import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import List from './numbers/List'
import Edit from './numbers/Edit'
import Import from './numbers/Import'

const styles = theme => {
	return {
		root: {
			padding: theme.spacing(3, 2),
		},
	}
}

function Numbers(props) {
	var path = props.match.path
	return (
		<Switch>
			<Route path={`${path}/`} exact component={List} />			
			<Route path={`${path}/add`} render={props => (<Edit title="Add Number" {...props} />)} />
			<Route path={`${path}/edit/:numberID?`} component={Edit} />
			<Route path={`${path}/import`} exact component={Import} />
		</Switch>
	)
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Numbers))
