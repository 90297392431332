import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Link as Links } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Change(props) {
	const classes = useStyles()
	const [token] = React.useState(localStorage.getItem('token') || null)
	const [password, setPassword] = React.useState('')
	const [oldPassword, setOldPassword] = React.useState('')
	const [phone_number] = React.useState(props.user && props.user.phone_number ? props.user.phone_number : '');

	var submitChange = e => {
		e.preventDefault()
		axios
			.post(props.baseurl + 'remote/user/change', {
				token: token,
				password: password,
				old_password: oldPassword,
				parent_override: localStorage.getItem('parent_token') ? true : false
			})
			.then(result => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})
			})
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
			<Grid
  container
  justifyContent="center"
  direction="row"
  alignItems="center"
  style={{ marginLeft: "auto", marginRight: "auto" }}
>
  <LockOutlinedIcon fontSize="large" color="primary" style={{ marginRight: "8px" }} />

  <Typography component="h1" variant="h5">
    Change Password
  </Typography>
</Grid>
				<form className={classes.form} noValidate onSubmit={submitChange}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						placeholder="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						value={props.user ? props.user.email : ''}
						disabled
					/>

<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="phone_number"
						placeholder="Phone Number"
						name="phone_number"						                        
                        value={phone_number}
						disabled
					/>

					{
						!localStorage.getItem('parent_token') ? <TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="old_password"
						placeholder="Old Password"
						type="password"
						id="old_password"
						autoComplete="off"
						value={oldPassword}
						onChange={e => {
							setOldPassword(e.target.value)
						}}
					/>: null
					}
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						placeholder="New Password"
						type="password"
						id="password"
						autoComplete="off"
						value={password}
						onChange={e => {
							setPassword(e.target.value)
						}}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="confirm_password"
						placeholder="Confirm Password"
						type="password"
						id="confirm_password"
						autoComplete="off"
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Change Password
					</Button>
					<Grid container>
						<Grid item xs>
							<Links component={Link} color="primary" to="/forgot">
								Forgot password?
							</Links>
						</Grid>
					</Grid>
				</form>
			</div>
		</Container>
	)
}

const mapStateToProps = state => ({
	user: state.auth,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Change)
