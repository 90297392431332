import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { connect } from 'react-redux'
import { setLoaded, setTemp } from '../../actions'

function Confirm(props) {	

	const handleClose = () => {
		var temp = Object.assign({}, props.temp, {
			confirm: null,
		})

		props.setTemp(temp)
	}

	return (
		<Dialog
			open={props.temp.limited ? true : false}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="xs"
		>
			<DialogTitle style={{ paddingBottom: '8px' }}>
				{props.temp.limited && props.temp.limited.title
					? props.temp.limited.title
					: ''}
			</DialogTitle>
			<DialogContent style={{ paddingTop: '0px' }}>
				<Typography>
					{props.temp.limited && props.temp.limited.text
						? props.temp.limited.text
						: 'Are you sure?'}
				</Typography>
			</DialogContent>
			<DialogActions>
            {props.temp.limited && !props.temp.limited.no_cancel ? <Button onClick={handleClose} color="secondary">
				{props.temp.limited && props.temp.limited.cancel_text ? props.temp.limited.cancel_text : 'Cancel'}
				</Button> : null}

				<Button onClick={() => {
                    if (props.temp.limited && props.temp.limited.callback) {
                        handleClose()
                        props.temp.limited.callback()
                    } 
                }} color="primary">
					{props.temp.limited && props.temp.limited.limited_text ? props.temp.limited.limited_text : 'Yes'}
				</Button>

			</DialogActions>
		</Dialog>
	)
}

const mapStateToProps = (state) => ({
	loaded: state.loaded,
	temp: state.temp,
})

const mapDispatchToProps = (dispatch) => ({
	setLoaded: (loaded) => dispatch(setLoaded(loaded)),
	setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Confirm)
