import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { setUser, setAlert } from '../../../actions'
import { setNumber } from '../../../actions/numbers'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	formControl: {
		marginTop: theme.spacing(1),
		minWidth: 120,
		width: '100%'
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))

function Status(props) {
	const classes = useStyles()
	const [number, setNumber] = React.useState(props.number)

	React.useEffect(() => {
		setNumber(props.number)
	}, [props.number])

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth)
	}, [])

	const handleChange = (e) => {
		props.updateNumber('status', e.target.value)
	}

	return (
		<div>
			<FormControl variant="standard" className={classes.formControl}>
				<InputLabel ref={inputLabel}>Status</InputLabel>
				<Select
					value={number.status ? number.status : 'active'}
					onChange={handleChange}
					labelWidth={labelWidth}
				>
					<MenuItem value={'active'}>Active</MenuItem>
					<MenuItem value={'disabled'}>Disabled</MenuItem>
					<MenuItem value={'deleted'} disabled={false}>
						Deleted
					</MenuItem>
				</Select>
			</FormControl>
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	number: state.number,
	groups: state.groups,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setNumber: (number) => dispatch(setNumber(number)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Status)
