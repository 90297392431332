import { combineReducers, applyMiddleware } from 'redux'
import { createStore } from 'redux'
import auth from './auth'
import alert from './alert'
import tools from './tools'
import settings from './settings'
import theme from './theme'
import intent from './intent'
import checkout from './checkout'
import socket from './socket'
import contacts from './contacts'
import contact from './contact'
import groups from './groups'
import group from './group'
import messages from './messages'
import message from './message'
import account from './account'
import temp from './temp'
import keyword from './keyword'
import status from './status'
import number from './number'
import baseurl from './baseurl'
import isinit from './isinit'
import loaded from './loaded'
import shop from './shop'
import thunk from 'redux-thunk';

const store = createStore(combineReducers({
    auth,
    baseurl,
    alert,
    tools,
    settings,
    theme,
    socket,
    contacts,
    contact,
    groups,
    group,
    messages,
    message,
    account,
    temp,
    intent,
    checkout,
    keyword,
    status,
    number,
    isinit,
    loaded,
    shop
  }), applyMiddleware(thunk))

export default store