import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { setUser, setAlert, setTemp } from '../../../actions'
import { setContact } from '../../../actions/contacts'
import { connect } from 'react-redux'
import axios from 'axios'
import Payments from '../Payment'
import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	formControl: {
		marginTop: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))

function Status(props) {
	const classes = useStyles()

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth)
	}, [])

	const [paymentMethods, setPaymentMethods] = React.useState([])
	const [paymentMethod, setPaymentMethod] = React.useState('')
	const [paymentDetails, setPaymentDetails] = React.useState(null)

	const handleChange = e => {

		
		for(var i = 0; i < paymentMethods.length; i++) {
			
			if (paymentMethods[i]._id.toString() === e.target.value.toString()) {
				

				setPaymentDetails(paymentMethods[i])

				break;
			}
		}



		setPaymentMethod(e.target.value)
	}

	const loaded = useRef(false)

	var load = () => {
		axios
			.post(props.baseurl + 'remote/master/paymentmethods', {
				token: props.user.token,
			})
			.then(result => {
				setPaymentMethods(result.data.payment_methods)



				if (result.data.payment_methods.length) {


	
					result.data.payment_methods.forEach((v, i) => {
							if (v.card_token.toString() === props.user.card_token) {
								setPaymentMethod(v._id.toString())
								setPaymentDetails(v)
							}
						})
	

					//setPaymentMethod(result.data.payment_methods[0]._id.toString())
				} else {
					//setPaymentMethod('')
				}

				loaded.current = true
			})
	}

	var paymentCallback = (charge) => {		
		setPaymentMethods(charge.payment_methods)
		setPaymentMethod(charge.payment_methods[charge.payment_methods.length-1]._id.toString())

		setPaymentDetails(charge.payment_methods[charge.payment_methods.length-1])



	var user = Object.assign({}, props.user, {
		customer_token: charge.customer,
		payment_methods: charge.payment_methods
	  })

	props.setUser(user)
		
	}

	var selectPayment = () => {

		var temp = Object.assign({}, props.temp, {
			paymentMethod: paymentDetails.card_token,		
		  })
	
		props.setTemp(temp)

props.setStep(2)

	}

	useEffect(() => {
		load()
		// eslint-disable-next-line
	}, [])

	return (
        <React.Fragment>
		<FormControl variant="standard" className={classes.formControl} fullWidth>
			<InputLabel ref={inputLabel}>Choose Payment Method</InputLabel>
			{paymentMethods ? (
				<Select
					value={paymentMethod}
					onChange={handleChange}
					labelWidth={labelWidth}
				>
					<MenuItem value={''}>Choose Payment Method</MenuItem>
					<MenuItem value={'new'}>Add New Payment Method</MenuItem>
					{paymentMethods.map((v, i) => {
						return (
							<MenuItem value={v._id} key={v._id.toString()}>
								{v.label}
							</MenuItem>
						)
					})}
				</Select>
			) : null}
		</FormControl>

		{paymentMethod !== 'new' && paymentMethod !== '' ? 
					<Box pt={1}>
				<Button
					variant="contained"					
					color="primary"
					fullWidth
					onClick={() => {
						selectPayment()
					}}
				>
					Continue
				</Button>
			</Box> : null
	}

{paymentMethod === 'new' ? <Box pt={1} mt={2} width="100%"><Payments callBack={paymentCallback} /></Box> : null}
</React.Fragment>

	)
}

const mapStateToProps = state => ({
	user: state.auth,
	contact: state.contact,
	groups: state.groups,
	temp: state.temp,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setTemp: temp => dispatch(setTemp(temp)),
	setContact: contact => dispatch(setContact(contact)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Status)
