import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import List from './reports/List'
import Messages from './reports/Messages'

const styles = theme => {
	return {
		root: {
			padding: theme.spacing(3, 2),
		},
	}
}

function Reports(props) {
	var path = props.match.path

	return (
		<Switch>
			<Route path={`${path}/`} exact component={List} />
			<Route path={`${path}/messages`} exact component={Messages} />
		</Switch>
	)
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Reports))
