import React, { useEffect, useRef } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { setAlert, setUser, setTemp, setCheckout } from '../../actions'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import Options from './choices/Options'
//import Previews from './choices/Previews'
import Steps from './choices/Steps'
import PaymentMethods from './choices/PaymentMethods'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// uncomment to use Stripe Checkout
/*
import { loadStripe } from '@stripe/stripe-js'
var stripePromise = null
if (window.location.href.includes(process.env.REACT_APP_LIVE_DOMAIN_CHECK)) {
	stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_LIVE);
} else {
	stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_TEST);
}
*/
var stripePromise = null

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	panel: {
		width: '100%',
	},
	formControl: {
		marginTop: theme.spacing(1),
		minWidth: 120,
	},
}))

function Choices(props) {
	const classes = useStyles()

	const [choice, setChoice] = React.useState('')

	const [step, setStep] = React.useState(0)

	const [userAccount, setUserAccount] = React.useState({
		per_sms: 0,
		per_mms: 0,
		per_combo: 0,
		choice: '',
	})

	const loaded = useRef(false)

	useEffect(() => {
		load()
		// eslint-disable-next-line
	}, [])

	React.useEffect(() => {
		if (props.user) {
			setUserAccount(props.user)
		}
	}, [props.user])

	var load = () => {
		axios
			.post(props.baseurl + 'remote/user/data', {
				token: props.user.token,
				account: true,
				_id: props.match.params.contactID,
			})
			.then((result) => {
				props.setUser(result.data)
				loaded.current = true
			})
	}

	var confirmPayment = () => {
		axios
			.post(props.baseurl + 'remote/payments/charge', {
				token: props.user.token,
				customer_token: props.user.customer_token,
				card_token: props.temp.paymentMethod,
				choice: choice,
			})
			.then((result) => {
				if (result.data.charge.status === 'succeeded') {
					if (!result.data.account_data) {
						result.data.user.account_data = props.user.account_data
					}

					props.setUser(result.data.user)

					var temp = Object.assign({}, props.temp, {
						charge_card: result.data.charge,
					})

					props.setTemp(temp)
					setStep(3)
				}
			})
	}

	var steps = []

	steps[0] = (
		<React.Fragment>
			<Box pt={1} width="100%">
				<Options choice={choice} setChoice={setChoice} />
			</Box>

			{/*}
			<Box pt={1}>
				<Previews userAccount={userAccount} choice={choice} />
			</Box>
	*/}
			{
				<Box pt={1}>
					<Button
						variant="contained"
						fullWidth
						color="primary"
						component={Link}
						to="/choices"
						onClick={() => {
							setStep(1)
						}}
						disabled={!choice || choice < 1}
					>
						Continue To Payment
					</Button>
				</Box>
			}

			{stripePromise ? (
				<Box pt={1}>
					<Button
						variant="contained"
						fullWidth
						color="primary"
						onClick={async () => {
							var res = await axios.post(
								props.baseurl + 'remote/payments/checkout_payment',
								{
									token: props.user.token,
									choice: choice,
								}
							)

							//setClientSecret(res.data.client_secret)
							setCheckout(res.data.session_id)

							if (res.data.customer_id) {
								var u = Object.assign({}, props.user, {
									customer_token: res.data.customer_id,
								})

								props.setUser(u)

								const stripe = await stripePromise

								stripe
									.redirectToCheckout({
										// Make the id field from the Checkout Session creation API response
										// available to this file, so you can provide it as argument here
										// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
										sessionId: res.data.session_id,
									})
									.then(function (result) {
										// If `redirectToCheckout` fails due to a browser or network
										// error, display the localized error message to your customer
										// using `result.error.message`.
									})
							}
						}}
					>
						Continue To Checkout
					</Button>
				</Box>
			) : null}

			{/* save card via Stripe Checkout for future use then return to select that saved card 
			<Box pt={1}>
				<Button
					variant="contained"
					fullWidth
					color="primary"
					onClick={async () => {
						var res = await axios.post(
							props.baseurl + 'remote/payments/checkout_payment',
							{
								token: props.user.token,
								choice: choice,
							}
						)

						//setClientSecret(res.data.client_secret)
						setCheckout(res.data.session_id)

						if (res.data.customer_id) {
							var u = Object.assign({}, props.user, {
								customer_token: res.data.customer_id,
							})

							props.setUser(u)

							const stripe = await stripePromise

							stripe
								.redirectToCheckout({
									// Make the id field from the Checkout Session creation API response
									// available to this file, so you can provide it as argument here
									// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
									sessionId: res.data.session_id,
								})
								.then(function (result) {
									// If `redirectToCheckout` fails due to a browser or network
									// error, display the localized error message to your customer
									// using `result.error.message`.
								})
						}
					}}
				>
					Continue To Payment
				</Button>
			</Box>
				*/}
		</React.Fragment>
	)

	steps[1] = (
		<Box pt={1} width="100%">
			<PaymentMethods
				userAccount={userAccount}
				choice={choice}
				setStep={setStep}
				confirmPayment={confirmPayment}
			/>
		</Box>
	)

	if (choice === '') {
		var total = 0
	}

	if (choice === 0) {
		total = 0
	}

	if (choice === 1) {
		total = 100
	}

	if (choice === 2) {
		if (props.user.package === 1) {
			total = 150
		} else {
			total = 250
		}
	}

	var package_details = {}
	switch (choice) {
		case 0:
			package_details.title = 'Free'
			break
		case 1:
			package_details.title = 'Basic'
			break
		case 2:
			package_details.title = 'Plus'
			break
		case 3:
			package_details.title = 'Pro'
			break
		default:
			package_details.title = 'Free'
			break
	}

	steps[2] = (
		<React.Fragment>
			<Box pt={1} width="100%">
				<Grid container className={classes.formControl}>
					<Grid item xs={6}>
						Plan:
					</Grid>
					<Grid item xs={6}>
						{package_details.title}
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						Total:
					</Grid>
					<Grid item xs={6}>
						${total.toFixed(2)}
					</Grid>
				</Grid>
			</Box>

			<Box pt={2}>
				<Button
					variant="contained"
					fullWidth
					color="primary"
					onClick={() => {
						confirmPayment()
					}}
				>
					Complete Purchase
				</Button>
			</Box>
		</React.Fragment>
	)

	steps[3] = (
		<Box pt={1}>
			<Typography variant="h6" component="p">
				Payment Successful!
			</Typography>
			<Typography variant="subtitle1" component="p">
				Package: {package_details.title}
			</Typography>
			<Typography variant="subtitle1" component="p">
				Payment Details:
			</Typography>

			{props.temp.charge_card ? (
				<React.Fragment>
					{' '}
					<Typography variant="subtitle2" component="p">
						Card: {props.temp.charge_card.brand} {props.temp.charge_card.last4}
					</Typography>
					<Typography variant="subtitle2" component="p">
						Receipt ID: {props.temp.charge_card.id}
					</Typography>
				</React.Fragment>
			) : null}

			<Box pt={1}>
				<Button
					variant="contained"
					fullWidth
					color="primary"
					component={Link}
					to="/account"
				>
					Go To Account
				</Button>
			</Box>
		</Box>
	)

	return (
		userAccount && (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={`${classes.paper} top-paper`}>
					<Grid
						container
						justifyContent="center"
						direction="row"
						alignItems="center"
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<LockOutlinedIcon
							fontSize="large"
							color="primary"
							style={{ marginRight: '8px' }}
						/>

						<Typography component="h1" variant="h5">
							Select Plan
						</Typography>
					</Grid>

					<Box pt={1} width="100%">
						<Steps
							choice={choice}
							setChoice={setChoice}
							steps={steps}
							step={step}
						/>
					</Box>

					{step > 0 && step < 3 ? (
						<Box pt={1}>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => {
									setStep((step) => step - 1)
								}}
							>
								Back
							</Button>
						</Box>
					) : null}
				</div>
			</Container>
		)
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	temp: state.temp,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
	setUser: (user) => dispatch(setUser(user)),
	setTemp: (temp) => dispatch(setTemp(temp)),
	setCheckout: (checkout) => dispatch(setCheckout(checkout)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Choices)
