import React, { useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import { setMessage } from '../../../actions/messages'
import axios from 'axios'
import Director from '../../Director'
import FormControl from '@material-ui/core/FormControl'
import Moment from 'moment-timezone'

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Reply(props) {
	const classes = useStyles()

    const [message, setMessage] = React.useState(props.message)
    const original = useRef(null)
	const loaded = useRef(false)
    const [saved, setSaved] = React.useState(false)

    console.log(original)

	useEffect(() => {
		if (props.match.params.messageID) {
			load()
		} else {
			props.setMessage({status: 'draft'})
			loaded.current = true
		}
		// eslint-disable-next-line
	}, [props.match.params.messageID])

	React.useEffect(() => {
		setMessage(props.message)
    }, [props.message])

	var load = () => {
		axios
			.post(props.baseurl + 'remote/messages/load', {
				token: props.user.token,
				_id: props.match.params.messageID,
			})
			.then(result => {
				if (result.data._id) {
					props.setMessage(result.data)
                    loaded.current = true
                    original.current = result.data                                    
                    setSaved(true)                    
				} else {
					props.setMessage({})
					loaded.current = true
					setSaved(false)
				}
			})
	}

	
	var updateMessage = (field, value) => {
		var updates = {}
		updates[field] = value

		setSaved(false)

		setMessage(Object.assign({}, message, updates))
	}
	
	var submitSave = e => {
		e.preventDefault()

		axios
			.post(props.baseurl + 'remote/messages/save', {
				token: props.user.token,
                body: message.new_body,
                to: message.from,
				push_token: message.push_token,
                status: 'reply',
                reply: true
			})
			.then(result => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})

				setSaved(true)

				if (!props.match.params.messageID && result.data._id) {
					loaded.current = false					
					Director('/messages/edit/' + result.data._id, true)
				}

			})
			.catch(err => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: 'Error',
				})
			})
	}

	if (message && message.media) {

	var media = message.media.map((m,i) => {
		return <div><img alt={'image '+i+1} src={m} style={{width: '50%'}} /></div>
		})

	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Typography component="h1" variant="h5">
    {message ? props.title ? props.title : message && message._id ? 'Reply to' : 'Reply to' : null} {message ? <Button variant="text" color="primary" component={Link} to={'/messages/convo/'+message.from}>{message.from}</Button> : null}
				</Typography>
                
				{message ? (
					<form className={classes.form} noValidate onSubmit={submitSave}>
						<FormControl component="fieldset" style={{ display: 'flex' }}>
                        
                            They said:
                            <br />
                
                             {
                                original.current ? original.current.body : null
                            }
                   
							<div>
							At {Moment(message.date_added).format('hh:mm:ss A on MM/DD/YYYY')}
							</div>
							{
								message.media && message.media.length ?
								<div>
									{
										media
									}
								</div>
								
								: null
							}

							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="body"
								label="Reply"
								name="body"
								autoFocus
								disabled={saved && message && message.status === 'sent' ? true : false}
								value={message.new_body}
								onChange={e => {
									updateMessage('new_body', e.target.value)
								}}
								multiline={true}
								rows={5}
							/>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								disabled={saved ? true : false}
							>
								Send Message
							</Button>
						</FormControl>
					</form>
				) : null}
			</div>
		</Container>
	)
}

const mapStateToProps = state => ({
	user: state.auth,
	message: state.message,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setMessage: message => dispatch(setMessage(message)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Reply)