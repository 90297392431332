const baseurl = (state = '/', action) => {
	switch (action.type) {
		case 'SET_BASEURL':	 
		if (process.env.NODE_ENV === 'development') {
			return action.baseurl
		} else {
			return action.baseurl
		}		
		default:
			return state
	}
}

export default baseurl
