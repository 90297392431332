import React, { /*useState*/ } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../actions'
//import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useRouteMatch } from 'react-router-dom'

const useStyles = withStyles((theme) => ({}), { withTheme: true })

function Home(props) {
	//const matches = props.theme.breakpoints.down('sm')

    //var isSmall = useMediaQuery(matches)

    //const [verified, setVerified] = useState(false)
    
    var reset_match = useRouteMatch('/reset/:resetToken?')
    
    var reset_token = reset_match
        ? reset_match.params.resetToken
            ? true
            : false
        : false

    var verify_match = useRouteMatch('/verify/:verifyToken?')

    var verify_token = verify_match
        ? verify_match.params.verifyToken
            ? true
            : false
        : false

        var verify_match_phone = useRouteMatch('/verifyphone/:verifyToken?')

        var phone_token = verify_match_phone
            ? verify_match_phone.params.verifyToken
                ? true
                : false
            : false

        var tokens = verify_token || reset_token || phone_token ? true : false

    React.useEffect(() => {
               

        if (props.callback) {
            props.callback({tokens: tokens})
        }
    
    }, [props,tokens])   

	return null
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(Home))
