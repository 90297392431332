import React /*useState*/ from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert, setTemp } from '../../actions'
//import useMediaQuery from '@material-ui/core/useMediaQuery'
import Container from '@material-ui/core/Container'
import { useRouteMatch } from 'react-router-dom'
import { Box, Typography, Button } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'

const useStyles = withStyles((theme) => ({}), { withTheme: true })

function Error(props) {
	//const matches = props.theme.breakpoints.down('sm')

	//var isSmall = useMediaQuery(matches)

	//const [loaded, setLoaded] = useState(false)



	var variable_match = useRouteMatch('/this/:variable?')

	var is_variable = variable_match
		? variable_match.params.variable
			? true
			: false
		: false

	var variable_value = is_variable ? variable_match.params.variable : null

	React.useEffect(() => {
		if (props.callback) {
			var ret = {}
			ret[is_variable] = variable_value

			props.callback(ret)
		}
	}, [props, is_variable, variable_value])

	return (
		<Container maxWidth="xs">
			<CssBaseline />
			{props.temp.error ? (
				<React.Fragment>
					<Box mt={3}>
						<Typography style={{ textAlign: 'center' }} variant="h6">
							Something Went Wrong
						</Typography>
					</Box>
					<Box mt={3}>
						<Button
							onClick={() => {
								var temp = Object.assign({}, props.temp, {
									reload: true,
								})
								props.setTemp(temp)
							}}
							fullWidth
							variant="contained"
							color="primary"
						>
							Reload
						</Button>
					</Box>
				</React.Fragment>
			) : null}

			<Box mt={3}>
				<Button
					onClick={props.SignOutFunction}
					fullWidth
					variant="contained"
					color="primary"
				>
					Sign Out
				</Button>
			</Box>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	temp: state.temp,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(Error))
