const shop = (state = {}, action) => {
	switch (action.type) {
		case 'SET_SHOP':			
		return action.shop
		default:
			return state
	}
}

export default shop
