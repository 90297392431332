import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForwardTwoTone'
import Director from '../Director'

const useStyles = makeStyles({
  root: {
    minWidth: 140,
    textAlign: 'center',
    
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    paddingTop: '16px'
  },
  title: {
    
  },
  actions: {
      marginTop: 'auto'
  }
});

export default function OutlinedCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined" onClick={(e)=>{
        if (props.to) {
            Director(props.to)
        } else if (props.click) {
            props.click(e)
        }
    }}>
      <CardContent className={classes.content}>

        <Typography variant="caption" component="h6">
          {props.icon ? props.icon : null}
        </Typography>

        <Typography variant="subtitle1">
          {props.title}
        </Typography>
        <Typography variant="body2" component="div" style={{textAlign: 'left'}} color="textPrimary">
          {props.text}
        </Typography>
      </CardContent>
      {
        props.actionText ? <CardActions className={classes.actions}>
        <Button size="small" color="primary" endIcon={<ArrowForward />}>{props.actionText ? props.actionText : 'Go'}</Button>
        </CardActions> : null
      }
    </Card>
  );
}
