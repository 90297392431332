import React from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import TextIcon from '@material-ui/icons/TextsmsOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'
import axios from 'axios'
import Director from '../Director'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Sample(props) {

	const classes = useStyles()
	
	const [phone_number, setPhone] = React.useState(props.user && props.user.phone_number ? props.user.phone_number : '');

    var sendTest = (e) => {
        e.preventDefault()
		axios.post(props.baseurl + 'remote/api/test', {			
			phone_number: phone_number,
			token: props.user && props.user.token ? props.user.token : null
        }).then(result => {            
			if (result.data.status) {  
				props.setAlert({
					open: true,
					severity: 'info',
					message: 'message sent',
				})                    
                Director('/')
            } else {
				props.setAlert({
					open: true,
					severity: 'error',
					message: result.data.error,
				})
			}
		})
		.catch(err=>{
			props.setAlert({
				open: true,
				severity: 'error',
				message: `That action can't be performed right now`,
			})	
		})
    }



    return (
		<Container component="main" disableGutters maxWidth="xs">
		<CssBaseline />
		<div className={`${classes.paper} top-paper`}>
		<Grid
  container
  justifyContent="center"
  direction="row"
  alignItems="center"
  style={{ marginLeft: "auto", marginRight: "auto" }}
>
  <TextIcon fontSize="large" color="primary" style={{ marginRight: "8px" }} />

  <Typography component="h1" variant="h5">
    Send A Test Message To Your Number
  </Typography>
</Grid>

				<form className={classes.form} noValidate onSubmit={sendTest}>
				 <TextField
						variant="standard"
						margin="normal"
						required
						fullWidth
						id="phone_number"
						label="Phone Number"
						name="phone_number"						
                        autoFocus
                        value={phone_number}
                        onChange={(e) => {
                            setPhone(e.target.value)
						}}
						disabled={props.user ? true : false}
					/> 
					<Button
						type="submit"fullWidth
						variant="contained"
						color="primary"
                        className={classes.submit}                        
					>
						Send
					</Button>


				</form>
			</div>
			</Container>

	)
}

const mapStateToProps = state => ({
	user: state.auth,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Sample)
