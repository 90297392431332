const keyword = (state = null, action) => {
	switch (action.type) {
        case 'SET_KEYWORD':	        	
		return action.keyword
		default:
			return state
	}
}

export default keyword
