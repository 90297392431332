import React, { useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import { setGroup as reduceGroup } from '../../../actions/groups'
import axios from 'axios'
import Director from '../../Director'
import EditIcon from '@material-ui/icons/EditOutlined'
import AddIcon from '@material-ui/icons/AddOutlined'

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Edit(props) {
	const classes = useStyles()

	const [group, setGroup] = React.useState(props.group)
	const loaded = useRef(false)

	useEffect(() => {
		load()
		// eslint-disable-next-line
	}, [])

	React.useEffect(() => {
		setGroup(props.group)
	}, [props.group])

	var load = () => {
		axios
			.post(props.baseurl + 'remote/groups/load', {
				token: props.user.token,
				_id: props.match.params.groupID,
			})
			.then(result => {
				props.setGroup(result.data)
				loaded.current = true
			})
	}

	var updateGroup = (field, value) => {
		var updates = {}
		updates[field] = value

		
		var new_data = Object.assign({}, group, updates)
		setGroup(new_data)
		props.setGroup(new_data)
	}

	var submitSave = e => {
		e.preventDefault()

		axios
			.post(props.baseurl + 'remote/groups/save', {
				token: props.user.token,
				_id: group._id,
				name: group.name,
			})
			.then(result => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})

				Director('/groups')

			})
			.catch(err => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: 'Error',
				})
			})
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>

			<Grid
  container
  justifyContent="center"
  direction="row"
  alignItems="center"
  style={{ marginLeft: "auto", marginRight: "auto" }}
>
{group && group._id ? <EditIcon fontSize="large" color="primary" style={{ marginRight: "8px" }} /> : <AddIcon fontSize="large" color="primary" style={{ marginRight: "8px" }} /> }

  <Typography component="h1" variant="h5">
  {props.title ? props.title : 'Edit Group'}
  </Typography>
</Grid>

				{group ? (
					<form className={classes.form} noValidate onSubmit={submitSave}>
						<TextField
							variant="standard"
							margin="normal"
							fullWidth
							id="name"
							label="Name"
							name="name"
							autoComplete="name"
							autoFocus
							value={group.name ? group.name : ''}
							onChange={e => {
								updateGroup('name', e.target.value)
							}}
						/>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							Save Group
						</Button>
					</form>
				) : null}
			</div>
		</Container>
	)
}

const mapStateToProps = state => ({
	user: state.auth,
	group: state.group,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setGroup: group => {
		dispatch(reduceGroup(group))
	},
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
