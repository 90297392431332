const Tools = {
	formatter: function(amount) {
		var converter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
		})

		return converter.format(amount)
	},
}

export default Tools