import React, { useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { setUser, setAlert, SignOut, setTemp, } from '../../actions'
import axios from 'axios'
import Director from '../Director'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}))


function Verify(props) {
	const [verifyToken] = React.useState(props.match.params.verifyToken)



	const classes = useStyles()

	var loadUser = () => {
		if (!props.user) {
			axios
				.post(props.baseurl + 'remote/user/verify', {
					verifyToken: verifyToken,
					removeToken: false,
				})
				.then(result => {
					if (result.data.error) {
						props.setAlert({
							open: true,
							severity: 'error',
							message: result.data.error,
						})
						
						Director('/signout')
					} else {

						

						if (!props.user || result.data.token !== props.user.token) {												
							props.setUser(result.data)
						}

						
						var temp = Object.assign({}, props.temp, {
							verified: true
						})
	
						props.setTemp(temp)

						Director('/verified')
						props.setAlert({
							open: true,
							severity: 'success',
							message: 'Account Verified',
						})
					}
				})
		} else {
			axios
				.post(props.baseurl + 'remote/user/verify', {
					verifyToken: verifyToken,
					removeToken: false,
				})
				.then(result => {
					if (result.data.error) {
						props.setAlert({
							open: true,
							severity: 'error',
							message: result.data.error,
						})
						
						Director('/signout')
					} else {

						

						if (!props.user || result.data.token !== props.user.token) {												
							props.setUser(result.data)
						}

						
						var temp = Object.assign({}, props.temp, {
							verified: true
						})
	
						props.setTemp(temp)

						Director('/verified')
						props.setAlert({
							open: true,
							severity: 'success',
							message: 'Account Verified',
						})
					}
				})
		}
	}

	useEffect(() => {
		loadUser()
		// eslint-disable-next-line
	}, [])

	
	return props.user ? null : <Container component="main" maxWidth="xs" disableGutters>
	<CssBaseline />
		<div className={`${classes.paper} top-paper`}>
				<div style={{marginTop: '8px'}}>
				<Typography component="h1" variant="h5" align="center">
					Verifying...
				</Typography>
					</div>

			</div>
</Container>
}

const mapStateToProps = state => ({
	user: state.auth,
	socket: state.socket,
	baseurl: state.baseurl,
	temp: state.temp
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setAlert: alert => dispatch(setAlert(alert)),
	SignOut: () => dispatch(SignOut()),
	setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Verify)
