import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Select Credits', 'Choose Payment', 'Complete Purchase'];
}

export default function HorizontalLinearStepper(props) {

  const classes = useStyles();
  const steps = getSteps();


  const getStepContent = (step) => {
    return props.steps[step]
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={props.step}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {props.step === steps.length ? (
          props.steps[steps.length]
        ) : (
          <div>
            {getStepContent(props.step)}       
          </div>
        )}
      </div>
    </div>
  );
}