import React, { useEffect } from 'react'

import Grid from '@material-ui/core/Grid'

import { CssBaseline, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert, SignOut } from '../../actions'
import axios from 'axios'
import Director from '../Director'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Auto(props) {
	const classes = useStyles()
	const [autoToken] = React.useState(props.match.params.autoToken)
	//const [password, setPassword] = React.useState('')
	const [user, setLocalUser] = React.useState(null)

	if (user) {
	}

	var so = props.SignOut

	React.useEffect(() => {
		so()
	}, [so])

	var loadUser = () => {
		if (localStorage.getItem('token')) {
			return false
		}

		axios
			.post(props.baseurl + 'remote/user/load', {
				autoToken: autoToken,
				removeToken: false,
			})
			.then((result) => {
				if (result.data.error) {
					props.SignOut()

					//Director('/')
					props.setAlert({
						open: true,
						severity: 'error',
						message: result.data.error,
					})
				} else {
					//props.SignOut()
					setLocalUser(result.data)

					localStorage.setItem('token', result.data.token)
					props.setUser(result.data)

					if (props.match.params.convo) {
						Director('/messages/convo/' + props.match.params.convo)
					} else {
						Director('/')
					}
				}
			})
	}

	useEffect(() => {
		loadUser()
		// eslint-disable-next-line
	}, [])

	return (
		<Container
			component="main"
			disableGutters
			maxWidth="sm"
			style={{
				padding: props.user ? '0px' : '16px',
			}}
		>
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				></Grid>
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	SignOut: () => dispatch(SignOut()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Auto)
