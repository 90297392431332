import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import List from './contacts/List'
import Edit from './contacts/Edit'
import Import from './contacts/Import'

const styles = theme => {
	return {
		root: {
			padding: theme.spacing(3, 2),
		},
	}
}

function Contacts(props) {
	var path = props.match.path



	return (
		<Switch>
			<Route path={`${path}/`} exact component={List} />			
			<Route path={`${path}/add`} render={props => (<Edit title="Add Contact" {...props} />)} />
			<Route path={`${path}/edit/:contactID?`} component={Edit} />} />
			<Route path={`${path}/import`} exact component={Import} />
		</Switch>
	)
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Contacts))
