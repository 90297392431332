import React, { Component } from 'react'
import { connect } from 'react-redux'
import socketIOClient from 'socket.io-client'
import RouterControl from './RouterControl'
import { withStyles } from '@material-ui/core/styles'

import Hooks from './Hooks'
import Confirm from './ui/Confirm'

import axios from 'axios'
import {
	setUser,
	SignOut,
	setSocket,
	setUserSocket,
	setTheme,
	setTemp,
	setBaseurl,
	setLoaded,
} from '../actions'

import Director from './Director'

const styles = (theme) => {
	return {
		root: {
			padding: theme.spacing(3, 2),
		},
		main: {
			'& main:first-of-type': {
				marginLeft: '0px',
				paddingLeft: '0px',
				paddingRight: '0px',
			},
		},
	}
}

class Auth extends Component {
	constructor(props, context) {
		super()
		this.state = {
			socket: null,
		}
	}

	loadUser = () => {
		axios
			.post(this.props.baseurl + 'remote/user/data', {
				token: localStorage.getItem('token'),
			})
			.then((result) => {
				if (result.data) {
					var reload = this.props.temp.reload_url
						? this.props.temp.reload_url.toString()
						: null

					var destroy = this.props.temp.destroy_socket ? true : false

					if (this.props.temp.reload) {
						var temp = Object.assign({}, this.props.temp, {
							reload: false,
							reload_url: null,
							destroy_socket: destroy ? false : null,
						})
					}

					if (destroy && window.socket) {
						//this.state.socket.disconnect()
						window.socket.disconnect()
					}

					if (this.props.temp.error || this.props.temp.reload) {
						if (!temp) {
							temp = Object.assign({}, this.props.temp)
						}
						delete temp.error

						this.props.setTemp(temp)
					}

					this.props.setLoaded(true)

					this.props.setUser(result.data)

					//console.log('set base 2')
					this.props.setBaseurl(result.data.base_url ? result.data.base_url : null)					

					if (result.data.mode) {
						
						this.props.setTheme(result.data.mode)
						this.setState({theme_change: true})
					}

					if (reload) {
						Director(reload)
					}
				} else {
					temp = Object.assign({}, this.props.temp, {
						error: true,
						reload: false,
					})

					this.props.setTemp(temp)
				}
			})
			.catch((err) => {
				//localStorage.removeItem('token')
				//this.props.SignOut()

				var temp = Object.assign({}, this.props.temp, {
					error: true,
					reload: false,
				})

				this.props.setTemp(temp)
			})
	}

	hookBack = (is_token) => {
		//console.log('HOOK '+JSON.stringify(is_token))

		this.setState({ tokens: is_token.tokens })

		if (!this.state.hooks) {
			this.setState({ hooks: true }, () => {
				if (!is_token.tokens) {
					if (
						localStorage.getItem('token') &&
						(!this.props.user || (this.props.temp.reload && this.props.never))
					) {
						this.loadUser()
					}
				} else {
					//uncomment this.props.SignOut()
				}
			})
		}
	}

	componentDidMount() {
		//this.sockets()
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.user &&
			(!prevProps.user || this.props.user.token !== prevProps.user.token)
		) {
			this.props.setSocket(null)
			// disable socket connection, remove // on next line to enable

			this.sockets()
			var updating_user = true
		}

		if (
			this.props.user &&
			this.props.user.token &&
			this.props.socket &&
			this.props.socket !== this.props.user.socket &&
			!updating_user
		) {
			this.props.setUserSocket(this.props.socket)
			this.updateSocket(this.props.user.token, this.props.socket)
		}

		if (localStorage.getItem('token') && this.props.temp.reload) {
			this.loadUser()
		}
	}

	SignOutFunction = () => {
		this.props.SignOut()
		Director('/')
	}

	updateSocket = (token, socket) => {
		axios
			.post(this.props.baseurl + 'remote/user/socket', {
				token: token,
				socket: socket,
			})
			.then((res) => {
				this.props.setUserSocket(socket)
			})
			.catch((err) => {})
	}

	sockets = () => {
		/* force use of websocket transport
			const socket = socketIOClient('//', { rejectUnauthorized: false, secure: true, transports: ['websocket'] });
		*/

		if (this.state.socket) {
			//this.state.socket.disconnect()
		}

		if (window.socket) {
			window.socket.disconnect()
		}

		const socket = socketIOClient('//', {
			rejectUnauthorized: false,
			secure: true,
		})

		socket.on('hello', (data) => {
			this.props.setSocket(data)
			//uncomment just this line to show connections
			//console.log(data)
			
			
			
			
			//this.setState({ socket: data })
		})

		socket.on('reload', (data) => {
			console.log('RELOAD')

			var temp = Object.assign({}, this.props.temp, {
				reload: true,
			})

			this.props.setTemp(temp)
		})

		//this.setState({socket: socket})
		window.socket = socket
	}

	render() {
		return (
			<React.Fragment>
				<Hooks callback={this.hookBack} />
				<RouterControl
					themes={this.props.themes}
					SignOutFunction={this.SignOutFunction}
					tryAgain={this.tryAgain}
					tokens={this.state.tokens}
				/>
				

				<Confirm />
				
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.auth,
	alert: state.alert,
	settings: state.settings,
	socket: state.socket,
	loaded: state.loaded,
	status: state.status,
	temp: state.temp,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setBaseurl: (baseurl) => dispatch(setBaseurl(baseurl)),
	SignOut: () => dispatch(SignOut()),
	setSocket: (socket) => dispatch(setSocket(socket)),
	setUserSocket: (socket) => dispatch(setUserSocket(socket)),
	setTheme: (theme) => dispatch(setTheme(theme)),
	setTemp: (temp) => dispatch(setTemp(temp)),
	setLoaded: (loaded) => dispatch(setLoaded(loaded)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Auth))
