import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { setAlert, setUser } from '../../actions'
import Pages from '../nav/Pages'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(
	(theme) => ({
		paper: {
			marginTop: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',
		},
		credit_details: {
			marginTop: theme.spacing(1),
		},
		avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		submit: {
			margin: theme.spacing(3, 0, 2),
		},
		order_history: {
			marginBottom: theme.spacing(2),
		},
	}),
	{ withTheme: true }
)

function PaymentHistory(props) {
	const classes = useStyles()

	const [total, setTotal] = React.useState(0)
	const [data, setData] = React.useState([])

	const [loaded, setLoaded] = React.useState(false)

	const [pagination] = React.useState({
		page: 1,
		skip: 0,
		limit: 3,
		max: 3,
	})

	var load = React.useCallback(
		(pagination_settings) => {
			setLoaded(false)

			if (!pagination_settings) {
				pagination_settings = pagination
			}

			var paged_methods = []

			var start = (pagination_settings.page - 1) * pagination_settings.max
			var end = start + pagination_settings.max

			for (var i = start; i < end; i++) {
				paged_methods.push(props.methods[i])
			}

			setData(paged_methods)

			setTotal(props.methods.length)

			setLoaded(true)
		},
		[pagination, props.methods]
	)

	var methods = data

	React.useEffect(() => {
		load()
	}, [load])

	return (
		<Grid container spacing={0}>
			<Grid item className={classes.order_history} xs={12}>
				<Typography component="h1" variant="h5" align="center">
					Payment Methods
				</Typography>
			</Grid>

            <Grid item xs={12}>
			{props.addPayment}
			</Grid>

			<Grid item container>
				{methods.length ? (
					<React.Fragment>
						<Grid item alignContent="flex-end" container style={{marginBottom: '8px', marginTop: '8px'}}>
							{methods}
						</Grid>
						<Grid
							item
							alignContent="flex-end"
							container
							style={{ marginTop: '8px' }}
						>
							<Pages callback={load} pagination={pagination} total={total} />
						</Grid>
					</React.Fragment>
				) : loaded ? (
					'No Methods'
				) : (
					<React.Fragment>
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
					</React.Fragment>
				)}
			</Grid>

			
		</Grid>
	)
}

const mapStateToProps = (state) => ({
	
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	
	setAlert: (alert) => dispatch(setAlert(alert)),
	setUser: (user) => dispatch(setUser(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory)
