import React from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import { Grid, CssBaseline, Container } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert, setTheme, setLoaded } from '../../actions'
import axios from 'axios'
import Director from '../Director'
import { Link as Links } from '@material-ui/core'

//import { ThemeProvider } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function SignIn(props) {
	const classes = useStyles()

	const [email, setEmail] = React.useState('')
	const [password, setPassword] = React.useState('')

	var submitSignIn = (e) => {
		props.setLoaded(false)

		e.preventDefault()
		axios
			.post(props.baseurl + 'remote/user/signin', {
				email: email,
				password: password,
			})
			.then(async (result) => {
				if (result.data) {
					if (result.data.mode) {
						props.setTheme(result.data.mode)
					}

					props.changeUser(() => {
						
						if (result.data.package > 0) {
							Director('/dashboard')
						} else {
							Director('/subscribe/onboard')
						}

						window.scrollTo(0, 0)
					}, result.data)
				} else {
					props.setAlert({
						open: true,
						severity: 'error',
						message: 'Invalid credentials',
					})
				}
			})
			.catch((err) => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: `That action can't be performed right now`,
				})
			})
	}

	return (
		
			<Container component="main" disableGutters maxWidth="xs" style={{
				padding: props.user ? '0px' : '16px'
			}}>
				
				<CssBaseline />

				<div
					style={{
						marginBottom: '16px',
						marginTop: '16px',
						textAlign: 'center',
						display: 'none'
					}}
				>
					<img
						src={
							props.parent_theme === 'dark'
								? '/assets/logo_dark.png'
								: '/assets/logo.png'
						}
						alt=""
						style={{ maxWidth: '128px' }}
					/>
				</div>

				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '16px' }}
				>
					<LockOutlinedIcon
						fontSize="large"
						color="primary"
						style={{ marginRight: '8px' }}
					/>

					<Typography component="h1" variant="h5">
						Sign In
					</Typography>
				</Grid>
				<form className={classes.form} noValidate onSubmit={submitSignIn}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						placeholder="Email Address or Mobile Phone Number"
						name="email"
						autoComplete="email"
						value={email}
						onChange={(e) => {
							setEmail(e.target.value)
						}}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						placeholder="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						value={password}
						onChange={(e) => {
							setPassword(e.target.value)
						}}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Sign In
					</Button>
					<Grid container>
						<Grid item xs>
							<Links component={Link} color="primary" to={'/forgot'}>
								Forgot password?
							</Links>
						</Grid>
						<Grid item>
							<Links component={Link} color="primary" to={'/signup'}>
								Don't have an account? Sign Up
							</Links>
						</Grid>
					</Grid>
				</form>
				
			</Container>		
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
	loaded: state.loaded,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setLoaded: (loaded) => dispatch(setLoaded(loaded)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	setTheme: (theme) => dispatch(setTheme(theme)),
	changeUser: (callback, user) => {
		dispatch((dispatch) => {
			callback(dispatch(setUser(user)).user, true)
		})
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
