export const setUser = user => ({
  type: 'SET_USER',
  user
})

export const setAccount = account => ({
  type: 'SET_ACCOUNT',
  account
})

export const setStatus = status => ({
  type: 'SET_STATUS',
  status
})

export const setTemp = temp => ({
  type: 'SET_TEMP',
  temp
})

export const setIntent = intent => ({
  type: 'SET_INTENT',
  intent
})

export const setCheckout = checkout => ({
  type: 'SET_CHECKOUT',
  checkout
})

export const setIsinit = isinit => ({
  type: 'SET_ISINIT',
  isinit
})

export const setShop = shop => ({
  type: 'SET_SHOP',
  shop
})

export const setLoaded = loaded => ({
  type: 'SET_LOADED',
  loaded
})

export const setUserSocket = socket => ({
  type: 'SET_USER_SOCKET',
  socket
})

export const setSocket = socket => ({
  type: 'SET_SOCKET',
  socket
})

export const SignOut = () => ({
  type: 'SIGN_OUT'
})

export const setAlert = (alert) => ({
  type: 'SET_ALERT',
  alert
})

export const director = (to) => ({
  type: 'DIRECTOR',
  to
})

export const setTheme = (theme) => {
 return ({
  type: 'SET_THEME',
  theme
})
}

export const setBaseurl = baseurl => ({
  type: 'SET_BASEURL',
  baseurl
})