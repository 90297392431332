import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForwardTwoTone'
import Director from '../Director'
import { setLoaded } from '../../actions'
import { connect } from 'react-redux'

const useStyles = makeStyles({
  root: {
    minWidth: 140,
    textAlign: 'center',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    paddingTop: '16px'
  },
  title: {
    
  },
  actions: {
      
  }
});

function BigButton(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root+' big-button'} variant="outlined" onClick={(e)=>{
        if (props.to) {
            
          if (props.reload) {

            props.setLoaded(false)
            Director(props.to)

          } else {
            Director(props.to)
          }

        } else if (props.click) {
            props.click(e)
        }
    }}>
      
      <CardContent className={classes.content}>      
        <Typography variant="caption" component="h6">
          {props.icon ? props.icon : null}
        </Typography>

        <Typography variant="subtitle1">
          {props.title}
        </Typography>
        <Typography variant="body2" component="p" style={{textAlign: 'left'}} color="textSecondary">
          {props.text}
        </Typography>      
      </CardContent>
      
      <CardActions className={classes.actions}>
      <Button variant="text" color="default" endIcon={<ArrowForward />}>{props.actionText ? props.actionText : 'Go'}</Button>
      </CardActions>

    </Card>
  );
}


const mapStateToProps = (state) => ({
	loaded: state.loaded
})

const mapDispatchToProps = (dispatch) => ({
	setLoaded: (loaded) => dispatch(setLoaded(loaded)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BigButton)
