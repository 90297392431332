import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { setAlert } from '../../../actions'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120,
		marginTop: '0px',
		marginBottom: '0px'
	},
}))

function Status(props) {
	const classes = useStyles()

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth)
	}, [])

	const handleChange = e => {      
		console.log('change')  
		props.setFilter('status', e.target.value)
	}
    
	return (
			<FormControl variant="standard" className={classes.formControl} margin="dense" fullWidth>
				<InputLabel ref={inputLabel}>Status</InputLabel>
				<Select	
				style={{width: '100%'}}			
					value={props.status ? props.status : ''}
					onChange={handleChange}
					labelWidth={labelWidth}
				>
					<MenuItem value={''}>All Numbers</MenuItem>
					<MenuItem value={'active'}>Active</MenuItem>
					<MenuItem value={'disabled'}>Disabled</MenuItem>
					<MenuItem value={'deleted'}>Deleted</MenuItem>
				</Select>
			</FormControl>
	)
}

const mapStateToProps = state => ({
	user: state.auth,	
	groups: state.groups,
})

const mapDispatchToProps = dispatch => ({	
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Status)
