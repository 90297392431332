import React, { Component } from 'react'
import './App.css'
import Auth from 'components/Auth'
import { withRouter } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setTheme } from './actions'
import secondaryColor from '@material-ui/core/colors/grey'
import { darken } from '@material-ui/core/styles/colorManipulator'
import customTheme from 'ui/theme'

const defaultTheme = createTheme({
	overrides: {
		MuiDrawer: {
			paper: {
				//backgroundColor: '#191b1c'
			},
		},

		MuiSvgIcon: {
			root: {},
		},

		MuiStepIcon: {
			text: {
				fill: 'rgba(255, 255, 255, 0.87)',
			},

			root: {
				'&$completed': {},
				'&$active': {},
			},
			active: {},
			completed: {},
		},

		MuiListItemIcon: {
			// Name of the rule

			root: {
				// Some CSS
				minWidth: '36px',
			},
		},
		MuiInputBase: {
			root: {
				borderRadius: '0px !important',
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: '0px !important',
			},
		},
		MuiFilledInput: {
			root: {
				borderRadius: '0px !important',
				backgroundColor: 'transparent',
			},
		},
		MuiCard: {
			root: {
				borderRadius: '0px !important',
			},
		},
		MuiAppBar: {
			root: {
				height: '56px',
			},
		},
		MuiToolbar: {
			root: {
				height: '56px',
				minHeight: '56px !important',
			},
		},
		MuiFab: {
			root: {
				boxShadow: 'none',
				'&:active': {
					boxShadow: 'none',
				},
			},
			label: {
				color: '#ffffff',
			},
		},
	},
})

const lightTheme = createTheme({
	//shadows: ["none"],
	palette: {
		type: 'light',
		primary: {
			main: process.env.REACT_APP_PRIMARY_COLOR,
		},
		secondary: {
			light: secondaryColor[300],
			main: secondaryColor[400],
			dark: secondaryColor[700],
			contrastText: darken(secondaryColor[700], 0.5),
		},
		background: {
			default: '#f7f7f7',
		},
	},
	overrides: {
		...defaultTheme.overrides,

		MuiCssBaseline: {
			'@global': {
				'.expand': {
					border: '1px solid rgba(0, 0, 0, .10)',
					marginBottom: '0px',

					boxShadow: 'none',
					'&:not(:first-child)': {
						//borderTop: 0,
					},
					'&:before': {
						display: 'none',
					},
					'&.Mui-expanded': {
						borderT: '1px solid rgba(0, 0, 0, .10)',
					},
					'&.Mui-expanded + .expand': {
						borderTop: '1px solid rgba(0, 0, 0, .10)',
					},
				},
				'.big-button': {
					background: '#ffffff !important',
					'&:hover': {
						backgroundColor: '#fafafa !important',
					},
				},
				'.from-you': {
					borderRadius: '24px',
					padding: '8px',
					background: '#efefef !important',
					/*
					'&:hover': {
						backgroundColor: '#fafafa !important',
					}
					,*/
				},
				'.to-you': {
					borderRadius: '24px',
					padding: '8px',
					background: '#ffffff !important',
					/*
					'&:hover': {
						backgroundColor: '#fafafa !important',
					}
					,*/
				},
				'.faq': {
					backgroundColor: '#f6f6f6 !important',
				},

				'.CardField-number': {
					transitionTimingFunction: 'none !important',
					transition: 'none !important',
				},

				'.CheckoutForm input': {
					transitionTimingFunction: 'none !important',
					transition: 'none !important',
				},
				'.InputElement': {
					transitionTimingFunction: 'none !important',
					transition: 'none !important',
				},
			},
		},
		MuiPaper: {
			root: {
				backgroundColor: '#fff',
				boxShadow: 'none',
				borderRadius: '0px !important',
				'@global': {
					'::-webkit-scrollbar-thumb': {
						border: '2px solid #fff !important',
						borderRadius: '4px',
						backgroundColor: '#0000004d',
					},
				},
			},
		},
		MuiInputBase: {
			input: {
				//background: '#fff'
			},
			//background: '#fff'
			root: {
				borderRadius: '0px !important',
			},
		},
		MuiTextField: {
			root: {
				background: '#fff',
			},
		},
		MuiButton: {
			root: {
				//background: '#fff',
				'&:hover': {
					//opacity: .5
				},
			},
			outlined: {
				borderRadius: '0.0em',
			},
			contained: {
				padding: '0.7em 0.7em',
				margin: '0',
				borderRadius: '0.0em',
				boxSizing: 'border-box',
				textDecoration: 'none',
				fontFamily: '"Roboto", sans-serif',
				textTransform: 'uppercase',
				fontWeight: 400,

				//boxShadow: 'inset 0 -0.6em 0 -0.5em rgba(0, 0, 0, 0.17) !important',
				boxShadow: 'none !important',
				textAlign: 'left',
				position: 'relative',
			},
			containedPrimary: {
				color: '#ffffff',
				borderRadius: '0.0em',
			},
			containedSecondary: {
				color: '#ffffff',
				borderRadius: '0.0em',
			},
		},
	},
})

const darkTheme = createTheme({
	palette: {
		type: 'dark',
		primary: {
			main: process.env.REACT_APP_PRIMARY_COLOR,
		},
		secondary: {
			light: secondaryColor[300],
			main: secondaryColor[400],
			dark: secondaryColor[700],
			contrastText: darken(secondaryColor[700], 0.5),
		},
		background: {
			default: '#0c0c0c',
		},
	},

	overrides: {
		...defaultTheme.overrides,
		MuiCssBaseline: {
			'@global': {
				body: {
					//backgroundColor: '#000000',
				},
				'.expand': {
					border: '1px solid rgba(255, 255, 255, .10)',
					marginBottom: '0px',

					boxShadow: 'none',
					'&:not(:first-child)': {
						//borderTop: 0,
					},
					'&:before': {
						display: 'none',
					},
					'&.Mui-expanded': {
						borderT: '1px solid rgba(255, 255, 255, .10)',
					},
					'&.Mui-expanded + .expand': {
						borderTop: '1px solid rgba(255, 255, 255, .10)',
					},
				},
				'.big-button': {
					borderRadius: '24px',
					backgroundColor: '#323638 !important',
					'&:hover': {
						backgroundColor: '#252829 !important',
					},
				},
				'.from-you': {
					borderRadius: '24px',
					padding: '8px',
					backgroundColor: '#323638 !important',
					/*
					'&:hover': {
						backgroundColor: '#252829 !important',
					}
					,*/
				},
				'.to-you': {
					borderRadius: '24px',
					padding: '8px',
					backgroundColor: '#9a9a9a !important',
					/*
					'&:hover': {
						backgroundColor: '#252829 !important',
					}
					,*/
				},
				'.faq': {
					backgroundColor: '#323638 !important',
				},

				'.CardField-number': {
					transitionTimingFunction: 'none !important',
					transition: 'none !important',
				},

				'.CheckoutForm input': {
					transitionTimingFunction: 'none !important',
					transition: 'none !important',
				},
				'.InputElement': {
					transitionTimingFunction: 'none !important',
					transition: 'none !important',
				},
			},
		},
		MuiPaper: {
			root: {
				backgroundColor: '#101010',
				boxShadow: 'none',
				borderRadius: '0px !important',

				'@global': {
					'::-webkit-scrollbar-thumb': {
						border: '2px solid #191b1c !important',
						borderRadius: '4px',
						backgroundColor: '#0000004d',
					},
				},
			},
		},
		MuiInputBase: {
			input: {
				//background: '#000'
			},
			//background: '#000'
			root: {
				borderRadius: '0px !important',
			},
		},
		MuiTextField: {
			root: {
				background: '#000',
			},
		},
		MuiButton: {
			root: {
				//background: '#fff',
				'&:hover': {
					//opacity: .5
				},
			},
			outlined: {
				borderRadius: '0.0em',
			},
			contained: {
				padding: '0.7em 0.7em',
				margin: '0',
				borderRadius: '0.0em',
				boxSizing: 'border-box',
				textDecoration: 'none',
				fontFamily: '"Roboto", sans-serif',
				textTransform: 'uppercase',
				fontWeight: 400,

				//				boxShadow: 'inset 0 -0.6em 0 -0.5em rgba(0, 0, 0, 0.17) !important',
				boxShadow: 'none !important',
				textAlign: 'left',
				position: 'relative',
			},
			containedPrimary: {
				color: '#ffffff',
				borderRadius: '0.0em',
			},
			containedSecondary: {
				color: '#ffffff',
				borderRadius: '0.0em',
			},
		},
	},
})

const themes = {
	light: lightTheme,
	dark: darkTheme,
	custom: customTheme,
}

class App extends Component {
	constructor(props, context) {
		super()

		var theme = localStorage.getItem('mode')
			? localStorage.getItem('mode')
			: 'light'

		this.state = {
			alert: null,
			message: null,
			severity: null,
			theme: theme,
		}
	}

	componentDidMount() {}

	render() {
		return (
			<ThemeProvider theme={themes[this.props.theme]}>
				<Auth {...this.props} themes={themes} />
			</ThemeProvider>
		)
	}
}

const mapStateToProps = (state) => ({
	theme: state.theme,
})

const mapDispatchToProps = (dispatch) => ({
	setTheme: (theme) => dispatch(setTheme(theme)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
