import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import { setUser, setAlert } from '../../../actions'
import StepLabel from '@material-ui/core/StepLabel'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { connect } from 'react-redux'
import { CheckTwoTone as CheckIcon } from '@material-ui/icons'

const useStyles = withStyles(
	(theme) => ({
		root: {
			//width: '100%',
		},
		button: {
			marginRight: theme.spacing(1),
		},
		instructions: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	{ withTheme: true }
)

function getSteps() {
	return ['Choose Subscription', 'Select Payment', 'Complete Purchase']
}

function MyStepper(props) {
	const matches = props.theme.breakpoints.down('sm')
	var isSmall = useMediaQuery(matches)

	const steps = getSteps()

	const getStepContent = (step) => {
		return props.steps[step]
	}

	return (
		<div className={props.classes.root}>
			<Stepper
				activeStep={props.step}
				style={{
					flexDirection: isSmall ? 'column' : 'row',
					alignItems: isSmall ? 'flex-start' : 'center',
				}}
			>
				{steps.map((label, index) => {
					const stepProps = {}
					const labelProps = {
						icon:
							props.step > index ? (
								<div
									style={{
										width: '36px',
										height: '36px',
										borderRadius: '50%',
										alignContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										justifyContent: 'center',
										justifyItems: 'center',
										lineHeight: '36px',
										backgroundColor:
											props.step >= index
												? props.theme.palette.primary.main
												: props.theme.palette.secondary.main,
									}}
								>
									<CheckIcon size={36} style={{ verticalAlign: 'middle' }} className="white-icon" />
								</div>
							) : (
								<div
									style={{
										width: '36px',
										height: '36px',
										borderRadius: '50%',
										alignContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										justifyContent: 'center',
										justifyItems: 'center',
										lineHeight: '36px',
										color: '#fff',
										backgroundColor:
											props.step >= index
												? props.theme.palette.primary.main
												: props.theme.palette.secondary.main,
									}}
								>
									{index + 1}
								</div>
							),
					}

					return (
						<Step
							key={label}
							{...stepProps}
							style={{ marginTop: isSmall && index > 0 ? '8px' : '0px' }}
						>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					)
				})}
			</Stepper>
			<div>
				{props.step === steps.length ? (
					props.steps[steps.length]
				) : (
					<div>{getStepContent(props.step)}</div>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(useStyles(MyStepper))
