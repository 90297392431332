import React from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'
import axios from 'axios'
import Director from '../Director'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { Link as Links } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Forgot(props) {

	const classes = useStyles()

	const [email, setEmail] = React.useState(props.user && props.user.email ? props.user.email : '');
	const [phone_number, setPhone] = React.useState(props.user && props.user.phone_number ? props.user.phone_number : '');

    var submitForgot = (e) => {
        e.preventDefault()
		axios.post(props.baseurl + 'remote/user/forgot', {
			email: email,
			phone_number: phone_number,
			token: props.user && props.user.token ? props.user.token : null
        }).then(result => {            
			if (result.data.status) {  
				props.setAlert({
					open: true,
					severity: 'info',
					message: 'Password reset sent',
				})                    
                Director('/')
            } else {
				props.setAlert({
					open: true,
					severity: 'error',
					message: result.data.error,
				})
			}
		})
		.catch(err=>{
			props.setAlert({
				open: true,
				severity: 'error',
				message: `That action can't be performed right now`,
			})	
		})
    }



    return (
		<Container component="main" disableGutters maxWidth="xs" style={{
			padding: props.user ? '0px' : '16px'
		}}>
			
			<CssBaseline />

			<div
				style={{
					marginBottom: '16px',
					marginTop: '16px',
					textAlign: 'center',
					display: 'none'
				}}
			>
				<img
					src={
						props.parent_theme === 'dark'
							? '/assets/logo_dark.png'
							: '/assets/logo.png'
					}
					alt=""
					style={{ maxWidth: '128px' }}
				/>
			</div>
	

	
	<Grid
container
justifyContent="center"
direction="row"
alignItems="center"
style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '16px' }}
>
  <LockOutlinedIcon fontSize="large" color="primary" style={{ marginRight: "8px" }} />

  <Typography component="h1" variant="h5">
    Reset Password
  </Typography>
</Grid>

				<form className={classes.form} noValidate onSubmit={submitForgot}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						placeholder={!props.user ? 'Email Address or Phone Number' : 'Email Address'}
						name="email"
						autoComplete="email"
                        
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
						}}
						disabled={props.user ? true : false}
					/>
										{props.user ? <TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="phone_number"
						placeholder="Phone Number"
						name="phone_number"						
                        autoFocus
                        value={phone_number}
                        onChange={(e) => {
                            setPhone(e.target.value)
						}}
						disabled={props.user ? true : false}
					/> : null}
					<Button
						type="submit"fullWidth
						variant="contained"
						color="primary"
                        className={classes.submit}                        
					>
						Reset Password
					</Button>

{!props.user ?
					<Grid container>
						<Grid item xs>
						<Links component={Link} color="primary" to="/" variant="body2">
								Have an account? Sign In
							</Links>
						</Grid>
						<Grid item>
							<Links component={Link} color="primary" to={'/signup'}>
								No account? Sign Up
							</Links>
						</Grid>
					</Grid> : null }

				</form>
			
			</Container>

	)
}

const mapStateToProps = state => ({
	user: state.auth,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Forgot)
