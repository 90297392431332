import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import List from './keywords/List'
import Edit from './keywords/Edit'

const styles = theme => {
	return {
		root: {
			padding: theme.spacing(3, 2),
		},
	}
}

function Keywords(props) {
	var path = props.match.path

	return (
		<Switch>
			<Route path={`${path}/`} exact component={List} />			
			<Route path={`${path}/add`} render={props => (<Edit title="Add Keyword" {...props} />)} />
			<Route path={`${path}/edit/:keywordID?`} component={Edit} />} />	
		</Switch>
	)
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Keywords))
