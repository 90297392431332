import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import { setAlert } from '../../actions'
import axios from 'axios'
import Director from '../Director'


const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function CreateAccount(props) {
	const classes = useStyles()

	const [email, setEmail] = React.useState('')
	const [password, setPassword] = React.useState('')
	const [confirm_password, setConfirmPassword] = React.useState('')

	var submitCreateAccount = e => {
		e.preventDefault()

		if (password !== confirm_password) {
			props.setAlert({
				open: true,
				severity: 'error',
				message: 'Passwords do not match',
			})

			return false
		}

		axios
			.post(props.baseurl + 'remote/user/createaccount', {
				email: email,
                password: password,
                token: props.user.token
			})
			.then(result => {
				if (result.data && result.data.error) {
					props.setAlert({
						open: true,
						severity: 'error',
						message: result.data.error,
					})
				} else {

                    console.log(result.data)					
					Director('/accounts')

				}
			})
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
			<Grid
  container
  justifyContent="center"
  direction="row"
  alignItems="center"
  style={{ marginLeft: "auto", marginRight: "auto" }}
>
  <LockOutlinedIcon fontSize="large" color="primary" style={{ marginRight: "8px" }} />

  <Typography component="h1" variant="h5">
    Create Sub-Account
  </Typography>
</Grid>
				<form className={classes.form} noValidate onSubmit={submitCreateAccount}>
					<TextField
						variant="standard"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						value={email}
						onChange={e => {
							setEmail(e.target.value)
						}}
					/>
					<TextField
						variant="standard"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						value={password}
						onChange={e => {
							setPassword(e.target.value)
						}}
					/>
					<TextField
						variant="standard"
						margin="normal"
						required
						fullWidth
						name="confirm_password"
						label="Confirm Password"
						type="password"
						id="confirm_password"
						value={confirm_password}
						onChange={e => {
							setConfirmPassword(e.target.value)
						}}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Create Account
					</Button>
					
				</form>
			</div>
		</Container>
	)
}

const mapStateToProps = state => ({
	user: state.auth,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount)
