import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { connect } from 'react-redux'
import { setAlert } from '../../actions'
//import { SocialIcon } from 'react-social-icons';

const useStyles = withStyles(
	(theme) => ({
		root: {
			width: '100%',			
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			flexBasis: '100%',
			flexShrink: 0,
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
		},
		faq: {
			border: '1px solid rgba(0, 0, 0, .125)',
			marginBottom: '0px',
			boxShadow: 'none',
			'&:not(:first-child)': {
				borderTop: 0,
			},
			'&:before': {
				display: 'none',
			},
			'&.Mui-expanded': {
				'&:not(:first-child)': {
					borderTop: '1px solid rgba(0, 0, 0, .125)',
				},
			},
			'&.Mui-expanded + .faq': {
				borderTop: '1px solid rgba(0, 0, 0, .125)',
			},
		},
	}),
	{ withTheme: true }
)

const faqs = []
faqs.push(
	{
		title: `What is ` + process.env.REACT_APP_NAME + `?`,
		body: (
			<React.Fragment>
				{process.env.REACT_APP_NAME} is an app available on both iOS and Android
				that allows users to follow their favorite dispensaries and receive push
				notifications from each shop with the latest updates, deals, events, and
				more.
			</React.Fragment>
		),
	},
	{
		title:
			`What features does ` + process.env.REACT_APP_NAME + ' offer for users?',
		body: (
			<React.Fragment>
				<Typography variant="body2">
					• {process.env.REACT_APP_NAME} can be used by anyone 18 or older where
					cannabis is legal.
				</Typography>
				<br />
				<Typography variant="body2">
					• No account or signup is required.
				</Typography>
				<br />
				<Typography variant="body2">
					• Follow your favorite dispensaries and swipe through them to view the
					latest updates and information from each shop.
				</Typography>
				<br />
				<Typography variant="body2">
					• Receive a push notification whenever a shop you follow makes an
					update.
				</Typography>
				<br />
				<Typography variant="body2">
					• Tap on a shop's name at the top of its profile to get more
					information, such as phone number, directions, hours, website & social
					media links, videos, and more.
				</Typography>
				<br />
				<Typography variant="body2">
					• Chat with dispensaries in real-time to get answers to questions as
					quickly as possible.
				</Typography>
				<br />
				<Typography variant="body2">
					• Get notified via push notification whenever you have a new chat
					message.
				</Typography>
				<br />
				<Typography variant="body2">
					• Easily follow dispensaries by scanning a shop's QR code or entering
					a shop's Greenlight code.
				</Typography>
				<br />
				<Typography variant="body2">
					• Easily disable notifications from specific shops with one tap.
				</Typography>
				<br />
				<Typography variant="body2">
					• Easily hide updates from specific shops with one tap.
				</Typography>
				<br />
				<Typography variant="body2">
					• Optionally link a phone number to backup and recover your list of
					shops using that phone number. This is helpful if you reinstall the
					app or install the app on a new device and want to recover your list
					of shops.
				</Typography>
			</React.Fragment>
		),
	},
	{
		title:
			`What features does ` +
			process.env.REACT_APP_NAME +
			' offer for dispensaries?',
		body: (
			<React.Fragment>
				<Typography variant="body2">
					The {process.env.REACT_APP_NAME} dashboard provides easy to use tools
					for dispensaries to manage their profile. This includes the following
					features and more:
				</Typography>
				<br />
				<Typography variant="body2">
					• Create a profile with your name, logo, address, and contact
					information.
				</Typography>
				<br />
				<Typography variant="body2">
					• Post an image / flyer that users see when viewing your profile.
				</Typography>
				<br />
				<Typography variant="body2">
					• Send a push notification to your followers when you post an updated
					flyer, update your profile information, or just have news &
					information you want to share with your users.
				</Typography>
				<br />
				<Typography variant="caption" style={{ margin: 40 }}>
					When a user taps one of your push notifications the app will open
					directly to your profile.
				</Typography>
				<br />
				<Typography variant="caption" style={{ margin: 40 }}>
					Notifications can include an image to be displayed alongside text when
					a user views the notification.
				</Typography>
				<br />
				<Typography variant="caption" style={{ margin: 40 }}>
					Notifications can open the app directly to your profile and load the
					video player to play your video when a user opens the notification.
				</Typography>
				<br />
				<br />
				<Typography variant="body2">
					• Let users contact you via phone, text, email, website, or social
					media.
				</Typography>
				<br />
				<Typography variant="body2">
					• Let users get directions to your shop with a single tap.
				</Typography>
				<br />
				<Typography variant="body2">
					• Link to your website and/or Weedmaps.
				</Typography>
				<br />
				<Typography variant="body2">
					• Include a video on your profile for users to watch.
				</Typography>
				<br />
				<Typography variant="body2">
					• Respond to chat messages from users via a simple and intuitive inbox
					system.
				</Typography>
				<br />
				<Typography variant="caption" style={{ margin: 40 }}>
					Chat notifications will open the app directly to your profile and load
					the real-time chat when the user opens the notification.
				</Typography>
				<br />
				<br />
				<Typography variant="body2">
					• Customize the colors and appearance of your profile and preview the
					changes before publishing them.
				</Typography>
			</React.Fragment>
		),
	}
)

function FAQ(props) {
	const [expanded, setExpanded] = React.useState({})

	const handleChange = (faq) => (event, isExpanded) => {
		var new_exp = {}

		if (expanded[faq]) {
			new_exp[faq] = false
		} else {
			new_exp[faq] = true
		}

		var final_exp = {}

		//comment out to allow multiple accordions to be open simultaneously
		final_exp = Object.assign({}, new_exp)

		final_exp = new_exp

		setExpanded(final_exp)
	}

	return (
		<React.Fragment>			
			<div
				style={{
					padding: props.user ? '0px' : '16px',
				}}
			>
				<h3>Contact Us</h3>
				<h4>
					By phone: <Link href="tel:+18447770420">844-777-0420</Link>
				</h4>
				<h4>
					By email:{' '}
					<Link href="mailto:contact@greenlightalerts.com">
						contact@greenlightalerts.com
					</Link>
				</h4>
			</div>
			<div className={props.classes.root} style={{
				padding: props.user ? '0px' : '16px'
			}}>
				{faqs.map((f, i) => {
					return (
						<Accordion
							expanded={expanded['faq' + i] ? expanded['faq' + i] : false}
							onChange={handleChange('faq' + i)}
							key={'faq' + i}
							TransitionProps={{
								timeout: 0,
							}}
							className={props.classes.faq + ' faq'}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={'faq' + i + 'bh-content'}
								id={'faq' + i + 'bh-header'}
							>
								<Typography className={props.classes.heading + ' bold'}>
									{f.title}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant={'body2'} component="div">
									{f.body}
								</Typography>
							</AccordionDetails>
						</Accordion>
					)
				})}
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(FAQ))
