
import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline'
import NotReadyIcon from '@material-ui/icons/ErrorOutlineOutlined'
import Gear from '@material-ui/icons/SettingsTwoTone'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Button, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	not_ready: {
        marginTop: '8px'
    }
}))

function NotReady(props) {
	const classes = useStyles()
    
	return (
		<Container component="main" maxWidth="xs" disableGutters>
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Avatar className={classes.avatar}>
					<NotReadyIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Not Available
				</Typography>
				
                <Grid
						container
						alignItems="stretch"
						direction="row"
						className={classes.not_ready}
					>

<Typography component="p" variant="body1">
This feature isn't available for your account yet. We will contact you when you are able to access this feature.
</Typography>

{props.user && !props.user.parent_account ?
<Box mt={2}>
<Button variant="contained" color="primary" component={Link} to="/payments" endIcon={<Gear />}>
    Manage Account
</Button>
</Box> : null }

                </Grid>

			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,    
	baseurl: state.baseurl
})

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(setUser(user)),
    
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotReady)
