import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
//import RequestNumberActions from './RequestNumberActions'
import { connect } from 'react-redux'
import { setAlert, setUser, setTemp } from '../../../actions'

import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import Numbers from './Numbers'
import countries from '../Countries'

const useStyles = makeStyles((theme) => ({
	formControl: {
		marginTop: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))

function RequestNumber(props) {
	const [open, setOpen] = React.useState(false)
	const [numbers, setNumbers] = React.useState([])
	const [type, setType] = React.useState('local')
	const [amount, setAmount] = React.useState(5)
	const [area, setArea] = React.useState(props.user.area_code)
	const [selected, setSelected] = React.useState([])
	const [country, setCountry] = React.useState('US')
	const [countryInfo, setCountryInfo] = React.useState({
        name: 'United States',
        dialCode: '+1',
        isoCode: 'US',
    })

	const classes = useStyles()

	var changeSelected = (nums) => {
		setSelected(nums)
	}

	var requestnumbers = () => {

        var complete_request = () => {
            axios
			.post(props.baseurl + 'remote/numbers/create', {
				token: props.user.token,
				numbers: selected,
				amount: selected.length,
			})
			.then((result) => {
				if (result.data.status === true) {
					handleClose()
				} else {
					props.setAlert({
						open: true,
						severity: result.data.severity,
						message: result.data.message,
					})
				}
			})
        }

        var temp = Object.assign({}, props.temp, {
            confirm: {
                title: 'Confirm purchase',
                callback: complete_request
            }
        })

        props.setTemp(temp)

		
	}

	var lookupnumbers = () => {

        setSelected([])
        setNumbers([])

		axios
			.post(props.baseurl + 'remote/numbers/lookup', {
				token: props.user.token,
				area: area,
				country: countryInfo,
				type: type,
				page_size: amount,
			})
			.then((result) => {
				if (result.data.status === true) {
					//handleClose()

					setNumbers(result.data.numbers)
				} else {
					props.setAlert({
						open: true,
						severity: result.data.severity,
						message: result.data.message,
					})
				}
			})
	}

	var handleArea = (e) => {
        setArea(e.target.value)
        setSelected([])        
        setNumbers([])
	}

	const handleClose = () => {
		setOpen(false)
		props.clear()
	}

	React.useEffect(() => {
		setOpen(props.open)
	}, [props.open])

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		setLabelWidth(inputLabel.current ? inputLabel.current.offsetWidth : 0)
	}, [])

	var country_list = countries.map((c, i) => {
		return (
			<MenuItem onClick={()=>{
				setCountryInfo({dialCode:c.dialCode, isoCode: c.isoCode, name: c.name})
			}} key={i}  value={c.isoCode}>
				{c.dialCode} {c.name}
			</MenuItem>
		)
	})

	return (
		<div>
			<Dialog
				fullWidth={numbers.length ? true : true}
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle style={{ paddingBottom: '8px' }}>
					Search For Numbers
				</DialogTitle>
				<DialogContent style={{ paddingTop: '0px' }}>
					<Grid container spacing={1} alignItems="center">
						<Grid item xs={12}>
							<TextField
								style={{ marginTop: '16px' }}
								label="Area Code"
								type="area"
								fullWidth
								onChange={handleArea}
								value={area}
								variant="standard"
							/>
						</Grid>
					</Grid>

					<div>
						<FormControl
							variant="filled"
							className={classes.formControl}
							style={{ marginTop: '16px' }}
							fullWidth
						>
							<InputLabel ref={inputLabel}>Country</InputLabel>
							<Select
								value={country ? country : 'US'}
								onChange={(e) => {									
									setCountry(e.target.value)									
								}}
								labelWidth={labelWidth}
							>
								{country_list}
							</Select>
						</FormControl>
					</div>

					<FormControl
						variant="filled"
						className={classes.formControl}
						style={{ marginTop: '16px' }}
						fullWidth
					>
						<InputLabel ref={inputLabel}>Number Type</InputLabel>
						<Select
							value={type ? type : 'local'}
							onChange={(e) => {
								setType(e.target.value)
                                setSelected([])
                                setNumbers([])
								if (e.target.value === 'tollfree') {
									setArea('855')
								} else {
									setArea(props.user.area_code)
								}
							}}
							labelWidth={labelWidth}
						>
							<MenuItem value={'local'}>Local</MenuItem>
							{/*<MenuItem value={'mobile'}>Mobile</MenuItem>*/}
							<MenuItem value={'tollfree'}>Toll-Free</MenuItem>
						</Select>
					</FormControl>

					<FormControl
						variant="standard"
						className={classes.formControl}
						style={{marginTop: '16px'}}
						fullWidth
					>
						<InputLabel ref={inputLabel}>Show how many results?</InputLabel>
						<Select
							style={{ width: '100%' }}
							value={amount}
							onChange={(e) => {
								setAmount(e.target.value)
							}}
						>
							<MenuItem value={5}>5</MenuItem>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={20}>20</MenuItem>
						</Select>
					</FormControl>

					{numbers.length ? (
						<Numbers
							numbers={numbers}
							requestnumbers={requestnumbers}
                            changeSelected={changeSelected}
                            selected={selected}
						/>
					) : null}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>

					<Button onClick={lookupnumbers} color="primary">
						Search
					</Button>
				</DialogActions>
				{selected.length ? (
					<DialogActions>
						<Button
                            fullWidth
							onClick={requestnumbers}
							color="primary"
							variant="contained"
							disabled={!selected.length}
						>
							Purchase
						</Button>
					</DialogActions>
				) : null}
			</Dialog>
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
    baseurl: state.baseurl,
    temp: state.temp
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
    setUser: (user) => dispatch(setUser(user)),
    setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestNumber)
