import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import { darken, lighten } from '@material-ui/core/styles/colorManipulator'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import FileDownload from 'js-file-download'
import Triggers from './Triggers'

const styles = theme => {
	return {
		paper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		buttons: {
			width: '50%',
		},
		striper:
			theme.palette.type === 'light'
				? {
						color: darken(theme.palette.secondary.dark, 0.2),
						backgroundColor: lighten(theme.palette.secondary.dark, 0.5),
				  }
				: {
						color: theme.palette.text.primary,
						backgroundColor: darken(theme.palette.secondary.dark, 0.5),
				  },

		header: {
			textAlign: 'center',
		},
		column: {
			textAlign: 'center',
		},
	}
}

class Report extends React.Component {
	state = {
		cols: [],
		data: [],
	}

	getReport = download => {
		this.setState({ data: [] })
		axios
			.post(this.props.baseurl + 'remote/reports/messages', {
				token: localStorage.getItem('token'),
				download: download ? download : false,
			})
			.then(res => {
				if (download) {
					FileDownload(res.data, 'report.csv')

					return false
				}

				var headers = res.data.columns.map((header, i) => {
					header.headerClassName = this.props.classes.header
					header.className = this.props.classes.column
					return header
				})

				this.setState({ data: res.data.rows, cols: headers })
			})
	}

	componentDidMount() {
		this.getReport()
	}

	render() {
		const { data, cols } = this.state

		return (
			<React.Fragment>
				<CssBaseline />

				<Grid container>
					<Grid item xs={12} sm={12} md={12}>
						<Box p={1}>
							<Grid container direction="row">
								<Grid item xs={12}>
									<Triggers
										reload={() => {
											this.getReport(false)
										}}
										export={() => {
											this.getReport(true)
										}}
										data={this.state.data}
									/>
								</Grid>
							</Grid>
						</Box>
						<Box p={1}>
							{data.length ? (
								<ReactTable
									data={data}
									minRows={0}
									columns={cols}
									defaultPageSize={1000}
									className={'-striped -highlight '}
									noDataText="Rows not loaded"
									style={{
										minHeight: '400px',
										marginTop: '8px',
									}}
									pageSizeOptions={[
										5,
										10,
										20,
										25,
										50,
										100,
										250,
										500,
										1000,
										5000,
									]}
								/>
							) : null}
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Report))
