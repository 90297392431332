import { history } from '../index';

const auth = (state = {}, action) => {
	switch (action.type) {
		case 'DIRECTOR':

			var push = true

        console.log(action)

			if (push === true) {
				history.push(action.to)
			} else {
				history.replace(action.to)
			}

			return state
		default:
			return state
	}
}

export default auth
