import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'

import Drawer from '@material-ui/core/Drawer'

import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Sidebar from './Sidebar'

import Director from '../components/Director'

import { SignOut } from '../actions'

import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withStyles, useTheme } from '@material-ui/core/styles'

import { Grid, Container } from '@material-ui/core'

import { connect } from 'react-redux'
import { setUser, setTemp, setTheme, setLoaded, setSocket } from '../actions'
//import axios from 'axios'

import Error from 'components/views/Error'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { ThemeProvider } from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'

//const drawerWidth = '192px'

const useStyles = withStyles(
	(theme) => ({
		logo_box: {
			padding: '0px',
			height: '64px',
			textAlign: 'center',
		},
		logo: {
			width: '100%',
			position: 'relative',
			top: '18px',
			padding: '2px',

			paddingRight: '0px',
		},
		small_logo: {
			height: '100%',
			position: 'absolute',
			top: '0px',
			right: '0px',
			padding: '5px',
			paddingRight: '10px',
		},
		toolbar: {},
		barSpacer: {
			marginBottom: '56px',
		},
		no_bar_paper: {
			minWidth: '192px',
		},
		bar_paper: {
			minWidth: '192px',
		},
		menuButton: {
			color: '#fff',
		},
		content: {
			height: 'calc(100% - 56px)',
		},
	}),
	{
		withTheme: true,
	}
)

function ResponsiveDrawer(props) {
	const theme = useTheme()
	const [mobileOpen, setMobileOpen] = React.useState(false)

	const matches = props.theme.breakpoints.down('sm')

	var isSmall = useMediaQuery(matches)

	React.useEffect(() => {}, [props.theme])

	const toggleDrawer = (state) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return
		}

		if (mobileOpen) {
			handleDrawerToggle(state)
		}
	}

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	const sideList = () => {
		return (
			<Sidebar
				toggleDrawer={toggleDrawer}
				handleDrawerToggle={handleDrawerToggle}
				header={true}
				needs_brand={props.needs_brand}
			/>
		)
	}

	const container =
		window !== undefined ? () => window.document.body : undefined

	var force_top = window?.location.href.includes('/help')

	if (props.is_viewing) {
		var container_styles = {
			//marginTop: '16px',
			marginTop: 0,
			marginLeft: 0,
			marginRight: 0,
			padding: 0,
		}
	} else {
		container_styles = {
			marginTop: '16px',
		}
	}

	if (!props.user) {
		container_styles = {
			marginTop: 0,
			marginLeft: 0,
			marginRight: 0,
			padding: 0,
		}
	}

	return (
		<React.Fragment>
			<Hidden mdUp implementation="css">
				<AppBar position="fixed" className={props.classes.appBar} elevation={1}>
					<Toolbar className={props.classes.bar}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							className={props.classes.menuButton}
						>
							<MenuIcon />
						</IconButton>
						{props.user || 1 ? (
							<Hidden mdUp implementation="css">
								<Typography
									variant="h6"
									noWrap
									onClick={() => {
										props.setLoaded(false)
										Director('/dashboard')
									}}
								>
									<img
										src={
											props.parent_theme === 'dark'
												? '/assets/logo_dark_blank.png'
												: '/assets/logo_dark_blank.png'
										}
										className={props.classes.small_logo}
										alt=""
									/>
								</Typography>
							</Hidden>
						) : null}
					</Toolbar>
				</AppBar>
			</Hidden>
			<nav className={props.classes.drawer} aria-label="mailbox folders">
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden mdUp implementation="css">
					<ThemeProvider theme={props.themes['dark']}>
						<CssBaseline />
						<Drawer
							transitionDuration={{ enter: 250, exit: 0 }}
							container={container}
							variant="temporary"
							anchor={theme.direction === 'rtl' ? 'right' : 'left'}
							open={mobileOpen && isSmall}
							onClose={handleDrawerToggle}
							classes={{
								paper: props.classes.bar_paper,
							}}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}
						>
							{sideList()}
						</Drawer>
					</ThemeProvider>
					<div className={props.classes.barSpacer}></div>
				</Hidden>
				<Hidden smDown implementation="css">
					<ThemeProvider theme={props.themes['dark']}>
						<CssBaseline />

						{props.needs_brand || props.user ? (
							<Drawer
								classes={{
									paper: props.classes.no_bar_paper,
								}}
								variant="permanent"
								open
							>
								{sideList()}
							</Drawer>
						) : null}
					</ThemeProvider>
				</Hidden>
			</nav>
			<Grid
				className={props.classes.content}
				style={{
					paddingBottom: props.user ? '16px' : '0px',
					overflow: 'auto',
					marginLeft:
						props.needs_brand || (props.user && props.isBig) ? '192px' : '0px',

					width:
						props.needs_brand || (props.user && props.isBig)
							? 'calc(100% - 192px)'
							: '100%',
				}}
				alignItems={props.user || force_top ? 'stretch' : 'center'}
				alignContent={props.user || force_top ? 'stretch' : 'center'}
				justifyContent={props.user || force_top ? 'flex-start' : 'center'}
				container
			>
				<div className={props.classes.toolbar} />

				<Container
					className={props.classes.main}
					style={container_styles}
					maxWidth={false}
				>
					{props.token && !props.user && !props.tokens ? (
						<Error SignOutFunction={props.SignOutFunction} />
					) : (
						props.auth_content
					)}
				</Container>
			</Grid>
		</React.Fragment>
	)
}

ResponsiveDrawer.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
}

const mapStateToProps = (state) => ({
	user: state.auth,
	loaded: state.loaded,
	temp: state.temp,
	baseurl: state.baseurl,
	socket: state.socket,
	parent_theme: state.theme,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setSocket: (socket) => dispatch(setSocket(socket)),
	SignOut: () => dispatch(SignOut()),
	setTheme: (theme) => dispatch(setTheme(theme)),

	setLoaded: (loaded) => dispatch(setLoaded(loaded)),

	setTemp: (temp) => dispatch(setTemp(temp)),

	Out: (callback, user) => {
		dispatch((dispatch) => {
			let u = dispatch(SignOut(user)).user

			callback(u)
		})
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(useStyles(ResponsiveDrawer))
