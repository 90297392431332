import React, { useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import { setNumber } from '../../../actions/numbers'
import axios from 'axios'
import Status from './Status'
import Box from '@material-ui/core/Box'
import Director from '../../Director'

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Edit(props) {
	console.log(props)
	const classes = useStyles()

	const [number, setNumber] = React.useState(props.number)
	const loaded = useRef(false)

	

	useEffect(() => {
		if (props.match.params.numberID) {
		load()
		} else {
			props.setNumber({
				status: 'active'
			})
		}
		// eslint-disable-next-line
	}, [])
	
	React.useEffect(() => {
		setNumber(props.number)
	}, [props.number])


	var load = () => {
		axios
			.post(props.baseurl + 'remote/numbers/load', {
				token: props.user.token,
				_id: props.match.params.numberID,
			})
			.then(result => {
				if (!result.data._id) {
					result.data.status = 'active'
				}
				props.setNumber(result.data)
				loaded.current = true
			})
	}

	

	var updateNumber = (field, value) => {
		var updates = {}
		updates[field] = value

		setNumber(Object.assign({}, number, updates))

		props.setNumber(Object.assign({}, number, updates))
	}

	var submitSave = e => {
		e.preventDefault()

		axios
			.post(props.baseurl + 'remote/numbers/save', {
				token: props.user.token,
				_id: number._id,
				phone_number: number.phone_number,
				status: number.status,
			})
			.then(result => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})

				if (result.data.new) {
					loaded.current = false
					Director('/numbers/edit/' + result.data.new, true)
				}

			})
			.catch(err => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: 'Error',
				})
			})
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Typography component="h1" variant="h5">
					{props.title ? props.title : 'Edit Number'}
				</Typography>
				{number ? (
					<form className={classes.form} noValidate onSubmit={submitSave}>
						<TextField
							variant="standard"
							margin="normal"
							required
							fullWidth
							id="phone_number"
							label="Phone Number"
							name="phone_number"
							autoComplete="phone"
							disabled={props.match.params.numberID ? true : false}
							autoFocus
							value={number.phone_number ? number.phone_number : ''}
							onChange={e => {
								updateNumber('phone_number', e.target.value)
							}}
						/>
					
						<Box pt={1}>
							<Status updateNumber={updateNumber} />
						</Box>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							Save Number
						</Button>
					</form>
				) : null}
			</div>
		</Container>
	)
}

const mapStateToProps = state => ({
	user: state.auth,
	number: state.number,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setNumber: number => dispatch(setNumber(number)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
