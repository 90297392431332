import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/Accordion'
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary'
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Grid } from '@material-ui/core'
import Moment from 'moment-timezone'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
}))

export default function SimpleExpansionPanel(props) {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<ExpansionPanel variant="outlined">
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.heading}>
						{Moment(props.order.charge_source.created)
							.tz('America/Los_Angeles')
							.format('MM/DD/YYYY hh:mm:ss A')}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<Grid item container key={props.order._id.toString()}>
						<Grid item container>
							<Grid item xs={4} sm={4} md={6}>
								Date & Time
							</Grid>
							<Grid item xs={8} sm={8} md={6}>
								{Moment(props.order.charge_source.created)
									.tz('America/Los_Angeles')
									.format('MM/DD/YYYY hh:mm:ss A')}
							</Grid>
						</Grid>

						<Grid item container>
							<Grid item xs={4} sm={4} md={6}>
								Receipt ID
							</Grid>
							<Grid item xs={8} sm={8} md={6}>
								{props.order._id.toString()}
							</Grid>
						</Grid>

						<Grid item container>
							<Grid item xs={4} sm={4} md={6}>
								Amount
							</Grid>
							<Grid item xs={8} sm={8} md={6}>
								{'$' + (props.order.charge_source.amount / 100).toFixed(2)}
							</Grid>
						</Grid>

						<Grid item container>
							<Grid item xs={4} sm={4} md={6}>
								Card
							</Grid>
							<Grid item xs={8} sm={8} md={6}>
								{props.order.charge.brand} {props.order.charge.last4}
							</Grid>
						</Grid>
					</Grid>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	)
}
