import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { setAlert, setTemp } from '../../../actions'
import { connect } from 'react-redux'
import Director from '../../Director'

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120,
		marginTop: '0px',
		marginBottom: '0px'
	},
}))

function Status(props) {

	const classes = useStyles()

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)

	const [status, setStatus] = React.useState(props.status ? props.status : '')

	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth)
	}, [])

	React.useEffect(() => {
		setStatus(props.status)
    }, [props.status])

	const handleChange = e => {        		
		
			//props.setFilter('status', e.target.value)
			setStatus(e.target.value)			
			Director('/messages/'+e.target.value)
		
	}

	return (
			<FormControl variant="standard" className={classes.formControl} margin="dense" fullWidth>
				<InputLabel ref={inputLabel}>Show Messages</InputLabel>
				<Select					
					value={status}
					onChange={handleChange}
					labelWidth={labelWidth}										
				>
					<MenuItem value={''}>All Messages</MenuItem>
					<MenuItem value={'draft'}>Draft</MenuItem>
					<MenuItem value={'inbound'}>Inbound</MenuItem>
					<MenuItem value={'queued'}>Queued</MenuItem>
					<MenuItem value={'paused'}>Paused</MenuItem>
					<MenuItem value={'scheduled'}>Scheduled</MenuItem>					
					<MenuItem value={'reply'}>Reply</MenuItem>
					<MenuItem value={'sent'}>Sent</MenuItem>
					<MenuItem value={'auto'}>Auto Response</MenuItem>
					<MenuItem value={'stop'}>Stop</MenuItem>

					
				</Select>
			</FormControl>
	)
}

const mapStateToProps = state => ({
	user: state.auth,	
	groups: state.groups,
})

const mapDispatchToProps = dispatch => ({	
	setAlert: alert => dispatch(setAlert(alert)),
	setTemp: temp => dispatch(setTemp(temp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Status)
