import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import VerifiedUser from '@material-ui/icons/VerifiedUser'
import Tools from '../tools/Tools'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'

const useStyles = makeStyles({
	root: {
		maxWidth: '100%',
	},
})

function MediaCard(props) {
	const classes = useStyles()


	return (
		<Card className={classes.root}>
			<CardActionArea>
				<CardContent>
					<Grid
						container
						justifyContent="center"
						direction="row"
						alignItems="center"
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<VerifiedUser fontSize="large" color="primary" style={{marginRight: '8px', color: '#4caf50'}} />

						<Typography variant="h5" component="p">
							Connected
						</Typography>
					</Grid>

					<Typography variant="body2" color="textSecondary" component="p" style={{marginTop: '8px'}}>
						Your Twilio account is connected to your dashboard.
					</Typography>


<div style={{marginTop: '8px'}}>
<Typography variant="body1" color="textSecondary" component="span">
						Available Balance:{' '}
					</Typography>

                    <Typography color="primary" variant="body1" component="span">{Tools.formatter(!props.user.parent_account ? props.user.account_data ? props.user.account_data.balance : props.user.balance : props.user.credits)}</Typography>
</div>

				</CardContent>
			</CardActionArea>
		</Card>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MediaCard)
