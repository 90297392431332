import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import StarIcon from '@material-ui/icons/StarBorder'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
//import Box from '@material-ui/core/Box';
import { connect } from 'react-redux'
import { setAlert, setUser, setTemp, setCheckout } from '../../actions'

const useStyles = makeStyles((theme) => ({
	'@global': {
		ul: {
			margin: 0,
			padding: 0,
			listStyle: 'none',
		},
	},
	toolbarTitle: {
		flexGrow: 1,
	},
	link: {
		margin: theme.spacing(1, 1.5),
	},
	heroContent: {
		padding: theme.spacing(8, 0, 6),
	},
	cardHeader: {
		backgroundColor:
			theme.palette.type === 'light'
				? theme.palette.grey[200]
				: theme.palette.grey[700],
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginBottom: theme.spacing(2),
	},
}))

function Pricing(props) {
	const classes = useStyles()

	const [selected, setSelected] = React.useState(props.choice)

	const tiers = [
		{
			title: 'Basic',
			price: props.temp.promo ? (props.temp.promo.amount * 250) / 100 : '250',
			package: 1,
			description: [
				'1 profile update per day',
				'1 mass notification per day',
				'Custom Greenlight code',
			],
			buttonText: 'Sign up for free',
			buttonVariant: 'outlined',
		},
		{
			title: 'Plus',
			subheader: 'Most popular',
			price: props.temp.promo ? (props.temp.promo.amount * 500) / 100 : '500',
			package: 2,
			description: [
				'Unlimited profile updates',
				'Unlimited mass notifications',
				'Website / social media links',
				'Custom Greenlight code',
			],
			buttonText: 'Get started',
			buttonVariant: 'contained',
		},
		{
			title: 'Pro',
			price: props.temp.promo
				? (props.temp.promo.amount * 1000) / 100
				: '1,000',
			package: 3,
			description: [
				'Unlimited profile updates',
				'Unlimited mass notifications',
				'Custom notification text',
				'Real-time chat with users',
				'Online store / shopping',
				'Add a video to profile',
				'Display image with notification',
				'Website / social media links',
				'Custom Greenlight code',
			],
			buttonText: 'Contact us',
			buttonVariant: 'outlined',
		},
	]

	React.useEffect(() => {
		setSelected(props.choice)
	}, [props.choice])

	return (
		<React.Fragment>
			<CssBaseline />

			<Container maxWidth="md" component="main" style={{ marginTop: '16px' }}>
				<Grid container spacing={5} alignItems="flex-end">
					{tiers.map((tier, i) => (
						// Enterprise card is full width at sm breakpoint
						<Grid
							item
							key={tier.title}
							xs={12}
							sm={tier.title === 'Enterprise' ? 12 : 12}
							md={4}
						>
							<Card
								onClick={() => {
									//setSelected(i)

									if (props.user && props.user.package === i + 1) {
										return false
									}

									props.setChoice(i + 1)

									var temp = Object.assign({}, props.temp)
									temp.tempChoice = i + 1
									props.setTemp(temp)
								}}
							>
								<CardHeader
									title={tier.title}
									subheader={tier.subheader}
									titleTypographyProps={{ align: 'center' }}
									subheaderTypographyProps={{
										align: 'center',
										style: {
											color: props.choice === i + 1 ? '#fff' : 'inherit',
										},
									}}
									action={tier.title === 'Plus' ? <StarIcon /> : null}
									className={classes.cardHeader}
									style={{
										backgroundColor:
											props.choice === i + 1
												? process.env.REACT_APP_PRIMARY_COLOR
												: 'inherit',
										color: props.choice === i + 1 ? '#fff' : 'inherit',
									}}
								/>
								<CardContent>
									<div className={classes.cardPricing}>
										<Typography component="h2" variant="h3" color="textPrimary">
											${tier.price}
										</Typography>
										<Typography variant="h6" color="textSecondary">
											/mo
										</Typography>
									</div>
									<ul>
										{tier.description.map((line) => (
											<Typography
												component="li"
												variant="subtitle1"
												align="center"
												key={line}
											>
												{line}
											</Typography>
										))}
									</ul>
								</CardContent>
								<CardActions>
									<Button
										//style={{visibility: props.user.package >= (i+1) ? 'hidden' : 'visible'}}
										disabled={props.user && props.user.package === i + 1}
										fullWidth
										variant={selected === i + 1 ? 'contained' : 'contained'}
										color={selected === i + 1 ? 'primary' : 'secondary'}
									>
										{props.user && props.user.package === i + 1
											? 'current plan'
											: selected === i + 1
											? 'selected'
											: 'select'}
									</Button>
								</CardActions>
							</Card>
						</Grid>
					))}
				</Grid>
			</Container>
		</React.Fragment>
	)
}
const mapStateToProps = (state) => ({
	user: state.auth,
	temp: state.temp,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
	setUser: (user) => dispatch(setUser(user)),
	setTemp: (temp) => dispatch(setTemp(temp)),
	setCheckout: (checkout) => dispatch(setCheckout(checkout)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Pricing)
