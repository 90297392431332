import React from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import { Grid, Container, CssBaseline } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import { setAlert } from '../../actions'
import axios from 'axios'
import Director from '../Director'
import { Link as Links } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function SignUp(props) {
	const classes = useStyles()

	const [email, setEmail] = React.useState('')
	const [password, setPassword] = React.useState('')
	const [confirm_password, setConfirmPassword] = React.useState('')

	var submitSignUp = (e) => {
		e.preventDefault()

		if (password !== confirm_password) {
			props.setAlert({
				open: true,
				severity: 'error',
				message: 'Passwords do not match',
			})

			return false
		}

		axios
			.post(props.baseurl + 'remote/user/signup', {
				email: email,
				password: password,
			})
			.then((result) => {
				if (result.data && result.data.error) {
					props.setAlert({
						open: true,
						severity: 'error',
						message: result.data.error,
					})
				} else {
					props.setUser(result.data)
					Director('/subscribe/onboard')
					window.scrollTo(0, 0)
				}
			})
			.catch((err) => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: `That action can't be performed right now`,
				})
			})
	}

	return (
		<Container
			component="main"
			disableGutters
			maxWidth="xs"
			style={{
				padding: props.user ? '0px' : '16px',
			}}
		>
			<CssBaseline />

			<div
				style={{
					marginBottom: '16px',
					marginTop: '16px',
					textAlign: 'center',
					display: 'none',
				}}
			>
				<img
					src={
						props.parent_theme === 'dark'
							? '/assets/logo_dark.png'
							: '/assets/logo.png'
					}
					alt=""
					style={{ maxWidth: '128px' }}
				/>
			</div>

			<Grid
				container
				justifyContent="center"
				direction="row"
				alignItems="center"
				style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '16px' }}
			>
				<LockOutlinedIcon
					fontSize="large"
					color="primary"
					style={{ marginRight: '8px' }}
				/>

				<Typography component="h1" variant="h5">
					Sign Up
				</Typography>
			</Grid>

			{props.temp.tempChoice && props.temp.tempChoice > 0 ? (
				<Grid
					item
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					alignContent="center"
					display="flex"
					style={{
						marginTop: '16px',
						color: '#464646',
					}}
				>
					<Typography variant="subtitle">
						You selected the{' '}
						{props.temp.tempChoice === 1
							? 'Basic'
							: props.temp.tempChoice === 2
							? 'Plus'
							: 'Pro'}{' '}
						Package.
						<br />
						<br />
						After you Sign Up you can Continue To Payment or Skip & Continue to
						your dashboard.
					</Typography>
				</Grid>
			) : null}
			<form className={classes.form} noValidate onSubmit={submitSignUp}>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="email"
					placeholder="Email Address or Mobile Phone Number"
					name="email"
					autoComplete="email"
					value={email}
					onChange={(e) => {
						setEmail(e.target.value)
					}}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					placeholder="Password"
					type="password"
					id="password"
					autoComplete="current-password"
					value={password}
					onChange={(e) => {
						setPassword(e.target.value)
					}}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="confirm_password"
					placeholder="Confirm Password"
					type="password"
					id="confirm_password"
					value={confirm_password}
					onChange={(e) => {
						setConfirmPassword(e.target.value)
					}}
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
				>
					Sign Up
				</Button>
				<Grid container>
					<Grid item xs>
						<Links component={Link} color="primary" to={'/forgot'}>
							Forgot password?
						</Links>
					</Grid>
					<Grid item>
						<Links
							component={Link}
							color="primary"
							to="/signin"
							variant="body2"
						>
							Already have an account? Sign In
						</Links>
					</Grid>
				</Grid>
			</form>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
	temp: state.temp,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),

	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
