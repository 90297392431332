const isinit = (state = false, action) => {
	switch (action.type) {
		case 'SET_ISINIT':			
		return action.isinit
		default:
			return state
	}
}

export default isinit
