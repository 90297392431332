import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import { setUser, setAlert } from '../../../actions'
import { setContact } from '../../../actions/contacts'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	formControl: {
		marginTop: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))

function Columns(props) {
	const classes = useStyles()	
	const [columns, setColumns] = React.useState(props.columns)


	React.useEffect(() => {
		setContact(props.contact)
	}, [props.contact])

	React.useEffect(() => {
		setColumns(props.columns)
	}, [props.columns])

	var allRows = props.contacts.split('\n')

	var importing = allRows.slice(
		props.ignoreFirstRow ? 1 : 0,
		props.ignoreFirstRow ? 6 : 5
	)
	var contacts = importing.map(function (item, index) {
		return item.split(',')
	})

	var diff = allRows.length - importing.length

	var rows = contacts.map((c, i) => {
		return (
			<Grid
				key={i}
				container
				item
				xs={12}
				spacing={0}
				className={i % 2 ? 'light_row' : 'dark_row'}
			>
				<Grid item xs={3} className='coldiv coldiv_first'>
					{c[0] ? c[0] : <span>&nbsp;</span>}
					{columns[0] === 'status' && c[0] !== 'active' && c[0] !== 'stop' ? '(active)' : null}
				</Grid>
				<Grid item xs={3} className='coldiv'>
					{c[1] ? c[1] : ''}
					{columns[1] === 'status' && c[1] !== 'active' && c[1] !== 'stop' ? '(active)' : null}
				</Grid>
				<Grid item xs={2} className='coldiv'>
					{c[2] ? c[2] : ''}
					{columns[2] === 'status' && c[2] !== 'active' && c[2] !== 'stop' ? '(active)' : null}
				</Grid>
				<Grid item xs={2} className='coldiv'>
					{c[3] ? c[3] : ''}
					{!c[3] && columns[3] === 'status' && c[3] !== 'active' && c[3] !== 'stop' ? '(active)' : null}
				</Grid>
				<Grid item xs={2} className='coldiv'>
					{c[4] ? c[4] : ''}
					{columns[4] === 'status' && c[4] !== 'active' && c[4] !== 'stop' ? '(active)' : null}
				</Grid>
			</Grid>
		)
	})
	
	return (
		<React.Fragment>
			<Grid container item xs={12} spacing={0}>
				<Grid item xs={3}>
					<FormControl
						variant="standard"
						className={classes.formControl}
						fullWidth
					>
						<InputLabel>column</InputLabel>
						<Select value={columns[0]} onChange={(e) => {
							props.setColumn(0,e.target.value)
						}}>
							<MenuItem value={'phone_number'}>phone</MenuItem>
							<MenuItem value={'email'}>email</MenuItem>
							<MenuItem value={'name'}>name</MenuItem>
							<MenuItem value={'status'}>status</MenuItem>
							<MenuItem value={'date_added'}>date added</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={3}>
				<FormControl
						variant="standard"
						className={classes.formControl}
						fullWidth
					>
						<InputLabel>column</InputLabel>
						<Select value={columns[1]} onChange={(e) => {
							props.setColumn(1,e.target.value)
						}}>
							<MenuItem value={'phone_number'}>phone number</MenuItem>
							<MenuItem value={'email'}>email</MenuItem>
							<MenuItem value={'name'}>name</MenuItem>
							<MenuItem value={'status'}>status</MenuItem>
							<MenuItem value={'date_added'}>date added</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={2}>
				<FormControl
						variant="standard"
						className={classes.formControl}
						fullWidth
					>
						<InputLabel>column</InputLabel>
						<Select value={columns[2]} onChange={(e) => {
							props.setColumn(2,e.target.value)
						}}>
							<MenuItem value={'phone_number'}>phone number</MenuItem>
							<MenuItem value={'email'}>email</MenuItem>
							<MenuItem value={'name'}>name</MenuItem>
							<MenuItem value={'status'}>status</MenuItem>
							<MenuItem value={'date_added'}>date added</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={2}>
				<FormControl
						variant="standard"
						className={classes.formControl}
						fullWidth
					>
						<InputLabel>column</InputLabel>
						<Select value={columns[3]} onChange={(e) => {
							props.setColumn(4,e.target.value)
						}}>
							<MenuItem value={'phone_number'}>phone number</MenuItem>
							<MenuItem value={'email'}>email</MenuItem>
							<MenuItem value={'name'}>name</MenuItem>
							<MenuItem value={'status'}>status</MenuItem>
							<MenuItem value={'date_added'}>date added</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={2}>
				<FormControl
						variant="standard"
						className={classes.formControl}
						fullWidth
					>
						<InputLabel>column</InputLabel>
						<Select value={columns[4]} onChange={(e) => {
							props.setColumn(4,e.target.value)
						}}>
							<MenuItem value={'phone_number'}>phone number</MenuItem>
							<MenuItem value={'email'}>email</MenuItem>
							<MenuItem value={'name'}>name</MenuItem>
							<MenuItem value={'status'}>status</MenuItem>
							<MenuItem value={'date_added'}>date added</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>

			{rows}

			{diff > 0 ? <div>(previewing only the first 5 rows, there are {props.ignoreFirstRow ? diff-1 : diff} more rows not shown here)</div> : null}
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	contact: state.contact,
	groups: state.groups,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setContact: (contact) => dispatch(setContact(contact)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Columns)
