import React, { useRef } from 'react'
import Button from '@material-ui/core/Button'
import { Grid } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import { setMessage } from '../../../actions/messages'
import axios from 'axios'

import FormControl from '@material-ui/core/FormControl'
import Pages from '../../nav/Pages'

import Message from './Message'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(0),
	},
	submit: {
		margin: theme.spacing(1, 0, 2),
		borderRadius: '24px',
	},
}))

var date_diff = (last_update, compare_time) => {
	var d = new Date()

	var current_time = compare_time ? compare_time : d.getTime()

	var diff = current_time - last_update

	if (diff < 0) {
		diff = diff * -1
	}

	var totalSeconds = diff / 1000

	const totalMinutes = Math.floor(totalSeconds / 60)

	const totalHours = Math.floor(totalMinutes / 60)

	const totalDays = Math.floor(totalHours / 24)

	const hours = totalHours - totalDays * 24

	const minutes = totalMinutes - totalDays * 24 * 60 - hours * 60

	const seconds =
		totalSeconds - totalDays * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

	var ret = ''

	var days = Math.abs(totalDays)

	if (days) {
		ret = ret ? ret + ' ' : ''

		ret = ret + days + ' day' + (days > 1 || days < 1 ? 's' : '')
	}

	if (hours) {
		ret = ret ? ret + ' ' : ''

		ret = ret + hours + ' hour' + (hours > 1 || hours < 1 ? 's' : '')
	}

	if (minutes && !days) {
		ret = ret ? ret + ' ' : ''

		ret = ret + minutes + ' minute' + (minutes > 1 || minutes < 1 ? 's' : '')
	}

	if (seconds && !hours && !days) {
		ret = ret ? ret + ' ' : ''

		ret =
			ret +
			Math.floor(seconds) +
			' second' +
			(seconds > 1 || seconds < 1 ? 's' : '')
	}

	return ret.trim()
}

function Reply(props) {
	const classes = useStyles()

	const [message, setMessage] = React.useState({})
	const loadedref = useRef(false)
	const [saved, setSaved] = React.useState(false)
	const [data, setData] = React.useState([])
	const [resultData, setResultData] = React.useState(null)
	const [loaded, setLoaded] = React.useState(false)
	const [loading, setLoading] = React.useState(false)

	const [noReply] = React.useState(false)

	const [total, setTotal] = React.useState(0)

	const [retrieved, setRetrieved] = React.useState(0)

	const [pagination] = React.useState({
		page: 1,
		skip: 0,
		limit: 10,
		max: 10,
	})

	var load = React.useCallback(
		(pagination_settings, scroll) => {
			if (loading) {
				return false
			}

			setLoaded(false)

			setLoading(true)

			if (!pagination_settings) {
				pagination_settings = pagination
			}

			var query = { page: pagination_settings.page - 1 }
			/*
		var query_page =
			pagination_settings.page === -1 ? 0 : pagination_settings.page - 1
*/
			query.pageSize = 10
			query.search = ''

			var filter = {}
			filter.pagination = {}
			filter.pagination.limit = pagination_settings.page * 10
			filter.pagination.skip = (pagination_settings.page - 1) * 10
			filter.order_by = query.orderBy ? query.orderBy.accessor : null
			filter.order_direction = query.orderDirection
			filter.search_term = query.search
			filter.status = props.status
				? props.status
				: filter.status
				? filter.status
				: ''

			filter.no_reply = noReply

			return axios
				.post(props.baseurl + 'remote/messages/list', {
					token: localStorage.getItem('token'),
					download: false,
					filter: filter,
					query: query,
					convo: props.match.params.uuid ? props.match.params.uuid : null,
					uuid: props.match.params.uuid,
				})
				.then((result) => {
					setLoading(false)

					setTotal(result.data.total)
					setRetrieved(result.data.retrieved)
					setData(result.data.rows)
					setResultData(result.data)
					setLoaded(true)

					if (loaded) {
					}

					if (scroll) {
						window.scrollTo(0, document.body.scrollHeight)
					}
				})
		},
		[
			loaded,
			loading,
			noReply,
			pagination,
			props.baseurl,
			props.match.params.uuid,
			props.status,
		]
	)

	var updateMessage = (field, value) => {
		var updates = {}
		updates[field] = value

		setSaved(false)

		setMessage(Object.assign({}, message, updates))
	}

	var submitSave = (e) => {
		e.preventDefault()

		axios
			.post(props.baseurl + 'remote/messages/save', {
				token: props.user.token,
				body: message.body,
				to: props.match.params.uuid,
				push_token: props.match.params.phone_number,
				status: 'reply',
				reply: true,
			})
			.then((result) => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})

				setSaved(true)

				if (!props.match.params.messageID && result.data._id) {
					loadedref.current = false
					//Director('/messages/edit/' + result.data._id, true)
					load(pagination)
					updateMessage('body', '')
					//document.getElementById('body').focus()
				}
			})
			.catch((err) => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: 'Error',
				})
			})
	}

	//var lp = load(pagination)

	React.useEffect(() => {
		load(pagination)
	}, [load, pagination])

	var messages = []

	if (data) {
		messages = data.map((v, i) => {
			return (
				<Grid container style={{ marginBottom: '8px', width: '100%' }} key={i}>
					<Grid
						item
						xs={12}
						style={{
							justifyContent: 'right',
							alignItems: 'right',
							alignContent: 'right',
							flexDirection: 'row',
							paddingLeft: v.from === 'app' ? '10%' : 0,
							paddingRight: v.from === 'app' ? 0 : '10%',
						}}
					>
						<div
							style={{
								width: '100%',
								textAlign: v.from === 'app' ? 'right' : 'left',
							}}
						>
							<Message
								message={v}
								date_diff={date_diff}
								retrieve_date={retrieved}
							/>
						</div>
					</Grid>
				</Grid>
			)
		})
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Typography component="h1" variant="h5">
					{message
						? props.title
							? props.title
							: message && message._id
							? 'Reply to'
							: 'Reply to'
						: null}{' '}
					{message ? (
						<Button
							color="primary"
							component={Link}
							to={'/messages/convo/' + props.match.params.uuid}
						>
							{resultData && resultData.contact_user
								? resultData.contact_user
								: props.match.params.uuid.split('-')[0]}
						</Button>
					) : null}
				</Typography>

				<form className={classes.form} noValidate onSubmit={submitSave}>
					<FormControl component="fieldset" style={{ display: 'flex' }}>
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							id="body"
							label="Reply"
							name="body"
							//autoFocus
							disabled={
								saved && message && message.status === 'sent' ? true : false
							}
							value={message.body}
							onChange={(e) => {
								updateMessage('body', e.target.value)
							}}
							multiline={true}
							rows={5}
						/>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							disabled={saved ? true : false}
						>
							Send Message
						</Button>
					</FormControl>
				</form>

				{messages}

				<br />

				<Pages callback={load} pagination={pagination} total={total} />
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	message: state.message,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setMessage: (message) => dispatch(setMessage(message)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Reply)
