import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert, setTemp } from '../actions'
import {
	SignIn,
	SignUp,
	CreateAccount,
	Forgot,
	Reset,
	Verify,
	VerifyPhone,
	Change,
	Home,
	EditProfile,
	Preferences,
	Contacts,
	Messages,
	Groups,
	Keywords,
	Reports,
	Payments,
	Payment,
	Account,
	Accounts,
	Connect,
	Numbers,
	Credits,
	Choices,
	NotReady,
	Updates,
	Help,
	Privacy,
	Verified,
	Subscribe,
	Sub,
	Shop,
	SignOut as SignOutControl,
	Sample,
} from 'components/views'
import Auto from 'components/views/Auto'
import Edit from 'components/views/messages/Edit'
import Footer from './views/Footer'
import Publish from 'components/views/updates/Edit'
import Limited from 'components/views/Limited'
import Current from 'components/views/updates/Current'
import Alert from '../ui/Alert.js'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import DrawerBar from '../ui/DrawerBar'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = withStyles(
	(theme) => ({
		root: {
			padding: theme.spacing(3, 2),
		},
		main: {
			'& main:first-of-type': {
				marginLeft: '0px',
				paddingLeft: '0px',
				paddingRight: '0px',
			},
		},
	}),
	{ withTheme: true }
)

function RouterControl(props) {
	const mdmatches = props.theme.breakpoints.up('md')
	var isBig = useMediaQuery(mdmatches)

	var token = localStorage.getItem('token')

	var base_url =
		props.user && props.user.base_url && props.user.base_url !== '/'
			? props.user.base_url
			: '/'

	var locked = base_url === '/' ? true : false

	if (props.user && props.user.reseller) {
		var reseller = true
	} else {
		reseller = false
	}

	var global_props = props

	var is_view = useRouteMatch('/view/')

	var is_shop = useRouteMatch('/shop/')

	var is_shopcode = useRouteMatch('/shopcode/')

	if (is_view || is_shop || is_shopcode) {
		var is_viewing = true
	}

	var auth_content = (
		<React.Fragment>
			<Switch>
				<Route path="/forgot" exact component={Forgot} />
				<Route path="/sample" exact component={Sample} />
				<Route path="/help" exact component={Help} />

				<Route path="/privacy" exact component={Privacy} />
				<Route path="/reset/:resetToken?" component={Reset} />
				<Route path="/auto/:autoToken?/convo/:convo" component={Auto} />
				<Route path="/auto/:autoToken?" component={Auto} />
				<Route path="/verify/:verifyToken?" component={Verify} />
				<Route path="/verifyphone/:verifyToken?" component={VerifyPhone} />

				<Route
					path="/shopcode/:shopcode?"
					render={(props) => <Shop {...props} />}
				/>

				<Route
					path="/shop/:shopcode?"
					render={(props) => <Shop {...props} />}
				/>
				<Route
					path="/view/:shopcode?"
					render={(props) => <Shop {...props} />}
				/>
			</Switch>

			{props.user ? (
				<Switch>
					<Route
						path="/dashboard"
						exact
						render={(props) => <Account {...props} />}
					/>
					<Route path="/verified" exact component={Verified} />
					<Route
						path="/account/addpayment"
						render={(props) => <Payment {...props} redirect={'/payments'} />}
					/>
					<Route path="/credits" exact component={Credits} />
					<Route path="/choices" exact component={Choices} />
					<Route path="/subscribe" exact component={Subscribe} />

					<Route
						path="/subscribe/onboard"
						render={(props) => <Subscribe {...props} onboard={true} />}
					/>

					<Route path="/change" exact component={Change} />
					<Route path="/profile" exact component={EditProfile} />
					<Route path="/preferences" exact component={Preferences} />
					<Route path="/credentials" exact component={Preferences} />
					<Route path="/account" exact component={Account} />
					<Route path="/connect" component={Connect} />
				</Switch>
			) : (
				<Switch>
					{!token ? <Route path="/subscribe" exact component={Sub} /> : null}

					{!token ? (
						<Route
							path="/"
							exact
							render={(props) => {
								if (
									window.location.href.includes(
										process.env.REACT_APP_DASHBOARD_DOMAIN_CHECK
									)
								) {
									return <SignIn {...props} themes={global_props.themes} />
								} else {
									return <Home {...props} themes={global_props.themes} />
								}
							}}
						/>
					) : null}

					{!token ? <Route path="/signup" exact component={SignUp} /> : null}

					{!token ? <Route path="/signin" exact component={SignIn} /> : null}
				</Switch>
			)}
			<Switch>
				<Route
					path="/payments"
					exact
					render={(props) => {
						if (locked && global_props.user && global_props.user.parent_account)
							return <NotReady />
						return <Payments {...props} />
					}}
				/>

				<Route
					path="/numbers"
					render={(props) => {
						if (locked) return <NotReady />
						return <Numbers {...props} />
					}}
				/>
				<Route
					path="/accounts"
					render={(props) => {
						if (locked || !reseller) return <NotReady />
						return <Accounts {...props} />
					}}
				/>
				<Route
					path="/createaccount"
					exact
					render={(props) => {
						if (locked) return <NotReady />
						return <CreateAccount {...props} />
					}}
				/>
				<Route
					path="/contacts"
					render={(props) => {
						if (locked) return <NotReady />
						return <Contacts {...props} />
					}}
				/>
				<Route
					path="/messages/:status?"
					render={(props) => {
						if (locked) return <NotReady />
						return (
							<Messages
								{...props}
								status={
									props.match.params.status ? props.match.params.status : null
								}
							/>
						)
					}}
				/>

				<Route
					path="/updates/:status?"
					render={(props) => {
						if (locked) return <NotReady />
						return (
							<Updates
								{...props}
								status={
									props.match.params.status ? props.match.params.status : null
								}
							/>
						)
					}}
				/>

				<Route
					path="/new"
					render={(props) => {
						if (locked) return <NotReady />
						return <Edit {...props} />
					}}
				/>

				<Route
					path="/publish"
					render={(props) => {
						if (locked) return <NotReady />
						return <Publish {...props} />
					}}
				/>

				<Route
					path="/limited/:reason?"
					render={(props) => {
						if (locked) return <NotReady />
						return <Limited {...props} />
					}}
				/>

				<Route
					path="/current"
					render={(props) => {
						if (locked) return <NotReady />
						return <Current {...props} />
					}}
				/>

				<Route
					path="/groups"
					render={(props) => {
						if (locked) return <NotReady />
						return <Groups {...props} />
					}}
				/>

				<Route
					path="/keywords"
					render={(props) => {
						if (locked) return <NotReady />
						return <Keywords {...props} />
					}}
				/>

				<Route
					path="/reports"
					render={(props) => {
						if (locked) return <NotReady />
						return <Reports {...props} />
					}}
				/>
			</Switch>
		</React.Fragment>
	)

	var auto_match = useRouteMatch('/auto/:autoToken?')

	var auto_token = auto_match
		? auto_match.params.autoToken
			? true
			: false
		: false

	var reset_match = useRouteMatch('/reset/:resetToken?')

	var reset_token = reset_match
		? reset_match.params.resetToken
			? true
			: false
		: false

	var verify_match = useRouteMatch('/verify/:verifyToken?')

	var verify_token = verify_match
		? verify_match.params.verifyToken
			? true
			: false
		: false

	var verify_match_phone = useRouteMatch('/verifyphone/:verifyToken?')

	if (verify_match_phone) {
		verify_token = verify_match_phone
			? verify_match_phone.params.verifyToken
				? true
				: false
			: false
	}

	var help_match = useRouteMatch('/help')

	var subscribe_match = useRouteMatch('/subscribe')

	var signup_match = useRouteMatch('/signup')

	var signin_match = useRouteMatch('/signin')

	var forgot_match = useRouteMatch('/forgot')

	var privacy_match = useRouteMatch('/privacy')

	var local_token = localStorage.getItem('token')

	var root_match = useRouteMatch('/')

	var is_root = root_match
		? root_match.params.resetToken
			? true
			: false
		: false

	var needs_brand =
		isBig &&
		!localStorage.getItem('token') &&
		(reset_token ||
			verify_token ||
			auto_token ||
			is_root ||
			privacy_match ||
			forgot_match ||
			signup_match ||
			signin_match ||
			help_match ||
			subscribe_match ||
			is_view ||
			is_shop ||
			is_shopcode)

	return (
		<React.Fragment>
			{(is_view || is_shop || is_shopcode) && 1 === 2 ? (
				<React.Fragment>
					<Route
						path="/shopcode/:shopcode?"
						render={(props) => <Shop {...props} />}
					/>

					<Route
						path="/shop/:shopcode?"
						render={(props) => <Shop {...props} />}
					/>

					<Route
						path="/view/:shopcode?"
						render={(props) => <Shop {...props} />}
					/>
					<Footer view={true} needs_brand={needs_brand} />
				</React.Fragment>
			) : null}

			<Route path="/signout" exact component={SignOutControl} />
			{(!is_view && !is_shop && !is_shopcode) || 1 ? (
				<React.Fragment>
					<DrawerBar
						isBig={isBig}
						is_viewing={is_viewing}
						themes={props.themes}
						user={props.user}
						auth_content={auth_content}
						token={token}
						tokens={props.tokens}
						SignOutFunction={props.SignOutFunction}
						needs_brand={needs_brand}
					/>
					<Footer needs_brand={needs_brand} />
				</React.Fragment>
			) : null}
			{props.user && local_token ? (
				<Route path="/" exact>
					<Redirect to="/dashboard" />
				</Route>
			) : null}
			{!localStorage.getItem('token') &&
			!reset_token &&
			!verify_token &&
			!auto_token &&
			!is_root &&
			!privacy_match &&
			!forgot_match &&
			!signup_match &&
			!signin_match &&
			!help_match &&
			!subscribe_match &&
			!is_view &&
			!is_shop &&
			!is_shopcode ? (
				<Route path="/">
					<Redirect to="/" />
				</Route>
			) : null}
			<Alert alert={props.alert} />
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	temp: state.temp,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(useStyles(RouterControl))
