import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { Typography, CssBaseline, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert, SignOut } from '../../actions'
import axios from 'axios'
import Director from '../Director'
import { Link as Links } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Reset(props) {
	const classes = useStyles()
	const [resetToken] = React.useState(props.match.params.resetToken)
	const [password, setPassword] = React.useState('')
	const [user, setLocalUser] = React.useState(null)

	var submitReset = (e) => {
		e.preventDefault()
		axios
			.post(props.baseurl + 'remote/user/reset', {
				resetToken: resetToken,
				password: password,
				compareResetToken: null,
			})
			.then((result) => {
				if (result.data.token) {
					Director('/')
					localStorage.setItem('token', result.data.token)
					props.setUser(result.data)
					props.setAlert({
						open: true,
						severity: 'success',
						message: 'Password successfully changed',
					})
				}
			})
	}

	var so = props.SignOut()

	React.useEffect(() => {
		so()
	}, [so])

	var loadUser = () => {
		if (localStorage.getItem('token')) {
			return false
		}

		axios
			.post(props.baseurl + 'remote/user/load', {
				resetToken: resetToken,
				removeToken: false,
			})
			.then((result) => {
				if (result.data.error) {
					props.SignOut()

					Director('/')
					props.setAlert({
						open: true,
						severity: 'error',
						message: result.data.error,
					})
				} else {
					//props.SignOut()
					setLocalUser(result.data)
				}
			})
	}

	useEffect(() => {
		loadUser()
		// eslint-disable-next-line
	}, [])

	return (
		<Container
			component="main"
			disableGutters
			maxWidth="xs"
			style={{
				padding: props.user ? '0px' : '16px',
			}}
		>
			<CssBaseline />

			<div
				style={{
					marginBottom: '16px',
					marginTop: '16px',
					textAlign: 'center',
					display: 'none',
				}}
			>
				<img
					src={
						props.parent_theme === 'dark'
							? '/assets/logo_dark.png'
							: '/assets/logo.png'
					}
					alt=""
					style={{ maxWidth: '128px' }}
				/>
			</div>

			<Grid
				container
				justifyContent="center"
				direction="row"
				alignItems="center"
				style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '16px' }}
			>
				<LockOutlinedIcon
					fontSize="large"
					color="primary"
					style={{ marginRight: '8px' }}
				/>

				<Typography component="h1" variant="h5">
					Reset Password
				</Typography>
			</Grid>
			<form className={classes.form} noValidate onSubmit={submitReset}>
				{props.user && props.never ? (
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						placeholder="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						value={
							user
								? user.email
									? user.email
									: user.phone_number
									? user.phone_number
									: ''
								: ''
						}
						disabled
					/>
				) : null}
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					placeholder="New Password"
					type="password"
					id="password"
					autoComplete="off"
					value={password}
					onChange={(e) => {
						setPassword(e.target.value)
					}}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="confirm_password"
					placeholder="Confirm Password"
					type="password"
					id="confirm_password"
					autoComplete="off"
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
				>
					Change Password
				</Button>
				{!props.user ? (
					<Grid container>
						<Grid item xs>
							<Links component={Link} color="primary" to={'/'}>
								Already have an account?
							</Links>
						</Grid>
						<Grid item>
							<Links component={Link} color="primary" to="#" variant="body2">
								Don't have an account? Sign Up
							</Links>
						</Grid>
					</Grid>
				) : null}
			</form>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	SignOut: () => dispatch(SignOut()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Reset)
