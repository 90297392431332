import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import axios from 'axios'
import Director from '../../Director'
import Groups from './Groups'

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function SignUp(props) {
	const classes = useStyles()

	const [numbers, setNumbers] = React.useState('')
	const [groups, setGroups] = React.useState([])

	var changeGroups = (val) => {
		setGroups([...val])

//setGroups(JSON.parse(JSON.stringify(val)))

	}

	var changeChecked = (_id, name) => {
		var found = false
		var foundIndex = 0

		if (groups) {
			groups.forEach((v, i) => {
				if (v._id.toString() === _id) {
					found = true
					foundIndex = i
				}
			})
		}



		if (found) {
			groups.splice(foundIndex, 1)
			return groups
		} else if (groups) {
			var new_groups = Object.assign([], groups)

			new_groups.push({
				_id: _id,
				name: name,
			})
			
			return new_groups
		} else {
			new_groups = []
			
			new_groups.push({
				_id: _id,
				name: name,
			})

			return new_groups
		}
	}

	var isChecked = _id => {
		var found = false
	
		if (groups) {
			groups.forEach((v, i) => {
				if (v._id.toString() === _id) {
					found = true
				}
			})
		}
	
		if (found) {
			return true
		} else {
			return false
		}
	}
	
	var submitSignUp = e => {
		e.preventDefault()

		axios
			.post(props.baseurl + 'remote/numbers/import', {
				numbers: numbers,
				groups: groups,
				token: localStorage.getItem('token')
			})
			.then(result => {
				if (result.data) {				

                    props.setAlert({
                        open: true,
                        severity: 'success',
                        message: 'Numbers Successfully Imported',
                    })

					Director('/numbers')
				} else {
                    
                    props.setAlert({
                        open: true,
                        severity: 'error',
                        message: 'Error',
                    })

				}
            })
            .catch(err => {
                props.setAlert({
                    open: true,
                    severity: 'error',
                    message: 'Error',
                })
            })
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Typography component="h1" variant="h5">
					Import Numbers
				</Typography>



				<form className={classes.form} noValidate onSubmit={submitSignUp}>
                <TextField                                
                                margin="normal"
                                fullWidth
                                label="Numbers"
                                onChange={(e) => {
                                    setNumbers(e.target.value);
                                }}
                                value={numbers}
                                color="primary"
                                multiline={true}
                                rows={10}
                                variant="standard"
                            />

					{<Groups updateField={changeGroups} isChecked={isChecked} changeChecked={changeChecked} />}

					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Import Numbers
					</Button>


					<div style={{width: '100%', textAlign: 'left',  marginTop: '8px'}}>
<strong>Correct formats:</strong>
<br />
5559991234<br />
5557774321<br />
5553334567<br />
5554443210
<br />
<br />
or
<br />
<br />
5559991234,email@{process.env.REACT_APP_DOMAIN},joe smith,active<br />
5557774321<br />
5553334567,number@{process.env.REACT_APP_DOMAIN},jane smith,stop<br />
5554443210
</div>

<div style={{width: '100%', textAlign: 'left',  marginTop: '8px'}}>
<strong>Incorrect formats:</strong>
<br />
(555) 999-1234<br />
555-777-4321<br />
+15553334567<br />
1-555-444-3210
<br />
<br />
or
<br />
<br />
(555) 999-1234<br />
555-777-4321<br />
+15553334567<br />
1-555-444-3210
</div>

				</form>
			</div>
		</Container>
	)
}

const mapStateToProps = state => ({
	user: state.auth,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
