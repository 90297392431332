import React, { useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import { setGroups } from '../../../actions/groups'
import { setAccount as reduceAccount } from '../../../actions/accounts'
import axios from 'axios'
import Director from '../../Director'
import EditIcon from '@material-ui/icons/EditOutlined'
import AddIcon from '@material-ui/icons/AddOutlined'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Edit(props) {
	const classes = useStyles()

	const [account, setAccount] = React.useState(props.account)
	const loaded = useRef(false)

	useEffect(() => {
		if (props.match.params.accountID) {
			load()
		} else {
			loaded.current = true
			props.setAccount({})
		}

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		// eslint-disable-next-line
	}, [])

	React.useEffect(() => {
		setAccount(props.account)
	}, [props.account])

	var load = () => {
		axios
			.post(props.baseurl + 'remote/accounts/load', {
				token: props.user.token,
				_id: props.match.params.accountID,
			})
			.then((result) => {
				props.setAccount(result.data)
				loaded.current = true
			})
	}

	var updateAccount = (field, value) => {
		var updates = {}
		updates[field] = value

		var new_data = Object.assign({}, account, updates)
		setAccount(new_data)

		props.setAccount(new_data)
	}

	var submitSave = (e) => {
		e.preventDefault()

		var save_url = account._id
			? 'remote/accounts/save'
			: 'remote/user/createaccount'

		axios
			.post(props.baseurl + save_url, {
				token: props.user.token,
				_id: account._id,
				nickname: account.nickname,
				email: account.email,
				phone_number: account.phone_number,
				password: account.password,
				domain: account.domain,
				package: account.package ? account.package : 0,
				base_url: account.base_url
			})
			.then((result) => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})

				Director('/accounts')
			})
			.catch((err) => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: 'Error',
				})
			})
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					{account && account._id ? (
						<EditIcon
							fontSize="large"
							color="primary"
							style={{ marginRight: '8px' }}
						/>
					) : (
						<AddIcon
							fontSize="large"
							color="primary"
							style={{ marginRight: '8px' }}
						/>
					)}

					<Typography component="h1" variant="h5">
						{props.title ? props.title : 'Edit Account'}
					</Typography>
				</Grid>
				{account ? (
					<form className={classes.form} noValidate onSubmit={submitSave}>
						<TextField
							variant="standard"
							margin="normal"
							fullWidth
							id="nickname"
							label="Name"
							name="nickname"
							autoComplete="nickname"
							value={account.nickname ? account.nickname : ''}
							onChange={(e) => {
								updateAccount('nickname', e.target.value)
							}}
						/>

						<TextField
							variant="standard"
							margin="normal"
							fullWidth
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							value={account.email ? account.email : ''}
							onChange={(e) => {
								updateAccount('email', e.target.value)
							}}
						/>

						<TextField
							variant="standard"
							margin="normal"
							fullWidth
							id="phone_number"
							label="Phone Number"
							name="phone_number"
							autoComplete="phone_number"
							value={account.phone_number ? account.phone_number : ''}
							onChange={(e) => {
								updateAccount('phone_number', e.target.value)
							}}
						/>

						{props.user.master || props.user ? (
							<TextField
								variant="standard"
								margin="normal"
								fullWidth
								id="domain"
								label="Domain"
								name="domain"
								autoComplete="domain"
								value={account.domain ? account.domain : ''}
								onChange={(e) => {
									updateAccount('domain', e.target.value)
								}}
							/>
						) : null}

						{props.user.master ? (
							<TextField
								variant="standard"
								margin="normal"
								fullWidth
								id="base_url"
								label="URL"
								name="base_url"
								autoComplete="base_url"
								value={account.base_url ? account.base_url : ''}
								onChange={(e) => {
									updateAccount('base_url', e.target.value)
								}}
							/>
						) : null}

						
{props.user.master ? (
							<TextField
								variant="standard"
								margin="normal"
								fullWidth
								id="package"
								label="Package"
								name="package"
								autoComplete="package"
								value={account.package ? account.package : 0}
								onChange={(e) => {
									updateAccount('package', e.target.value)
								}}
							/>
						) : null}


						<TextField
							variant="standard"
							margin="normal"
							type="password"
							fullWidth
							id="password"
							label="Password"
							name="password"
							autoComplete="password"
							value={account.password ? account.password : ''}
							onChange={(e) => {
								updateAccount('password', e.target.value)
							}}
						/>
						{account._id ? (
							<Typography variant="caption">
								Leave blank to keep the same password
							</Typography>
						) : null}

						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							Save Account
						</Button>
					</form>
				) : null}
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
	account: state.account,
	groups: state.groups,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAccount: (account) => {
		dispatch(reduceAccount(account))
	},
	setGroups: (groups) => dispatch(setGroups(groups)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
