import React from 'react'
import PropTypes from 'prop-types'

import MailIcon from '@material-ui/icons/MailTwoTone'
import InboxIcon from '@material-ui/icons/AllInboxTwoTone'

import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import HelpIcon from '@material-ui/icons/HelpTwoTone'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import HomeIcon from '@material-ui/icons/HomeTwoTone'
import ContactsIcon from '@material-ui/icons/ContactsTwoTone'
import LockIcon from '@material-ui/icons/LockTwoTone'
import CloseIcon from '@material-ui/icons/CloseTwoTone'
import AccountBoxIcon from '@material-ui/icons/AccountBoxTwoTone'
import ExitToAppIcon from '@material-ui/icons/ExitToAppTwoTone'
import GroupIcon from '@material-ui/icons/GroupTwoTone'
import KeywordIcon from '@material-ui/icons/VpnKeyTwoTone'
import ArrowBackIcon from '@material-ui/icons/ArrowBackTwoTone'
import TextIcon from '@material-ui/icons/TextsmsTwoTone'
import Director from '../components/Director'
import { SignOut } from '../actions'
//import Tools from '../components/tools/Tools'
import Theme from '../components/views/Theme'
import AssessmentIcon from '@material-ui/icons/AssessmentTwoTone'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { setUser, setTemp, setTheme, setLoaded, setSocket } from '../actions'
//import axios from 'axios'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { SocialIcon } from 'react-social-icons'

const drawerWidth = '192px'

const useStyles = withStyles(
	(theme) => ({
		root: {
			display: 'flex',
		},
		drawer: {
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				flexShrink: 0,
			},
		},
		bar: {
			minHeight: '56px !important',
			paddingLeft: '16px !important',
			paddingRight: '16px !important',
		},
		appBar: {
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${drawerWidth}px)`,
				marginLeft: drawerWidth,
			},
			flexGrow: 1,
			backgroundColor: '#191b1c',
			color: '#000',
			minHeight: '56px !important',
		},
		menuButton: {
			marginRight: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				display: 'none',
			},
		},
		// necessary for content to be below app bar
		toolbar: theme.mixins.toolbar,
		drawerPaper: {
			width: drawerWidth,
		},
		content: {
			flexGrow: 1,
		},
		logo_box: {
			padding: '0px',
			height: '128px',
			textAlign: 'center',
			paddingTop: '18px',
			paddingBottom: '8px',
		},
		logo: {
			height: '100%',
			position: 'relative',
		},
		toolbar_box: {
			cursor: 'pointer',
			[theme.breakpoints.down('xs')]: {
				display: 'none',
			},
		},
		toolbar_nav: {
			[theme.breakpoints.up('sm')]: {
				display: 'none',
			},
			minHeight: '56px !important',
		},
		toolbar_divider: {
			[theme.breakpoints.down('xs')]: {
				display: 'none',
			},
		},
	}),
	{
		withTheme: true,
	}
)

function ResponsiveDrawer(props) {
	const matches = props.theme.breakpoints.up('md')

	var isMd = useMediaQuery(matches)

	const xmatches = props.theme.breakpoints.down('xs')

	var isxSmall = useMediaQuery(xmatches)

	const smatches = props.theme.breakpoints.down('sm')

	var isSmall = useMediaQuery(smatches)

	/*
	var credit = props.user && props.user.credits ? props.user.credits : 0

	if (props.user && props.user.connected && !props.user.parent_account) {
		credit = props.user.account_data
			? props.user.account_data.balance
			: props.user.balance
			? props.user.balance
			: 0
	}

	if (props.user && !props.user.connect) {
		credit = props.user && props.user.credits ? props.user.credits : 0
	}

*/

	if (props.user) {
		var package_details = {}
		switch (props.user.package) {
			case 0:
				package_details.title = 'Free'
				break
			case 1:
				package_details.title = 'Basic'
				break
			case 2:
				package_details.title = 'Plus'
				break
			case 3:
				package_details.title = 'Pro'
				break
			default:
				package_details.title = 'Free'
				break
		}
	}

	return (
		<div
			className={props.classes.list}
			role="presentation"
			onClick={props.toggleDrawer(false)}
			onKeyDown={props.toggleDrawer(false)}
		>
			{(props.user || !props.user) && props.header && !isxSmall ? (
				<React.Fragment>
					<div
						className={props.classes.toolbar + ' ' + props.classes.toolbar_box}
						onClick={() => {
							props.setLoaded(false)
							Director('/dashboard')
						}}
					>
						<div className={props.classes.logo_box}>
							<img
								src={
									props.parent_theme === 'dark'
										? '/assets/logo_dark_blank.png'
										: '/assets/logo_blank.png'
								}
								className={props.classes.logo}
								alt=""
							/>
						</div>
					</div>
					<Divider
						className={props.classes.toolbar_divider + ' side-divider'}
					/>
				</React.Fragment>
			) : null}
			<List
				subheader={
					props.user ? (
						<ListSubheader
							style={{
								cursor: 'pointer',
								position: 'static',
								padding: '0px',
								margin: '0px',
								lineHeight: 'inherit',
								marginTop: '16px',
								marginBottom: '8px',
							}}
							component="div"
							id="nested-list-subheader"
							onClick={() => Director('/payments')}
						>
							<div style={{ textAlign: 'center' }}>
								<Typography
									color="textSecondary"
									variant="body2"
									component="span"
								>
									Current Plan:{' '}
									<Typography color="primary" variant="body2" component="span">
										{package_details ? package_details.title : ''}
									</Typography>
								</Typography>
							</div>

							{/*
							{process.env.REACT_APP_QUEUE !== 'email' && credit ? (
								<div style={{ textAlign: 'center' }}>
									<Typography
										color="textSecondary"
										variant="body2"
										component="span"
									>
										Available Balance:{' '}
										<Typography
											color="primary"
											variant="body2"
											component="span"
										>
											{Tools.formatter(credit)}
										</Typography>
									</Typography>
								</div>
							) : process.env.REACT_APP_QUEUE !== 'email' ? (
								<div style={{ textAlign: 'center' }}>
									<Typography
										color="textSecondary"
										variant="body2"
										component="span"
									>
										Available Balance:{' '}
										<Typography
											color="primary"
											variant="body2"
											component="span"
										>
											$0.00
										</Typography>
									</Typography>
								</div>
							) : null}
							*/}
						</ListSubheader>
					) : null
				}
			>
				{props.user ? <Divider className="side-divider" /> : null}

				<ListItem
					button
					key={'home'}
					onClick={() => {
						props.setLoaded(false)
						Director('/')
					}}
				>
					<ListItemIcon>
						<HomeIcon color="primary" className={'menu'} />
					</ListItemIcon>
					<ListItemText primary={'Home'} />
				</ListItem>

				{process.env.REACT_APP_QUEUE !== 'push!' &&
				props.user &&
				props.user.client_code !== 'green' ? (
					<ListItem
						button
						key={'inbox'}
						onClick={() => {
							Director('/messages/inbound')
						}}
					>
						<ListItemIcon>
							<InboxIcon color="primary" className={'menu'} />
						</ListItemIcon>
						<ListItemText primary={'Inbox'} />
					</ListItem>
				) : null}

				{process.env.REACT_APP_QUEUE !== 'push!' &&
				props.user &&
				props.user.client_code !== 'green' ? (
					<ListItem
						button
						key={'messages'}
						onClick={() => {
							Director('/messages/reply')
						}}
					>
						<ListItemIcon>
							<MailIcon color="primary" className={'menu'} />
						</ListItemIcon>
						<ListItemText primary={'Sent'} />
					</ListItem>
				) : null}

				{props.user && props.never ? (
					<ListItem
						button
						key={'reports'}
						onClick={() => {
							Director('/reports')
						}}
					>
						<ListItemIcon>
							<AssessmentIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary={'Reports'} />
					</ListItem>
				) : null}

				{process.env.REACT_APP_QUEUE !== 'push' && props.user ? (
					<ListItem
						button
						key={'contacts'}
						onClick={() => {
							Director('/contacts')
						}}
					>
						<ListItemIcon>
							<ContactsIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary={'Contacts'} />
					</ListItem>
				) : null}

				{process.env.REACT_APP_QUEUE !== 'push' && props.user ? (
					<ListItem
						button
						key={'groups'}
						onClick={() => {
							Director('/groups')
						}}
					>
						<ListItemIcon>
							<GroupIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary={'Groups'} />
					</ListItem>
				) : null}

				{process.env.REACT_APP_QUEUE !== 'push' && props.user ? (
					<ListItem
						button
						key={'keywords'}
						onClick={() => {
							Director('/keywords')
						}}
					>
						<ListItemIcon>
							<KeywordIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary={'Keywords'} />
					</ListItem>
				) : null}

				{props.user && props.never ? (
					<ListItem
						button
						key={'profile'}
						onClick={() => {
							Director('/account')
						}}
					>
						<ListItemIcon>
							<AccountBoxIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary={'Account'} />
					</ListItem>
				) : null}
				{props.user && props.never ? (
					<ListItem
						button
						key={'change'}
						onClick={() => {
							Director('/change')
						}}
					>
						<ListItemIcon>
							<LockIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary={'Password'} />
					</ListItem>
				) : null}

				{props.user && localStorage.getItem('parent_token') ? (
					<ListItem
						button
						key={'back'}
						onClick={() => {
							localStorage.setItem(
								'token',
								localStorage.getItem('parent_token')
							)
							localStorage.removeItem('parent_token')

							props.setSocket(null)

							var temp = Object.assign({}, props.temp, {
								reload: true,
								reload_url: '/accounts',
								destroy_socket: true,
							})

							props.setTemp(temp)
							//Director('/accounts')

							/*
							axios
								.post(props.baseurl + 'remote/user/data', {
									token: localStorage.getItem('token'),
								})
								.then((result) => {
									props.setUser(result.data)
									if (result.data.mode) {
										props.setTheme(result.data.mode)
									}

									Director('/accounts')
								})
								.catch((err) => {
									localStorage.removeItem('token')
									props.SignOut()
								})
								*/
						}}
					>
						<ListItemIcon>
							<ArrowBackIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary={'Parent Account'} />
					</ListItem>
				) : null}

				<ListItem
					button
					key={'help'}
					onClick={() => {
						Director('/help')
					}}
				>
					<ListItemIcon>
						<HelpIcon color="primary" className={'menu'} />
					</ListItemIcon>
					<ListItemText primary={'Help & Support'} />
				</ListItem>

				<Divider className="side-divider" />

				{process.env.REACT_APP_QUEUE === 'email' ? (
					<React.Fragment>
						<ListItem
							button
							key={'sample'}
							onClick={() => {
								Director('/sample')
							}}
						>
							<ListItemIcon>
								<TextIcon color="primary" className={'menu'} />
							</ListItemIcon>
							<ListItemText primary={'Test It Out'} />
						</ListItem>

						<Divider className="side-divider" />
					</React.Fragment>
				) : null}

				{props.user ? <Theme /> : null}

				{props.user ? (
					<ListItem
						button
						key={'signout'}
						onClick={() => {
							props.setTheme('dark')

							props.Out((u) => {
								if (!u) {
									Director('/')

									/*
										setTimeout(()=>{
										Director('/')
									},0)
										*/
								}
							}, null)
						}}
					>
						<ListItemIcon>
							<ExitToAppIcon color="primary" className={'menu'} />
						</ListItemIcon>
						<ListItemText primary={'Sign Out'} />
					</ListItem>
				) : null}

				{!props.user ? (
					<ListItem
						button
						key={'signout'}
						onClick={() => {
							Director('/signin')
						}}
					>
						<ListItemIcon>
							<ExitToAppIcon color="primary" className={'menu'} />
						</ListItemIcon>
						<ListItemText primary={'Sign In'} />
					</ListItem>
				) : null}

				{isSmall ? (
					<React.Fragment>
						<Divider className="side-divider" />

						<ListItem button key={'close'}>
							<ListItemIcon>
								<CloseIcon color="primary" className={'menu'} />
							</ListItemIcon>
							<ListItemText primary="Close" />
						</ListItem>
					</React.Fragment>
				) : null}

				<Divider className="side-divider" />

				{/*

<Box>
					<Grid
						container
						style={{ padding: '8px', marginTop: '8px', paddingLeft: '8px' }}
					>
						<Grid item xs={3} sm={3} style={{ paddingLeft: '8px' }}>
							<Typography
								component="span"
								variant="subtitle2"
								color="textSecondary"
							>
								email
							</Typography>
						</Grid>
						<Grid item xs={9} sm={9}>
							<Typography
								component="span"
								variant="subtitle2"
								color="textPrimary"
								className="selectable-text"
							>
								{process.env.REACT_APP_EMAIL}
							</Typography>
						</Grid>
					</Grid>
					<Grid container style={{ padding: '8px', paddingLeft: '8px' }}>
						<Grid item xs={3} sm={3} style={{ paddingLeft: '8px' }}>
							<Typography
								component="span"
								variant="subtitle2"
								color="textSecondary"
							>
								phone
							</Typography>
						</Grid>
						<Grid item xs={9} sm={9}>
							<Typography
								component="span"
								variant="subtitle2"
								color="textPrimary"
								className="selectable-text"
							>								
								{process.env.REACT_APP_PHONE}
							</Typography>
						</Grid>
					</Grid>
				</Box>

				*/}

				{props.user || !props.user ? (
					<React.Fragment>
						<div
							style={{ width: '192px', textAlign: 'center', marginTop: '16px' }}
						>
							<a href="https://play.google.com/store/apps/details?id=com.greenlightalerts&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
								<img
									alt="Get it on Google Play"
									src="/assets/play_badge.png"
									style={{ width: '168px' }}
								/>
							</a>
						</div>

						<div
							style={{ width: '192px', textAlign: 'center', marginTop: '8px' }}
						>
							<a href="https://apps.apple.com/us/app/greenlight-alerts/id1567499968?itsct=apps_box_badge&itscg=30200">
								<img
									src="/assets/ios_badge.png"
									alt="Download on the App Store"
									style={{ borderRadius: '0px', width: '168px' }}
								/>
							</a>
						</div>
					</React.Fragment>
				) : null}

				<div style={{ textAlign: 'center', marginTop: '16px' }}>
					<Grid container>
						<Grid item xs={3}>
							<SocialIcon
								url="https://twitter.com/greenlight0420"
								network="twitter"
								fgColor="#fff"
								style={{
									height: 32,
									width: 32,
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<SocialIcon
								url="https://instagram.com/greenlight0420"
								network="instagram"
								fgColor="#fff"
								style={{
									height: 32,
									width: 32,
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<SocialIcon
								url="https://snapchat.com/add/greenlight0420"
								network="snapchat"
								fgColor="#fff"
								style={{
									height: 32,
									width: 32,
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<SocialIcon
								url="https://facebook.com/greenlight0420"
								network="facebook"
								fgColor="#fff"
								style={{
									height: 32,
									width: 32,
								}}
							/>
						</Grid>
					</Grid>
				</div>

				{props.user || (isMd && !props.user) ? (
					<Typography
						variant="body2"
						className="copyright"
						style={{ textAlign: 'center', paddingTop: '16px' }}
					>
						© 2023 {process.env.REACT_APP_NAME}
					</Typography>
				) : null}
			</List>
		</div>
	)
}

ResponsiveDrawer.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
}

const mapStateToProps = (state) => ({
	user: state.auth,
	loaded: state.loaded,
	temp: state.temp,
	baseurl: state.baseurl,
	socket: state.socket,
	parent_theme: state.theme,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setSocket: (socket) => dispatch(setSocket(socket)),
	SignOut: () => dispatch(SignOut()),
	setTheme: (theme) => dispatch(setTheme(theme)),

	setLoaded: (loaded) => dispatch(setLoaded(loaded)),

	setTemp: (temp) => dispatch(setTemp(temp)),

	Out: (callback, user) => {
		dispatch((dispatch) => {
			let u = dispatch(SignOut(user)).user

			callback(u)
		})
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(useStyles(ResponsiveDrawer))
