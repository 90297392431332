import React from 'react'
import { Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

function Overlay(props) {

return <div className={'data-table-loading'} {...props}>
	<Grid
		container
		spacing={0}
		alignContent="center"
		alignItems="center"
		className={'data-table-loading-content'}
	>
		<Grid item xs={12}>
			<CircularProgress />
		</Grid>
	</Grid>
</div>

}

export default Overlay