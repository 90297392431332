import React from 'react'
import MaterialTable from 'material-table'
import { forwardRef } from 'react'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import EditAttributes from '@material-ui/icons/EditAttributes'

import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

import Retry from '@material-ui/icons/Replay'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'

import FileDownload from 'js-file-download'
//import Triggers from '../reports/Triggers'
import Director from '../../Director'
import { setAccount } from '../../../actions/accounts'
import { setAccounts } from '../../../actions/accounts'
import { setTemp, setAlert } from '../../../actions'

//import Moment from 'moment-timezone'

import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import ReloadIcon from '@material-ui/icons/CachedTwoTone'
import ExportIcon from '@material-ui/icons/GetAppTwoTone'

import Transfer from '../Transfer'
import LockIcon from '@material-ui/icons/LockTwoTone'
import MoneyIcon from '@material-ui/icons/AttachMoneyTwoTone'

import ImportIcon from '@material-ui/icons/SupervisorAccountTwoTone'

import Filter from './Filter'
import Hook from '../../Hook'

const styles = (theme) => {
	return {
		extendedIcon: {
			marginRight: theme.spacing(1),
		},
		action_button: {
			marginRight: '16px',
			'&:last-child': {
				marginRight: '16px',
			},
		},
	}
}

const tableRef = React.createRef()

class Report extends React.Component {
	state = {
		cols: [],
		data: [],
		filter: {},
		selected: [],
		page: 0,
		rowsPerPage: 20,
		total: 0,
		query: {},
		transfer: null,
		hooks: {},
	}

	setFilter = (filter, value) => {
		var new_state = this.state
		new_state.filter[filter] = value || null
		this.setState(new_state)
		//this.getReport(false, this.state.query)
		tableRef.current && tableRef.current.onQueryChange()
	}

	clearTransfer = () => {
		this.setState({ transfer: null })
	}

	hookBack = (hooks) => {
		this.setState({ hooks: hooks })
	}

	getReport = async (download, query_filter) => {
		var query = query_filter ? query_filter : { page: this.state.page }

		this.setState({ query: query })

		var filter = this.state.filter
		filter.pagination = {}
		filter.pagination.limit = (query.page + 1) * this.state.rowsPerPage
		filter.pagination.skip = query.page * this.state.rowsPerPage
		filter.order_by = query.orderBy ? query.orderBy.accessor : null
		filter.order_direction = query.orderDirection
		filter.search_term = query.search

		return axios
			.post(this.props.baseurl + 'remote/accounts/list', {
				token: localStorage.getItem('token'),
				download: download ? download : false,
				filter: filter,
				query: query,
			})
			.then((res) => {
				if (download) {
					FileDownload(res.data, 'report.csv')

					return false
				}

				setTimeout(() => {
					this.props.setAlert({
						open: false,
					})
				}, 1000)

				var headers = res.data.columns.map((header, i) => {
					header.headerClassName = this.props.classes.header
					header.className = this.props.classes.column
					var headerTitle = header.Header

					header.title = (
						<div
							style={{
								textAlign: 'left',
							}}
						>
							{headerTitle}
						</div>
					)

					header.editable = 'never'

					header.field = header.accessor

					if (header.Header === 'Status') {
						header.editable = 'always'
						header.lookup = {
							active: 'Active',
							closed: 'Closed',
							suspended: 'Suspended',
							unauthorized: 'Unauthorized',
							disconnected: 'Disconnected',
							deleted: 'Deleted',
						}
					}

					if (header.Header === 'Reseller') {
						header.editable = 'always'

						header.type = 'boolean'
					}

					if (header.Header === 'Package') {
						header.editable = 'always'

						header.type = 'numeric'
					}

					if (header.Header === 'Domain') {
						header.editable = 'always'

						header.render = (rowData) => {
							return rowData.domain ? rowData.domain : ''
						}

						header.title = 'Domain'
					}

					if (header.Header === 'URL') {
						header.editable = 'always'

						header.title = 'URL'

						header.render = (rowData) => {
							return rowData.base_url ? rowData.base_url : ''
						}
					}

					if (header.Header === 'accountSid') {
						header.render = (rowData) => {
							return rowData.accountSid
								? rowData.accountSid.substr(0, 7) + '...'
								: ''
						}
					}

					return header
				})

				if (!this.state.cols.length) {
					this.setState({ cols: headers })
				}

				this.setState({ data: res.data.rows, total: res.data.total })

				if (query.filters.length) {
					var filtered_data = this.state.data

					query.filters.forEach((v, i) => {
						var temp_data = filtered_data.filter((row) => {
							var column = query.filters[i].column
							var field = column.accessor

							var val = query.filters[i].value

							if (val === 'checked' || val === 'unchecked') {
								if (val === 'checked') {
									var filter_value = true
								} else {
									filter_value = false
								}

								var use_field = row[field] === filter_value || !filter_value

								return use_field ? true : false
							} else {
								return row[field]
									? row[field].includes(query.filters[i].value)
									: false
							}
						})

						console.log(temp_data)

						filtered_data = temp_data
					})
				}

				return new Promise((resolve, reject) => {
					resolve({
						data: query.filters.length ? filtered_data : this.state.data,
						page: query.page,
						totalCount: this.state.total,
					})
				})
			})
	}

	importAccounts = () => {
		axios
			.post(this.props.baseurl + 'remote/accounts/import', {
				token: localStorage.getItem('token'),
			})
			.then((result) => {
				if (result.data.status === true) {
					this.props.setAlert({
						open: true,
						severity: 'success',
						message: 'Import Successful',
					})
				} else {
					this.props.setAlert({
						open: true,
						severity: 'error',
						message: result.data.error,
					})
				}

				tableRef.current && tableRef.current.onQueryChange()
			})
			.catch((err) => {
				this.props.setAlert({
					open: true,
					severity: 'error',
					message: 'Something went wrong',
				})
			})
	}

	handleChangePage = (page, per) => {
		this.setState({
			selected: [],
			page: page,
			rowsPerPage: per,
		})

		return new Promise(function (resolve, reject) {
			resolve(true)
		})
	}

	componentDidMount() {}

	render() {
		var height = this.state.hooks.height
			? this.state.hooks.height
			: window.innerHeight

		var options = {}
		options.headerStyle = { position: 'sticky', top: 0 }

		if (this.state.hooks.isSmall || this.state.hooks.isxSmall) {
		} else {
			options.maxBodyHeight = height - 210
		}
		options.emptyRowsWhenPaging = false
		options.thirdSortClick = false
		options.filtering = false
		options.pageSizeOptions = [5, 10, 20, 25, 50, 100, 1000, 5000]
		options.pageSize = this.state.rowsPerPage
		options.debounceInterval = 300

		const tableIcons = {
			Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
			Delete: forwardRef((props, ref) => (
				<DeleteOutline {...props} ref={ref} />
			)),
			DetailPanel: forwardRef((props, ref) => (
				<ChevronRight {...props} ref={ref} />
			)),
			Edit: forwardRef((props, ref) => <EditAttributes {...props} ref={ref} />),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
			NextPage: forwardRef((props, ref) => (
				<ChevronRight {...props} ref={ref} />
			)),
			PreviousPage: forwardRef((props, ref) => (
				<ChevronLeft {...props} ref={ref} />
			)),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
			SortArrow: forwardRef((props, ref) => (
				<ArrowDownward {...props} ref={ref} />
			)),
			ThirdStateCheck: forwardRef((props, ref) => (
				<Remove {...props} ref={ref} />
			)),
			ViewColumn: forwardRef((props, ref) => (
				<ViewColumn {...props} ref={ref} />
			)),
			Retry: forwardRef((props, ref) => <Retry {...props} ref={ref} />),
		}

		var editable = this.props.user.master
			? {
					isEditable: (rowData) => !rowData.master,
					onRowUpdate: async (newData, oldData) => {
						var save_url = 'remote/accounts/save'

						newData.token = localStorage.getItem('token')

						let result = await axios.post(
							this.props.baseurl + save_url,
							newData
						)

						this.props.setAlert({
							open: true,
							severity: result.data.severity,
							message: result.data.message,
						})

						return new Promise((resolve, reject) => {
							resolve(true)
						})
					},
			  }
			: null

		return (
			<React.Fragment>
				<CssBaseline />

				<Grid container>
					<Grid item xs={12} sm={12} md={12}>
						<Box p={0} pt={1}>
							<Grid
								container
								direction="row"
								spacing={1}
								className="table-actions"
								alignItems="center"
							>
								<Grid item xs={12} sm={12} md={10} container>
									<Fab
										className={this.props.classes.action_button}
										color="primary"
										aria-label="add"
										size="small"
										onClick={() => {
											tableRef.current && tableRef.current.onQueryChange()
										}}
									>
										<ReloadIcon />
									</Fab>

									<Fab
										className={this.props.classes.action_button}
										color="primary"
										aria-label="add"
										size="small"
										component={Link}
										to="/accounts/add"
									>
										<AddIcon />
									</Fab>

									<Fab
										className={this.props.classes.action_button}
										color="primary"
										aria-label="add"
										size="small"
										onClick={() => {
											this.getReport(true, this.state.query)
										}}
									>
										<ExportIcon />
									</Fab>

									{this.props.user.accountSid && this.props.user.connected ? (
										<Fab
											color="primary"
											aria-label="add"
											size="medium"
											variant="extended"
											onClick={this.importAccounts}
										>
											<ImportIcon className={this.props.classes.extendedIcon} />
											Import
										</Fab>
									) : null}

									{/*
	<Triggers
		reload={() => {
			tableRef.current && tableRef.current.onQueryChange()
		}}
		export={() => {
			this.getReport(true, this.state.query)
		}}
		data={this.state.data}
	/>
	*/}
								</Grid>

								<Grid item xs={12} sm={12} md={2}>
									<Filter
										setFilter={this.setFilter}
										status={this.state.filter.status}
									/>
								</Grid>
							</Grid>
						</Box>
						<Box p={0} pt={1}>
							<div style={{ maxWidth: '100%' }}>
								{this.state.data ? (
									<MaterialTable
										editable={editable}
										actions={[
											(rowData) => ({
												icon: Edit,
												tooltip: 'Edit',
												disabled: rowData.master,
												onClick: (event, rowData) => {
													// Do save operation
													Director('/accounts/edit/' + rowData._id)
												},
											}),
											(rowData) => ({
												icon: MoneyIcon,
												tooltip: 'Transfer',
												disabled: rowData.master,
												onClick: (event, rowData) => {
													this.setState({ transfer: rowData })
												},
											}),
											(rowData) => ({
												icon: LockIcon,
												tooltip: 'Sign In',
												disabled: rowData.master,
												onClick: (event, rowData) => {
													localStorage.setItem(
														'parent_token',
														this.props.user.token
													)
													localStorage.setItem('token', rowData.token)
													//props.setUser(v)

													var temp = Object.assign({}, this.props.temp, {
														reload: true,
														reload_url: '/dashboard',
													})

													this.props.setTemp(temp)
												},
											}),
										]}
										icons={tableIcons}
										options={options}
										columns={this.state.cols}
										data={(query) => {
											return this.getReport(false, query).then((report) => {
												return report
											})
										}}
										title="Accounts"
										onChangePage={this.handleChangePage}
										onChangeRowsPerPage={this.handleChangeRowsPerPage}
										localization={{
											pagination: {
												labelRowsSelect: this.state.hooks.isxSmall
													? ''
													: 'Rows Per Page',
											},
											header: {
												actions: this.state.data.length ? 'Actions' : null,
											},
											body: {
												editTooltip: 'Quick Edit',
												editRow: { domain: '' },
											},
										}}
										tableRef={tableRef}
										components={{
											Container: (props) => (
												<div className={'material-table-styles'} {...props} />
											),
										}}
									/>
								) : null}
							</div>
						</Box>
					</Grid>
				</Grid>
				{this.state.transfer ? (
					<Transfer
						open={true}
						clear={this.clearTransfer}
						transfer={this.state.transfer}
					/>
				) : null}
				<Hook callback={this.hookBack} />
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
	temp: state.temp,
})

const mapDispatchToProps = (dispatch) => ({
	setAccount: (account) => dispatch(setAccount(account)),
	setAccounts: (accounts) => dispatch(setAccounts(accounts)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Report))
