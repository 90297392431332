import React, { Component } from 'react'
import Checkout from './payments/Checkout'
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const useStyles = withStyles(theme => ({
		paper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginTop: theme.spacing(2),
		},
		buttons: {
			width: '50%',
		},
		menu: {
			//backgroundColor: '#000000'
		},
		menu_container: {
			//height: 'calc(100vh - 102px)'
			marginTop: theme.spacing(1),
		},
		avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
}))




if (window.location.href.includes(process.env.REACT_APP_LIVE_DOMAIN_CHECK)) {
	var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_LIVE);
} else {
	stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_TEST);
}



class Payments extends Component {

    state = {
        stripe: null
      };

componentDidUpdate() {

	if (window.Stripe && !this.state.stripe) {

		if (window.location.href.includes(process.env.REACT_APP_LIVE_DOMAIN_CHECK)) {

			this.setState({
				stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_LIVE),
			})

		} else {

			this.setState({
				stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_TEST),
			})

		}



	}

}

    	componentDidMount() {
		if (window.Stripe) {

			if (window.location.href.includes(process.env.REACT_APP_LIVE_DOMAIN_CHECK)) {

				this.setState({
					stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_LIVE),
				})
	
			} else {
	
				this.setState({
					stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_TEST),
				})
	
			}
	

		} else {
			/*
			document.querySelector('#stripe-js').addEventListener('load', () => {
				// Create Stripe instance once Stripe.js loads
				this.setState({ stripe: window.Stripe(keyPublishable) })
			})
			*/
		}
	}

	render() {

		return (
			<Container component="main" disableGutters maxWidth="sm">
				<CssBaseline />
				<div className={`${this.props.classes.paper} top-paper`}>
				<Grid
  container
  justifyContent="center"
  direction="row"
  alignItems="center"
  style={{ marginLeft: "auto", marginRight: "auto" }}
>
  <LockOutlinedIcon fontSize="large" color="primary" style={{ marginRight: "8px" }} />

  <Typography component="h1" variant="h5">
    Add Payment Method
  </Typography>
</Grid>

				<Elements stripe={stripePromise}>
					<Checkout callBack={this.props.callBack} redirect={this.props.redirect} style={{width: '100%'}} />
				</Elements>

			</div>
            </Container>
		)
	}
}

export default useStyles(Payments)
