import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { setAlert, setUser } from '../../actions'
import axios from 'axios'
import Pages from '../nav/Pages'
import Order from './Order'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(
	(theme) => ({
		paper: {
			marginTop: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',			
		},
		credit_details: {
			marginTop: theme.spacing(1),
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.secondary.main,
		},
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		submit: {
			margin: theme.spacing(3, 0, 2),
		},
		order_history: {
			marginBottom: theme.spacing(2),
		},
	}),
	{ withTheme: true }
)

function History(props) {
	const classes = useStyles()

	const [total, setTotal] = React.useState(0)
	const [data, setData] = React.useState([])

	const [loaded, setLoaded] = React.useState(false)

	const [pagination] = React.useState({
		page: 1,
		skip: 0,
		limit: 5,
		max: 5,
	})

	var load = React.useCallback(
		(pagination_settings,scroll) => {
			setLoaded(false)						

			if (!pagination_settings) {
				pagination_settings = pagination
			}

			axios
				.post(props.baseurl + 'remote/user/orders', {
					token: props.user.token,
					pagination: pagination_settings,
				})
				.then((result) => {
					setTotal(result.data.total)					
					setData(result.data.data)
					setLoaded(true)
					
					if (scroll) {
						window.scrollTo(0,document.body.scrollHeight);						
					}

				})
		},
		[pagination, props.user.token, props.baseurl]
	)

	var orders = data.map((v, i) => {
		return (
			<div style={{ marginBottom: '8px', width: '100%' }} key={i}>
				<Order order={v} />
			</div>
		)
	})

	React.useEffect(() => {
		load()
	}, [load])

	return (
		<Grid container spacing={0} className={classes.paper}>
			<Grid item className={classes.order_history}>
				<Typography component="h1" variant="h5" align="center">
					Order History
				</Typography>
			</Grid>

			<Grid item container>
				{orders.length ? (
					<React.Fragment>
						<Grid item alignContent="flex-end" container style={{marginBottom: '8px'}}>
						{loaded ? orders : 
						<React.Fragment>
							<div style={{width:'100%'}}>
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						</div>
					</React.Fragment>}


						</Grid>
						<Grid item alignContent="flex-end" container style={{marginTop: '8px'}}>
							<Pages callback={load} pagination={pagination} total={total} />
						</Grid>
					</React.Fragment>
				) : loaded ? (
					'No Orders'
				) : (
<React.Fragment>
							<div style={{width:'100%'}}>
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						<Skeleton animation="wave" />
						</div>
					</React.Fragment>
				)}
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state) => ({
	
	user: state.auth,
	baseurl: state.baseurl
})

const mapDispatchToProps = (dispatch) => ({
	
	setAlert: (alert) => dispatch(setAlert(alert)),
	setUser: (user) => dispatch(setUser(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(History)
