import { Component } from 'react'
import { setUser, SignOut } from '../../actions'
import Director from '../Director'
import { connect } from 'react-redux'

class Auth extends Component {
	constructor(props, context) {
		super()
		this.state = {}
	}

	componentDidMount() {
		this.SignOut()
	}

	SignOut = () => {
		this.props.Out((u) => {
			if (!u) {
				Director('/')

				/*
					setTimeout(()=>{
					Director('/')
				},0)
					*/
			}
		}, null)
	}

	render() {
		return null
	}
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	SignOut: () => dispatch(SignOut()),
	Out: (callback, user) => {
		dispatch((dispatch) => {
			let u = dispatch(SignOut(user)).user

			callback(u)
		})
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
