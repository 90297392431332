import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
	},
	submit: {
		marginTop: theme.spacing(3),
	},
}))

function EditProfile(props) {
	const classes = useStyles()
	const [token] = React.useState(localStorage.getItem('token') || null)
	const [email, setEmail] = React.useState('')

	const [phone, setPhone] = React.useState('')
	const [code, setCode] = React.useState('')
	const [emailCode, setEmailCode] = React.useState('')

	const [notifyNumber, setNotifyNumber] = React.useState(
		props.user.notify_number
	)

	const [hideVerifyPhone, setHideVerifyPhone] = React.useState('')
	const [hideVerifyEmail, setHideVerifyEmail] = React.useState('')

	var user_email = props.user ? (props.user.email ? props.user.email : '') : ''
	var user_phone = props.user
		? props.user.phone_number
			? props.user.phone_number
			: ''
		: ''

	React.useEffect(() => {
		setEmail(user_email)
	}, [user_email])

	React.useEffect(() => {
		setPhone(user_phone)
	}, [user_phone])

	var submitChange = (e) => {
		e.preventDefault()
		axios
			.post(props.baseurl + 'remote/user/profile', {
				token: token,
				email: email,
				notify_number: notifyNumber,
				phone_number: phone,
			})
			.then((result) => {
				if (result.data.severity === 'success') {
					var user = Object.assign({}, props.user, {
						email: email,

						phone_number: phone,
						test_code: result.data.test_code,
						shop_code: result.data.shopcode,
					})

					props.setUser(user)
					setHideVerifyPhone(false)
					setHideVerifyEmail(false)
				}

				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})
			})
	}

	var reVerify = (field) => {
		axios
			.post(props.baseurl + 'remote/user/reverify', {
				token: token,
				field: field,
			})
			.then((result) => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})
			})
	}

	var verifyPhone = (field) => {
		axios
			.post(props.baseurl + 'remote/user/verifycode', {
				token: token,
				code: code,
				removeToken: true,
				field: field,
			})
			.then((result) => {
				if (!result.data.message) {
					var user = Object.assign({}, props.user, {
						verified_phone: true,
						verified_phone_token_link: result.data.verified_phone_token_link,
						verified_phone_token: result.data.verified_phone_token,
					})

					props.setUser(user)

					props.setAlert({
						open: true,
						severity: 'success',
						message: 'Phone Number Verified',
					})
				} else {
					props.setAlert({
						open: true,
						severity: result.data.severity,
						message: result.data.message,
					})
				}
			})
	}

	var verifyEmail = (field) => {
		axios
			.post(props.baseurl + 'remote/user/verifyemailcode', {
				token: token,
				code: emailCode,
				removeToken: true,
				field: field,
			})
			.then((result) => {
				if (!result.data.message) {
					var user = Object.assign({}, props.user, {
						verified_email: true,
						verified_phone_token_link: result.data.verified_email_token_link,
						verified_phone_token: result.data.verified_email_token,
					})

					props.setUser(user)

					props.setAlert({
						open: true,
						severity: 'success',
						message: 'Email Verified',
					})
				} else {
					props.setAlert({
						open: true,
						severity: result.data.severity,
						message: result.data.message,
					})
				}
			})
	}

	return (
		<Container component="main" maxWidth="xs" disableGutters>
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					<AccountBoxIcon
						fontSize="large"
						color="primary"
						style={{ marginRight: '8px' }}
					/>

					<Typography component="h1" variant="h5">
						Edit Credentials
					</Typography>
				</Grid>

				<form
					className={classes.form + ' paper-content'}
					noValidate
					onSubmit={submitChange}
				>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						placeholder="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						value={email}
						onChange={(e) => {
							if (e.target.value !== user_email) {
								setHideVerifyEmail(true)
							} else {
								setHideVerifyEmail(false)
							}

							setEmail(e.target.value)
						}}
					/>

					{props.user &&
					props.user.email &&
					!props.user.verified_email &&
					!hideVerifyEmail ? (
						<React.Fragment>
							<Grid
								container
								alignItems="center"
								spacing={1}
								style={{ marginBottom: '8px' }}
							>
								<Grid item xs={6}>
									<Typography variant="caption">Not Verified</Typography>
								</Grid>

								<Grid item xs={6}>
									<Button
										fullWidth
										variant="outlined"
										color="primary"
										onClick={() => {
											reVerify('email')
										}}
									>
										Send Verification
									</Button>
								</Grid>
							</Grid>

							<Grid container alignItems="center" spacing={1}>
								<Grid item xs={6}>
									<TextField
										fullWidth
										size="small"
										variant="outlined"
										placeholder="Verification Code"
										value={emailCode}
										onChange={(e) => {
											setEmailCode(e.target.value)
										}}
									/>
								</Grid>

								<Grid item xs={6}>
									<Button
										fullWidth
										variant="outlined"
										color="primary"
										onClick={() => {
											verifyEmail('email')
										}}
									>
										Verify
									</Button>
								</Grid>
							</Grid>
						</React.Fragment>
					) : null}

					<TextField
						variant="outlined"
						margin="normal"
						fullWidth
						id="phone"
						placeholder="Phone Number"
						name="phone"
						autoComplete="phone"
						autoFocus
						value={phone}
						onChange={(e) => {
							if (e.target.value !== user_phone) {
								setHideVerifyPhone(true)
							} else {
								setHideVerifyPhone(false)
							}

							setPhone(e.target.value)
						}}
					/>

					{props.user &&
					props.user.phone_number &&
					!props.user.verified_phone &&
					!hideVerifyPhone ? (
						<React.Fragment>
							<Grid container alignItems="center" spacing={1}>
								<Grid item xs={6}>
									<Typography variant="caption" component="p">
										Not Verified
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Button
										fullWidth
										variant="outlined"
										color="primary"
										onClick={() => {
											reVerify('phone_number')
										}}
									>
										Send Verification
									</Button>
								</Grid>
							</Grid>
							<Grid container alignItems="center" spacing={1}>
								<Grid item xs={6}>
									<TextField
										fullWidth
										size="small"
										variant="outlined"
										placeholder="Verification Code"
										value={code}
										onChange={(e) => {
											setCode(e.target.value)
										}}
									/>
								</Grid>

								<Grid item xs={6}>
									<Button
										fullWidth
										variant="outlined"
										color="primary"
										onClick={() => {
											verifyPhone('phone')
										}}
									>
										Verify
									</Button>
								</Grid>
							</Grid>
						</React.Fragment>
					) : null}

					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="notify"
						placeholder="Notify phone number"
						name="notify"
						autoComplete="notify"
						autoFocus
						value={notifyNumber}
						onChange={(e) => {
							setNotifyNumber(e.target.value)
						}}
					/>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Save Credentials
					</Button>
				</form>
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)
