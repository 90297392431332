const contacts = (state = null, action) => {
	switch (action.type) {
		case 'SET_CONTACTS':			
		return Object.assign({}, state, {
			contacts: action.contacts,
		})
		default:
			return state
	}
}

export default contacts
