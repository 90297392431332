import React from 'react'
import { setUser, setAlert } from '../../../actions'
import { setContact } from '../../../actions/contacts'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'


function Status(props) {


	return (
<React.Fragment>
		<Typography variant="subtitle2">Pricing</Typography>

		
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<TextField
								variant="standard"
								margin="dense"
								fullWidth
								label="Per SMS"
								name="credit"
								autoComplete="credit"
								autoFocus
								value={props.userAccount.per_sms}
								disabled
							/>
						</Grid>

						<Grid item xs={6}>
							<TextField
								variant="standard"
								margin="dense"
								fullWidth
								label="Total SMS"
								name="credit"
								autoComplete="credit"
								autoFocus
								value={(props.credits / props.userAccount.per_sms).toFixed(0)}
								disabled
							/>
						</Grid>
					</Grid>

					<Grid container spacing={1}>
						<Grid item xs={6}>
							<TextField
								variant="standard"
								margin="dense"
								fullWidth
								label="Per MMS"
								name="credit"
								autoComplete="credit"
								autoFocus
								value={props.userAccount.per_mms}
								disabled
							/>
						</Grid>

						<Grid item xs={6}>
							<TextField
								variant="standard"
								margin="dense"
								fullWidth
								label="Total MMS"
								name="credit"
								autoComplete="credit"
								autoFocus
								value={(props.credits / props.userAccount.per_mms).toFixed(0)}
								disabled
							/>
						</Grid>
					</Grid>

					<Grid container spacing={1}>
						<Grid item xs={6}>
							<TextField
								variant="standard"
								margin="dense"
								fullWidth
								label="Per Combo"
								name="credit"
								autoComplete="credit"
								autoFocus
								value={props.userAccount.per_combo}
								disabled
							/>
						</Grid>

						<Grid item xs={6}>
							<TextField
								variant="standard"
								margin="dense"
								fullWidth
								label="Total Combo"
								name="credit"
								autoComplete="credit"
								autoFocus
								value={(props.credits / props.userAccount.per_combo).toFixed(0)}
								disabled
							/>
						</Grid>
					</Grid>
                    </React.Fragment>
	)
}

const mapStateToProps = state => ({
	user: state.auth,
	contact: state.contact,
	groups: state.groups,
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setContact: contact => dispatch(setContact(contact)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Status)
