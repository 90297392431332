import React, { useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import { setMessage } from '../../../actions/messages'
import axios from 'axios'
import Groups from './Groups'
import Status from './Status'
import Picker from '../../../ui/form/Picker'
import Box from '@material-ui/core/Box'
import { Grid } from '@material-ui/core'
import Director from '../../Director'
import FormControl from '@material-ui/core/FormControl'
//import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import EditIcon from '@material-ui/icons/EditOutlined'
import AddIcon from '@material-ui/icons/AddOutlined'
import LinkIcon from '@material-ui/icons/LinkOutlined'
import Moment from 'moment-timezone'

const useStyles = makeStyles(
	(theme) => ({
		paper: {
			marginTop: theme.spacing(0),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.primary.main,
		},
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		submit: {
			marginTop: theme.spacing(2),
		},
	}),
	{ withTheme: true }
)

function Edit(props) {
	const classes = useStyles()

	const [message, setMessage] = React.useState(props.message)
	const loaded = useRef(false)
	const [saved, setSaved] = React.useState(false)

	const [shorten, setShorten] = React.useState(false)

	const [short_link, setShort] = React.useState('')
	const [long_link, setLong] = React.useState('')

	useEffect(() => {
		if (props.match.params.messageID) {
			load()
		} else {
			props.setMessage({ status: 'draft' })
			loaded.current = true
		}
		// eslint-disable-next-line
	}, [props.match.params.messageID])

	React.useEffect(() => {
		setMessage(props.message)
	}, [props.message])

	var load = () => {
		axios
			.post(props.baseurl + 'remote/messages/load', {
				token: props.user.token,
				_id: props.match.params.messageID,
			})
			.then((result) => {
				if (result.data._id) {
					props.setMessage(result.data)
					loaded.current = true
					setSaved(true)
				} else {
					props.setMessage({})
					loaded.current = true
					setSaved(false)
				}
			})
	}

	var shortenLink = (link) => {
		if (!link || short_link) {
			if (short_link) {
				var textBox = document.getElementById('short')
				textBox.select()
				document.execCommand('copy')

				props.setAlert({
					open: true,
					severity: 'success',
					message: 'short link copied',
					vertical: 'top',
					horizontal: 'right'
				})
			}

			return false
		}

		axios
			.post(props.baseurl + 'remote/user/shorten', {
				token: props.user.token,
				url: long_link,
			})
			.then((result) => {
				setShort(result.data.link)

				var textBox = document.getElementById('short')
				textBox.select()
				document.execCommand('copy')

				props.setAlert({
					open: true,
					severity: 'success',
					message: 'short link copied',
					vertical: 'top',
					horizontal: 'right'
				})
			})
	}

	var updateGroups = (value) => {
		updateMessage('groups', value)
	}

	var updateMessage = (field, value) => {
		var updates = {}
		updates[field] = value

		setSaved(false)

		setMessage(Object.assign({}, message, updates))
	}

	var changeChecked = (_id, name) => {
		var found = false
		var foundIndex = 0

		if (message.groups) {
			message.groups.forEach((v, i) => {
				if (v._id.toString() === _id) {
					found = true
					foundIndex = i
				}
			})
		}

		if (found) {
			message.groups.splice(foundIndex, 1)
			return message.groups
		} else if (message.groups) {
			var new_groups = Object.assign([], message.groups)

			new_groups.push({
				_id: _id,
				name: name,
			})

			return new_groups
		} else {
			new_groups = []

			new_groups.push({
				_id: _id,
				name: name,
			})

			return new_groups
		}
	}

	var isChecked = (_id) => {
		var found = false

		if (message.groups) {
			message.groups.forEach((v, i) => {
				if (v._id.toString() === _id) {
					found = true
				}
			})
		}

		if (found) {
			return true
		} else {
			return false
		}
	}

	var submitSave = (e) => {
		e.preventDefault()

		if (message) {
			if (message.body !== null && message.body !== undefined) {
				var use_body = message.body
			}

			if ((message.body === null || message.body === undefined) && props.user.signature) {
				use_body = '\r\n\r\n' + props.user.signature
			}
		}

		var count_body = use_body.toString()

		var isChrome = window.chrome
		if (isChrome) {
			count_body = count_body.replace(/(\r\n|\n|\r)/g, '  ')
		}

		var counts = Math.ceil(count_body.length / props.user.message_limit)

		var body_count = count_body.length

		if (counts === 0) {
			counts = 1
		}

		if (
			(message.status ===
				'scheduled' &&
				(!message.scheduled_time ||
					message.scheduled_time <= Moment().valueOf()))
		) {
			props.setAlert({
				open: true,
				severity: 'error',
				message:
					'Scheduled messages must be scheduled for a valid date and time',
			})
			return false
		}

		axios
			.post(props.baseurl + 'remote/messages/save', {
				token: props.user.token,
				_id: message._id,
				body: message.body,
				status: message.status,
				date_added: message.date_added,
				scheduled_time: message.scheduled_time,
				groups: message.groups ? message.groups : [],
				body_count: body_count,
				counts: counts,
				image: message.image,
			})
			.then((result) => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})

				setSaved(true)

				props.setMessage(message)

				if (!props.match.params.messageID && result.data._id) {
					loaded.current = false
					Director('/messages/edit/' + result.data._id, true)
				}
			})
			.catch((err) => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: 'Error',
				})
			})
	}

	var use_body = ''

	if (message) {
		if (message.body !== null && message.body !== undefined) {
			use_body = message.body
		}

		if ((message.body === null || message.body === undefined) && props.user.signature) {
			use_body = '\r\n\r\n' + props.user.signature
		}
	}

	var count_body = use_body.toString()

	var isChrome = window.chrome
	if (isChrome) {
		count_body = count_body.replace(/(\r\n|\n|\r)/g, '  ')
	}

	if (message && message.body) {
		var counts = Math.ceil(count_body.length / props.user.message_limit)

		var body_count = count_body.length

		if (counts === 0) {
			counts = 1
		}
	} else {
		counts = 1
		body_count = count_body.length
	}

	if (
		message &&
		(message.status === 'sent' ||
			message.status === 'reply' ||
			message.status === 'auto' ||
			message.status === 'inbound')
	) {
		var disabled = true
	}

	return (
		<Container component="main" disableGutters maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					{message && message._id ? (
						<EditIcon
							fontSize="large"
							color="primary"
							style={{ marginRight: '8px' }}
						/>
					) : (
						<AddIcon
							fontSize="large"
							color="primary"
							style={{ marginRight: '8px' }}
						/>
					)}

					<Typography component="h1" variant="h5">
						{message
							? props.title
								? props.title
								: message && message._id
								? 'Edit Message'
								: 'New Message'
							: null}
					</Typography>
				</Grid>

				{message ? (
					<form className={classes.form} noValidate onSubmit={submitSave}>
						<FormControl component="fieldset" style={{ display: 'flex' }}>
							{message.reply ? (
								<strong>This message is a reply to {message.to}</strong>
							) : null}

							<TextField
								style={{ borderRadius: '0px' }}
								variant="outlined"
								margin="normal"
								fullWidth
								id="body"
								label="Body"
								name="body"
								autoFocus
								disabled={saved && message && disabled ? true : false}
								//defaultValue={message.body ? message.body : default_body}
								value={use_body}
								onChange={(e) => {
									updateMessage('body', e.target.value)
								}}
								multiline={true}
								rows={8}
							/>

							<Grid container spacing={1}>
								<Grid item xs={6}>
									{body_count} character{body_count !== 1 ? 's' : null}
								</Grid>
								<Grid item xs={6} style={{ textAlign: 'right' }}>
									Counts as {counts} message{counts !== 1 ? 's' : null}
								</Grid>
							</Grid>

							<Grid item container spacing={0}>
							<Button
								fullWidth
								variant="outlined"
								aria-label="Clear"
								onClick={() => {
									if (shorten) {
										setLong('')
										setShort('')
									}

									setShorten(!shorten)
								}}
								startIcon={<LinkIcon />}
								style={{ marginTop: '8px', marginBottom: '8px' }}
							>
								{shorten ? 'Hide Quick Link Shortener' : 'Show Quick Link Shortener'}
							</Button>
								</Grid>

							{shorten ? (
								<Grid container spacing={1} alignItems="center">
									<Grid item xs={12} sm={12} md={9}>
										<TextField
											fullWidth
											variant="outlined"
											size="small"
											value={long_link}
											placeholder="original URL"
											onChange={(e) => {
												setLong(e.target.value)
												setShort('')
											}}											
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<Button
											fullWidth
											variant="outlined"
											aria-label="Clear"
											onClick={() => {
												shortenLink(long_link)
											}}
										>
											shorten
										</Button>
									</Grid>
									<Grid item xs={12} sm={12} md={12}>
										{short_link ? (
											<TextField
												fullWidth
												variant="outlined"
												size="small"
												value={short_link}
												placeholder="shortened URL"
												id="short"
												onFocus={() => {}}
											/>
										) : null}
									</Grid>
								</Grid>
							) : null}

							<Box pt={1}>
								<Picker
									fullWidth
									date={message.scheduled_time ? message.scheduled_time : null}
									disabled={saved && message && disabled ? true : false}
									callback={(value) => {
										updateMessage('scheduled_time', value)
									}}
								/>
							</Box>

							<Box pt={1}>
								{
									<Groups
										disabled={saved && message && disabled ? true : false}
										updateField={updateGroups}
										isChecked={isChecked}
										changeChecked={changeChecked}
									/>
								}
							</Box>

							<Box pt={1}>
								<Status
									disabled={saved && message && disabled ? true : false}
									updateMessage={updateMessage}
									message={message}
								/>
							</Box>

							<Box pt={1}>
								<div style={{ marginTop: '8px', marginBottom: '8px' }}>
									<Typography component="p" variant="body2">
										<strong>Include an image?</strong> (Picture messages count
										as 2 messages)
									</Typography>
									<Typography component="p" variant="body2">
										Images must be in .JPG format and no larger than 600 KB
									</Typography>
								</div>

								{!message.image ? (
									<TextField
										fullWidth
										inputProps={{
											multiple: false,
											style: {
												padding: '16px',
												paddingBottom: '21px',
												paddingLeft: 'auto',
												paddingRight: 'auto',
											},
										}}
										margin="none"
										disabled={saved && message && disabled ? true : false}
										type="file"
										onChange={(e) => {
											var target = e.target

											if (target && target.files) {
												const files = Array.from(target.files)

												const formData = new FormData()

												files.forEach((file, i) => {
													formData.append('file', file)
												})

												formData.append('token', localStorage.getItem('token'))
												formData.append('upload_type', 'current')

												axios({
													method: 'POST',
													url: '/remote/api/upload',
													data: formData,
													headers: {
														Accept: 'application/json',
														'Content-Type': 'multipart/form-data',
													},
												})
													.then((res) => {
														if (res.data.files && res.data.files.length) {
															updateMessage('image', res.data.files[0])
														}
													})
													.catch(function (thrown) {
														console.log(thrown)
													})
											}

											//this changes the value of the data that was passed down to state from props so that the text field updates, without updating state text field value will always come from state.data that was sent from (but now isolated from) props, so changing the text will do nothing unless this changes the state that provides the value to the field. This by itself does not trigger a save, the blur handler must happen to trigger a save or a save must be triggered. This is not needed at all if the async solution is in use, as it will update and save. Use this instead of the this.props.handleChange line  if you want to update the state locally and reflect changes, but trigger the save manually.
											//this.handleChange('data', obj);
										}}
										value={''}
										variant="filled"
										color="primary"
										multiline={false}
									/>
								) : saved && message && disabled ? null : message.image ? (
									<Button
										fullWidth
										variant="outlined"
										aria-label="Clear"
										onClick={() => {
											updateMessage('image', null)
										}}
										startIcon={<ReplayIcon />}
									>
										Remove Image
									</Button>
								) : null}

								<div style={{ marginBotom: '8px', marginTop: '8px' }}>
									{message.image ? (
										<img
											key={message.image}
											alt="main"
											src={props.baseurl + 'remote/api/image/' + message.image}
											style={{
												maxWidth: '100%',
											}}
										/>
									) : null}
								</div>
							</Box>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								disabled={saved ? true : false}
							>
								Save Message
							</Button>
						</FormControl>
					</form>
				) : null}
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	message: state.message,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setMessage: (message) => dispatch(setMessage(message)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
