import React from 'react'
import MaterialTable from 'material-table'
import { forwardRef } from 'react'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Reply from '@material-ui/icons/Reply'
import ReportIcon from '@material-ui/icons/AssessmentTwoTone'

import History from '@material-ui/icons/History'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { Grid, Button, Switch, FormControlLabel } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import FileDownload from 'js-file-download'
//import Triggers from '../reports/Triggers'
import Director from '../../Director'
import { setMessage } from '../../../actions/messages'
import { setGroups } from '../../../actions/groups'
import Filter from './Filter'
import Moment from 'moment-timezone'

import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import ReloadIcon from '@material-ui/icons/CachedTwoTone'
import ExportIcon from '@material-ui/icons/GetAppTwoTone'
import Hook from '../../Hook'

const styles = (theme) => {
	return {
		extendedIcon: {
			marginRight: theme.spacing(1),
		},
		action_button: {
			marginRight: '16px',
			'&:last-child': {
				marginRight: '16px',
			},
		},
		switch: {
			marginLeft: '4px',
		},
	}
}

const tableRef = React.createRef()

class Report extends React.Component {
	state = {
		cols: [],
		data: [],
		filter: {
			status: '',
		},
		page: 0,
		rowsPerPage: 20,
		total: 0,
		query: {},
		selected: [],
		hooks: {},
		no_reply: true,
	}

	setFilter = (filter, value) => {
		var new_state = this.state
		new_state.filter[filter] = value || null
		new_state.page = -1

		this.setState(new_state, () => {
			tableRef.current && tableRef.current.onQueryChange()
		})
		//this.getReport(false, this.state.query)
	}

	hookBack = (hooks) => {
		this.setState({ hooks: hooks })
	}

	getReport = async (download, query_filter) => {
		var query = query_filter ? query_filter : { page: this.state.page }

		var page = this.state.page === -1 ? 0 : query.page

		query.page = page

		this.setState({ query: query, page: page })

		var filter = this.state.filter
		filter.pagination = {}
		filter.pagination.limit = (page + 1) * this.state.rowsPerPage
		filter.pagination.skip = page * this.state.rowsPerPage
		filter.order_by = query.orderBy ? query.orderBy.accessor : null
		filter.order_direction = query.orderDirection
		filter.search_term = query.search
		filter.status = this.props.status
			? this.props.status
			: this.state.filter.status
			? this.state.filter.status
			: ''

		filter.no_reply = this.props.match.params.phone_number
			? false
			: this.state.no_reply

		return axios
			.post(this.props.baseurl + 'remote/messages/list', {
				token: localStorage.getItem('token'),
				download: download ? download : false,
				filter: filter,
				query: query,
				convo: this.props.match.params.phone_number
					? this.props.match.params.phone_number
					: null,
			})
			.then((res) => {
				if (download) {
					FileDownload(res.data, 'report.csv')

					return false
				}

				var headers = res.data.columns.map((header, i) => {
					header.headerClassName = this.props.classes.header
					header.className = this.props.classes.column
					var headerTitle = header.Header

					header.title = (
						<div
							style={{
								textAlign: 'left',
							}}
						>
							{headerTitle}
						</div>
					)

					header.field = header.accessor

					if (header.Header === 'Type') {
						header.lookup = {
							sent: 'Sent',
							draft: 'Draft',
							scheduled: 'Scheduled For',
							queued: 'Queued',
							paused: 'Paused',
							deleted: 'Deleted',
							reply: 'Reply',
							inbound: 'Inbound',
							auto: 'Auto',
							stop: 'Stop',
						}
					}

					if (header.Header === 'Body') {
						header.title = (
							<div
								style={{
									textAlign: 'left',
								}}
							>
								{headerTitle}
							</div>
						)

						header.render = (rowData) => {
							return rowData.to ? '' + rowData.body : rowData.body
						}

						header.Cell = (props) => {
							return (
								<div
									style={{
										textAlign: 'left',
									}}
								>
									<Grid container>
										<Grid item xs={6}>
											<Button
												variant="contained"
												color="secondary"
												className={this.props.classes.button}
												onClick={() => {
													Director(
														'/messages/report/' + props.row._original._id
													)
												}}
											>
												{'Report'}
											</Button>
										</Grid>
										<Grid item xs={6}>
											<Button
												variant="contained"
												color="secondary"
												fullWidth
												className={this.props.classes.button}
												onClick={() => {
													Director('/messages/edit/' + props.row._original._id)
												}}
											>
												{props.value || 'View / Edit'}
											</Button>
										</Grid>
									</Grid>
								</div>
							)
						}
					}

					if (header.Header === 'Scheduled For') {
						header.render = (rowData) => {
							return rowData.scheduled_time
								? Moment(rowData.scheduled_time)
										.tz(
											this.props.user.timezone
												? this.props.user.timezone
												: 'America/Los_Angeles'
										)
										.format('MM/DD/YYYY hh:mm:ss A')
								: ''
						}

						header.filtering = false
					}

					if (header.Header === 'Date Added') {
						header.render = (rowData) => {
							return rowData.date_added
								? Moment(rowData.date_added)
										.tz(
											this.props.user && this.props.user.timezone
												? this.props.user.timezone
												: 'America/Los_Angeles'
										)
										.format('MM/DD/YYYY hh:mm:ss A')
								: ''
						}

						header.filtering = false
					}

					return header
				})

				if (!this.state.cols.length) {
					this.setState({ cols: headers })
				}

				this.setState({ data: res.data.rows, total: res.data.total })

				//				console.log(query)

				if (query.filters.length) {
					var filtered_data = this.state.data

					query.filters.forEach((v, i) => {
						var temp_data = filtered_data.filter((row) => {
							var column = query.filters[i].column
							var field = column.accessor

							return row[field]
								? row[field].includes(query.filters[i].value)
								: false
						})

						filtered_data = temp_data
					})
				}

				return new Promise((resolve, reject) => {
					resolve({
						//data: this.state.data.filter(row => row.body.includes(query.search)),
						data: query.filters.length ? filtered_data : this.state.data,
						page: query.page,
						totalCount: this.state.total,
					})
				})
			})
	}

	handleChangePage = (page, per) => {
		this.setState(
			{
				selected: [],
				page: page,
				rowsPerPage: per,
			},
			() => {
				console.log('page ' + this.state.page)
				console.log(page)
			}
		)

		return new Promise(function (resolve, reject) {
			resolve(true)
		})
	}

	componentDidMount() {
		//this.getReport()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.status !== this.props.status) {
			this.setFilter('status', this.props.status)

			if (!this.props.match.params.phone_number) {
				if (this.props.status === 'reply') {
					this.setState({ no_reply: false })
				} else {
					this.setState({ no_reply: true })
				}
			}
		}
	}

	render() {
		if (
			this.props.match.params.phone_number ||
			this.props.status === 'inbound'
		) {
			var actions = [
				(rowData) => ({
					icon: () => {
						var icon =
							this.state.selected.length > 1 || rowData.status !== 'inbound' ? (
								<Reply color="secondary" />
							) : (
								<Reply color="primary" />
							)
						return icon
					},
					tooltip: 'Reply',
					disabled:
						this.state.selected.length > 1 || rowData.status !== 'inbound'
							? true
							: false,
					onClick: (event, rowData) => {
						// Do save operation

						if (this.state.selected.length) {
							Director('/messages/reply/' + rowData[0]._id)
						} else {
							Director('/messages/reply/' + rowData._id)
						}
					},
				}),
			]

			if (!this.props.match.params.phone_number) {
				actions.push((rowData) => ({
					icon: History,
					tooltip: 'History',
					disabled:
						this.state.selected.length > 1 || rowData.status !== 'inbound'
							? true
							: false,
					onClick: (event, rowData) => {
						// Do save operation

						this.setState({ no_reply: false })

						if (this.state.selected.length) {
							var rowLink = rowData[0].user_uuid
								? rowData[0].user_uuid
								: rowData[0].full_from
							Director('/messages/convo/' + rowLink)
						} else {
							rowLink = rowData.user_uuid
								? rowData.user_uuid
								: rowData.full_from
							Director('/messages/convo/' + rowLink)
						}
					},
				}))
			}
		} else {
			actions = [
				{
					icon: Edit,
					tooltip: 'Edit',
					disabled: this.state.selected.length > 1 ? true : false,
					onClick: (event, rowData) => {
						// Do save operation

						if (this.state.selected.length) {
							Director('/messages/edit/' + rowData[0]._id)
						} else {
							Director('/messages/edit/' + rowData._id)
						}
					},
				},
				{
					icon: ReportIcon,
					tooltip: 'Report',
					disabled: this.state.selected.length > 1 ? true : false,
					onClick: (event, rowData) => {
						// Do save operation

						if (this.state.selected.length) {
							Director('/messages/report/' + rowData[0]._id)
						} else {
							Director('/messages/report/' + rowData._id)
						}
					},
				},
			]
		}

		var height = this.state.hooks.height
			? this.state.hooks.height
			: window.innerHeight

		var options = {}
		options.headerStyle = { position: 'sticky', top: 0 }

		if (this.state.hooks.isSmall || this.state.hooks.isxSmall) {
		} else {
			options.maxBodyHeight = height - 210
		}
		options.emptyRowsWhenPaging = false
		options.thirdSortClick = false
		options.filtering = false
		options.pageSizeOptions = [5, 10, 20, 25, 50, 100, 1000, 5000]
		options.pageSize = this.state.rowsPerPage
		options.initialPage = 0

		//options.selection = true

		const tableIcons = {
			Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
			Delete: forwardRef((props, ref) => (
				<DeleteOutline {...props} ref={ref} />
			)),
			DetailPanel: forwardRef((props, ref) => (
				<ChevronRight {...props} ref={ref} />
			)),
			Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
			NextPage: forwardRef((props, ref) => (
				<ChevronRight {...props} ref={ref} />
			)),
			PreviousPage: forwardRef((props, ref) => (
				<ChevronLeft {...props} ref={ref} />
			)),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
			SortArrow: forwardRef((props, ref) => (
				<ArrowDownward {...props} ref={ref} />
			)),
			ThirdStateCheck: forwardRef((props, ref) => (
				<Remove {...props} ref={ref} />
			)),
			ViewColumn: forwardRef((props, ref) => (
				<ViewColumn {...props} ref={ref} />
			)),
		}

		return (
			<React.Fragment>
				<CssBaseline />

				<Grid container>
					<Grid item xs={12} sm={12} md={12}>
						<div>
							<FormControlLabel
								style={{
									marginLeft: '0px',
									marginTop: '10px',
									alignContent: 'center',
									alignItems: 'center',
									justifyContent: 'center',
								}}
								control={
									<Switch
										color="primary"
										checked={
											this.props.match.params.phone_number
												? false
												: this.state.no_reply === true
												? true
												: false
										}
										onChange={(e) => {
											if (this.state.no_reply === true) {
												this.setState({ no_reply: false })
											} else {
												this.setState({ no_reply: true })
											}
										}}
										disabled={
											this.props.match.params.phone_number ||
											this.props.status === 'reply'
										}
										value="checked"
										inputProps={{ 'aria-label': 'Unreplied only' }}
										className={this.props.classes.switch}
									/>
								}
								labelPlacement="start"
								label={'Unreplied only'}
							/>
						</div>
					</Grid>

					<Grid item xs={12} sm={12} md={12}>
						<Box p={0} pt={1}>
							<Grid
								container
								direction="row"
								spacing={1}
								className="table-actions"
								alignItems="center"
							>
								<Grid item xs={12} sm={12} md={10} container>
									<Fab
										className={this.props.classes.action_button}
										color="primary"
										aria-label="add"
										size="small"
										onClick={() => {
											tableRef.current && tableRef.current.onQueryChange()
										}}
									>
										<ReloadIcon />
									</Fab>

									{this.props.match.params.phone_number ? (
										<Fab
											className={this.props.classes.action_button}
											color="primary"
											aria-label="add"
											size="small"
											component={Link}
											to={
												'/messages/to/' + this.props.match.params.phone_number
											}
										>
											<Reply />
										</Fab>
									) : (
										<Fab
											className={this.props.classes.action_button}
											color="primary"
											aria-label="add"
											size="small"
											component={Link}
											to="/publish"
										>
											<AddIcon />
										</Fab>
									)}

									<Fab
										className={this.props.classes.action_button}
										color="primary"
										aria-label="add"
										size="small"
										onClick={() => {
											this.getReport(true, this.state.query)
										}}
									>
										<ExportIcon />
									</Fab>

									{/*
									<Triggers
										reload={() => {
											tableRef.current && tableRef.current.onQueryChange()
										}}
										export={() => {
											this.getReport(true, this.state.query)
										}}
										data={this.state.data}
									/>
									*/}
								</Grid>

								<Grid item xs={12} sm={12} md={2}>
									<Filter
										convo={this.props.match.params.phone_number ? true : false}
										setFilter={this.setFilter}
										status={this.props.status ? this.props.status : ''}
									/>
								</Grid>
							</Grid>
						</Box>
						<Box p={0} pt={1}>
							<div style={{ maxWidth: '100%' }}>
								{this.state.data ? (
									<MaterialTable
										onSelectionChange={(rows) => {
											this.setState({ selected: rows })
										}}
										actions={actions}
										icons={tableIcons}
										options={options}
										columns={this.state.cols}
										data={(query) => {
											return this.getReport(false, query).then(
												(report) => report
											)
										}}
										title={
											this.props.match.params.phone_number
												? 'Conversation with ' +
												  this.props.match.params.phone_number
												: 'Messages'
										}
										onChangePage={this.handleChangePage}
										onChangeRowsPerPage={this.handleChangeRowsPerPage}
										localization={{
											pagination: {
												labelRowsSelect: this.state.hooks.isxSmall
													? ''
													: 'Rows Per Page',
											},
											header: {
												actions: this.state.data.length ? 'Actions' : null,
											},
										}}
										tableRef={tableRef}
										components={{
											Container: (props) => (
												<div className={'material-table-styles'} {...props} />
											),
										}}
									/>
								) : null}
							</div>
						</Box>
					</Grid>
				</Grid>
				<Hook callback={this.hookBack} />
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setMessage: (message) => dispatch(setMessage(message)),
	setGroups: (groups) => dispatch(setGroups(groups)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Report))
