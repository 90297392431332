import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'

import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Grid, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'
import SignInSmall from './SignInSmall'

import { Link as Linker } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Video from './Video'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { Sub } from './'

const useStyles = withStyles(
	(theme) => ({
		paper: {
			marginTop: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.primary.main,
		},
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		submit: {
			margin: theme.spacing(3, 0, 2),
		},
		menu_container: {
			//height: 'calc(100vh - 102px)'
			marginTop: theme.spacing(1),
		},
		contain: {
			//backgroundColor: theme.palette.primary.main,
			width: '100%',
			padding: '0px',
			paddingTop: '24px',

			background:
				'linear-gradient(0deg, rgba(76,187,23,1) 60%, rgba(0,0,0,1) 100%)',
		},
		ytdiv: {
			div: {
				width: '100%',
			},
		},
	}),
	{ withTheme: true }
)

function Home(props) {
	const matches = props.theme.breakpoints.down('sm')
	const xmatches = props.theme.breakpoints.down('xs')
	const mdmatches = props.theme.breakpoints.up('md')
	const lgmatches = props.theme.breakpoints.up('lg')
	var isSmall = useMediaQuery(matches)
	var isxSmall = useMediaQuery(xmatches)
	var isBig = useMediaQuery(mdmatches)
	var isBigger = useMediaQuery(lgmatches)

	return (
		<Container
			component="main"
			maxWidth={false}
			disableGutters
			style={{
				zoom: isBigger ? 1.25 : 1,
			}}
		>
			<CssBaseline />

			<div style={{ width: '100%', backgroundColor: '#000000' }}>
				<SignInSmall />
			</div>

			{!props.user && isBig ? (
				<div
					style={{
						textAlign: 'center',
						paddingTop: '16px',
						position: 'absolute',
						width: '100%',
					}}
				>
					<img
						src="/assets/logo_dark.png"
						style={{ width: '184px' }}
						alt="Greenlight"
					/>
				</div>
			) : null}

			{isBig && 1 === 2 ? (
				<div
					style={{
						width: '128px',
						position: 'absolute',
						left: '16px',
						top: '16px',
					}}
				>
					<img
						alt="Greenlight"
						src="/assets/logo_dark_blank.png"
						style={{
							width: '100%',
						}}
					/>
				</div>
			) : null}

			<div className={`${props.classes.contain}`}>
				<Grid
					container
					style={{
						marginTop: isBig ? '20px' : '0px',
						marginBottom: isBig ? '0px' : '0px',
						padding: '8px',
						minHeight: 'calc(100vh - 274px)',
					}}
				>
					{' '}
					<Container component="main" maxWidth="lg" disableGutters>
						<Grid container>
							<Grid
								item
								xs={12}
								md={12}
								style={{
									alignItems: 'center',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-evenly',
									textAlign: 'left',
									height: isBig ? '400px' : 'auto',
									position: 'relative',
								}}
								container
							>
								<div
									style={{
										display: 'flex',
										height: isBig ? '400px' : 'auto',
										textAlign: 'left',
										//flex: 1,
										alignItems: 'center',
										alignContent: 'center',
										justifyContent: 'center',
										//marginLeft: '20%',
									}}
								>
									<Box
										maxWidth={'400px'}
										style={{ marginLeft: '24px', marginRight: 'auto' }}
									>
										<Typography variant="h5">Communicate</Typography>
										<Typography
											variant="h5"
											style={{ marginTop: '16px', marginBottom: '16px' }}
										>
											Keep Customers
										</Typography>
										<Typography variant="h5" style={{ fontStyle: 'italic' }}>
											INCREASE SALES
										</Typography>

										<Box
											style={{
												marginLeft: 'auto',
												marginRight: 'auto',
												textAlign: 'left',
												marginTop: '16px',
											}}
										>
											<Typography
												style={{
													textAlign: 'left',
													fontSize: '1.3em',
													color: '#c4efb0',
												}}
											>
												The only mobile app that allows dispensaries to send
												notifications, provide up-to-date information, and
												inform customers about events, deals, and special
												offers.
											</Typography>
										</Box>
									</Box>
								</div>
								{isBig ? (
									<div
										style={{
											height: '100%',
											display: 'inline-block',
											position: 'relative',
											width: '211px',
											//right: '20%',
											//marginRight: '20%',
										}}
									>
										<img
											alt="Greenlight App"
											src="/assets/app.png"
											style={{
												height: '420px',
												top: '-10px',
												right: '0px',
												position: 'absolute',
											}}
										/>
									</div>
								) : null}
							</Grid>
							{!isBig ? (
								<Grid
									item
									xs={12}
									style={{
										alignItems: 'center',
										alignContent: 'center',
										justifyContent: 'center',
										textAlign: 'center',
										marginTop: '32px',
									}}
								>
									<img
										alt="Greenlight App"
										src="/assets/app.png"
										style={{
											height: '420px',
										}}
									/>
								</Grid>
							) : null}
						</Grid>
					</Container>
					{isBig || isSmall || isxSmall ? (
						<Container component="main" maxWidth="md" disableGutters>
							{!isBig ? (
								<Grid
									item
									xs={12}
									container
									spacing={2}
									style={{ padding: '8px' }}
								>
									<div
										className="ytdiv"
										style={{
											textAlign: 'center',
											marginTop: '16px',
											marginBottom: '0px',
											width: '100%',
										}}
									>
										{isBig || isSmall || isxSmall ? (
											<Video isSmall={isSmall} isxSmall={isxSmall} />
										) : null}
									</div>
								</Grid>
							) : null}

							{isBig ? (
								<Grid
									item
									xs={12}
									container
									spacing={2}
									style={{ padding: '8px', marginTop: '16px' }}
								>
									<Grid item xs={12} md={4}>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<img src="/assets/icon1.png" alt="Mobile Marketing" />
										</Grid>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<Typography variant="h5">Mobile Marketing</Typography>
										</Grid>
										<Grid
											item
											xs={12}
											style={{ textAlign: 'center', color: '#c4efb0' }}
										>
											We help dispensaries market directly to customers using
											push notifications through our mobile app and easy-to-use
											dashboard.
										</Grid>
									</Grid>
									<Grid
										item
										xs={12}
										md={4}
										style={{
											marginTop: isBig ? '0px' : '32px',
										}}
									>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<img src="/assets/icon2.png" alt="Real-time Chat" />
										</Grid>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<Typography variant="h5">Real-time Chat</Typography>
										</Grid>
										<Grid
											item
											xs={12}
											style={{ textAlign: 'center', color: '#c4efb0' }}
										>
											Dispensaries can communicate with customers in real-time
											to answer questions, provide information, and increase
											sales.
										</Grid>
									</Grid>
									<Grid
										item
										xs={12}
										md={4}
										style={{
											marginTop: isBig ? '0px' : '32px',
										}}
									>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<img src="/assets/icon3.png" alt="Reach Everyone" />
										</Grid>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<Typography variant="h5">Reach Everyone</Typography>
										</Grid>
										<Grid
											item
											xs={12}
											style={{ textAlign: 'center', color: '#c4efb0' }}
										>
											Greenlight is available on both iOS and Android and is
											available anywhere cannabis is legal for medical or
											recreational use.
										</Grid>
									</Grid>
								</Grid>
							) : null}
							<Container component="main" maxWidth="sm" disableGutters>
								<Grid
									item
									xs={12}
									container
									style={{
										textAlign: 'center',
										margin: '0px',
										marginTop: '16px',
									}}
									spacing={1}
									display="flex"
								>
									<Grid item sm={8} md={8} style={{ textAlign: 'left' }}>
										<Button
											component={Linker}
											variant="contained"
											color="primary"
											size="small"
											href="mailto:contact@greenlightalerts.com"
										>
											contact@greenlightalerts.com
										</Button>
									</Grid>
									<Grid
										item
										sm={4}
										md={4}
										style={{
											textAlign: 'right',
											marginRight: '0px',
											paddingRight: '0px',
										}}
									>
										<Button
											component={Linker}
											variant="contained"
											color="primary"
											size="small"
											href="tel:844-777-0420"
										>
											844-777-0420
										</Button>
									</Grid>
								</Grid>
							</Container>
						</Container>
					) : null}
				</Grid>

				<Grid
					container
					style={{
						marginTop: '0px',
						marginBottom: '0px',
						backgroundColor: '#000000',
						paddingTop: '20px',
						paddingBottom: '20px',
						borderTop: '1px solid rgba(255, 255, 255, 0.65)',
						borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
					}}
				>
					<Container component="main" maxWidth="lg" disableGutters>
						<Container component="main" maxWidth="md" disableGutters>
							{!isBig ? (
								<Grid
									item
									xs={12}
									container
									spacing={2}
									style={{ padding: '8px' }}
								>
									<Grid item xs={12} md={4}>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<img src="/assets/icon1.png" alt="Mobile Marketing" />
										</Grid>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<Typography variant="h5">Mobile Marketing</Typography>
										</Grid>
										<Grid
											item
											xs={12}
											style={{ textAlign: 'center', color: '#909090' }}
										>
											We help dispensaries market directly to customers using
											push notifications through our mobile app and easy-to-use
											dashboard.
										</Grid>
									</Grid>
									<Grid
										item
										xs={12}
										md={4}
										style={{
											marginTop: isBig ? '0px' : '32px',
										}}
									>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<img src="/assets/icon2.png" alt="Real-time Chat" />
										</Grid>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<Typography variant="h5">Real-time Chat</Typography>
										</Grid>
										<Grid
											item
											xs={12}
											style={{ textAlign: 'center', color: '#909090' }}
										>
											Dispensaries can communicate with customers in real-time
											to answer questions, provide information, and increase
											sales.
										</Grid>
									</Grid>
									<Grid
										item
										xs={12}
										md={4}
										style={{
											marginTop: isBig ? '0px' : '32px',
										}}
									>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<img src="/assets/icon3.png" alt="Reach Everyone" />
										</Grid>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<Typography variant="h5">Reach Everyone</Typography>
										</Grid>
										<Grid
											item
											xs={12}
											style={{ textAlign: 'center', color: '#909090' }}
										>
											Greenlight is available on both iOS and Android and is
											available anywhere cannabis is legal for medical or
											recreational use.
										</Grid>
									</Grid>
								</Grid>
							) : null}
							{isBig ? (
								<Grid
									item
									xs={12}
									container
									spacing={2}
									style={{ padding: '8px' }}
								>
									<div
										className="ytdiv"
										style={{
											textAlign: 'center',
											marginTop: '16px',
											marginBottom: '0px',
											width: '100%',
										}}
									>
										{isBig || isSmall || isxSmall ? (
											<Video isSmall={isSmall} isxSmall={isxSmall} />
										) : null}
									</div>
								</Grid>
							) : null}
							<Grid
								item
								xs={12}
								container
								style={{ marginTop: '32px', padding: '8px' }}
							>
								<Grid item xs={12} md={6}>
									<Grid item xs={12} style={{ textAlign: 'center' }}>
										<Typography variant="h5">
											FEATURES FOR DISPENSARIES
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										style={{
											padding: isBig ? '0px' : '8px',
											color: '#909090',
										}}
									>
										<ul>
											<li>Send instant push notifications to all contacts.</li>
											<li>No limit on how many contacts you can have.</li>
											<li>
												Chat with customers in real-time to answer questions and
												provide information.
											</li>
											<li>
												Customize your listing with contact info, website &amp;
												social media links, graphical flyers, video, Weedmaps
												link, and more.
											</li>
											<li>
												Simple dashboard to post updates at any time from
												anywhere.
											</li>
										</ul>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									style={{
										marginTop: isBig ? '0px' : '32px',
									}}
								>
									<Grid item xs={12} style={{ textAlign: 'center' }}>
										<Typography variant="h5">FEATURES FOR CUSTOMERS</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										style={{
											padding: isBig ? '0px' : '8px',
											color: '#909090',
										}}
									>
										<ul>
											<li>No signup or account is required.</li>
											<li>
												Real-time notifications about updates &amp; offers.
											</li>
											<li>Follow dispensaries by scanning their QR code.</li>
											<li>
												Swipe to browse the latest updates from the shops you
												follow.
											</li>
											<li>Tap on a shop's name / logo to get more info.</li>
											<li>
												Enable / disable notifications from specific shops or
												show / hide specific shops from your list.
											</li>
										</ul>
									</Grid>
								</Grid>
							</Grid>
						</Container>
					</Container>
				</Grid>
				<Grid
					container
					style={{
						marginTop: '0px',
						marginBottom: '0px',
						backgroundColor: '#0c0c0c',
						paddingTop: '20px',
						paddingBottom: '20px',
					}}
				>
					<Container component="main" maxWidth="lg" disableGutters>
						<Sub home={true} />
					</Container>
				</Grid>
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(Home))
