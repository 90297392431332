import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { setUser, SignOut, setBaseurl } from '../../actions'
import { Grid, Container } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import Director from '../Director'
import BigButton from '../ui/BigButton'
import { Link } from 'react-router-dom'
//import Hidden from '@material-ui/core/Hidden';
import HomeIcon from '@material-ui/icons/HomeTwoTone'
import ImportContacts from '@material-ui/icons/ImportContactsOutlined'
import Visibility from '@material-ui/icons/VisibilityOutlined'

import {
	AttachMoneyTwoTone as Balance,
	AccountBoxTwoTone as Profile,
	LockTwoTone as Password,
	AccountTreeTwoTone as Accounts,
	ExitToAppTwoTone as SignOutIcon,
	SettingsTwoTone as Settings,
	SendOutlined as Send,
	SmsTwoTone as Sms,
	DialpadTwoTone as Dialpad,
} from '@material-ui/icons'


const styles = (theme) => {
	return {
		paper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginTop: theme.spacing(2),
		},
		buttons: {
			width: '50%',
		},
		menu: {
			//backgroundColor: '#000000'
			marginTop: theme.spacing(1),
		},
		menu_container: {
			//height: 'calc(100vh - 102px)'
			marginTop: theme.spacing(1),
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.primary.main,
		},
	}
}

class Account extends React.Component {
	state = {
		message: false,
		store: {},
	}

	componentDidUpdate(nextProps) {
		if (this.props.user !== nextProps.user) {
			this.setState({ user: nextProps.user })
		}
	}

	componentDidMount() {
		//this.setState({store: store.getState()})

		if (this.props.loaded) {
			return false
		}

		var load = () => {
			axios
				.post(this.props.baseurl + 'remote/user/data', {
					token: this.props.user.token,
					account: true,
				})
				.then((result) => {
					//console.log('set base 1')
					this.props.setUser(result.data)
					
					this.props.setBaseurl(result.data.base_url)
				})
		}

		load()
	}

	signOutAll = (e) => {
		e.preventDefault()
		axios
			.post(this.props.baseurl + 'remote/user/signout', {
				token: this.props.user.token,
			})
			.then((result) => {
				this.props.SignOut()

				Director('/')
			})
	}

	render() {



		var is_parent = localStorage.getItem('parent_token')


		return (
			<Container component="main" disableGutters maxWidth="sm">
				<CssBaseline />
			
					<Grid
						container
						justifyContent="center"
						direction="row"
						alignItems="center"
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<HomeIcon
							fontSize="large"
							color="primary"
							style={{ marginRight: '8px' }}
						/>

						<Typography component="h1" variant="h5">
							My Account
						</Typography>
					</Grid>

					<Grid
						container
						alignItems="stretch"
						direction="row"
						className={this.props.classes.menu_container}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							className={this.props.classes.menu}
						>
							<Grid container>
								<Grid item xs={12} sm={12} md={12}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12} md={6}>
											<Button
												color="primary"
												variant="contained"
												component={Link}
												to="/publish"
												fullWidth
												endIcon={<Send />}
											>
												Publish An Update
											</Button>
										</Grid>

										<Grid item xs={12} sm={12} md={6}>
											<Button
												color="primary"
												variant="contained"
												component={Link}
												to="/current"
												fullWidth
												endIcon={<Visibility />}
											>
												View Current Listing
											</Button>
										</Grid>

										{process.env.REACT_APP_QUEUE !== 'push' ? (
											<Grid item xs={12} sm={12} md={6}>
												<Button
													color="primary"
													variant="contained"
													component={Link}
													to="/contacts/import"
													fullWidth
													endIcon={<ImportContacts />}
												>
													Import Contacts
												</Button>
											</Grid>
										) : null}

<Grid item xs={12} sm={12} md={6}>
											<BigButton
												actionText="Edit"
												to="/profile"
												icon={<Settings fontSize="large" color="primary" />}
												title={`Edit Profile`}
												text={`Update the contact information for your listing, change your logo, and control your display preferences.`}
											/>
										</Grid>

										{this.props.user &&
										this.props.user.token ? (
											<Grid item xs={12} sm={12} md={6}>
												<BigButton
													actionText="Manage"
													to="/payments"
													reload={true}
													icon={<Balance fontSize="large" color="primary" />}
													title={`Account & Payments`}
													text={`Manage your account plan, add or remove payment methods, and view your order history.`}
												/>
											</Grid>
										) : null}



										<Grid item xs={12} sm={12} md={6}>
											<BigButton
												actionText="Manage"
												to="/credentials"
												icon={<Profile fontSize="large" color="primary" />}
												title={`Manage Credentials`}
												text={`Manage and verify the email address and phone number you use to sign in and receive communications.`}
											/>
										</Grid>


										{(!this.props.user.parent_account || is_parent) &&
										this.props.user.connect &&
										process.env.REACT_APP_QUEUE !== 'push' ? (
											<Grid item xs={12} sm={12} md={6}>
												<BigButton
													actionText="Connect"
													to="/connect"
													icon={<Sms fontSize="large" color="primary" />}
													title={`Connect With Twilio API`}
													text={`Connect a Twilio account to your dashboard to enable the ability to send and receive text messages.`}
												/>
											</Grid>
										) : null}

										{process.env.REACT_APP_QUEUE !== 'email' &&
										process.env.REACT_APP_QUEUE !== 'push' ? (
											<Grid item xs={12} sm={12} md={6}>
												<BigButton
													actionText="Manage"
													to="/numbers"
													icon={<Dialpad fontSize="large" color="primary" />}
													title={`Manage Number Pool`}
													text={`Manage the list of phone numbers that will be used to send and receive messages on your dashboard.`}
												/>
											</Grid>
										) : null}

										{!this.props.user.parent_account ? (
											<Grid item xs={12} sm={12} md={6}>
												<BigButton
													actionText="Manage"
													to="/accounts"
													icon={<Accounts fontSize="large" color="primary" />}
													title={`Manage Sub-Accounts`}
													text={`Manage sub-accounts or sign in to a sub-account dashboard to manage or update that sub-account.`}
												/>
											</Grid>
										) : null}

										<Grid item xs={12} sm={12} md={6}>
											<BigButton
												actionText="Change"
												to="/change"
												icon={<Password fontSize="large" color="primary" />}
												title={`Change password`}
												text={`Change your account password or choose for a reset link to be sent to you via email or text message.`}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<BigButton
												actionText="Sign Out"
												title={`Sign Out Everywhere`}
												icon={<SignOutIcon fontSize="large" color="primary" />}
												text={`Sign out of this account on every device that it is currently logged in to (including this device).`}
												click={(e) => {
													
													this.signOutAll(e)
												}}
											/>
										</Grid>
									</Grid>

									{/*}
								<Box p={.3}>
									<Button
										variant="contained"
										component={Link}
										to="/accounts"
										fullWidth
									>
										Sub-Accounts
									</Button>
								</Box>
								*/}
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} sm={12} md={6}></Grid>
					</Grid>
				
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
	loaded: state.loaded,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setBaseurl: (baseurl) => dispatch(setBaseurl(baseurl)),
	SignOut: () => dispatch(SignOut()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Account))
