const messages = (state = null, action) => {
	switch (action.type) {
		case 'SET_MESSAGES':			
		return Object.assign({}, state, {
			messages: action.messages,
		})
		default:
			return state
	}
}

export default messages