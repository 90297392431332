import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import LockOutlinedIcon from '@material-ui/icons/LockTwoTone'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert, setTemp } from '../../actions'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Director from 'components/Director'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}))

function Verified(props) {
	const classes = useStyles()

	const [verified] = useState(props.temp.verified)
	

	React.useEffect(() => {
		if (verified) {
		} else {
			Director('/dashboard')
		}
	}, [verified])

	return (
		<Container component="main" disableGutters maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					<LockOutlinedIcon
						fontSize="large"
						color="primary"
						style={{ marginRight: '8px' }}
					/>

					<Typography component="h1" variant="h5">
						Account {verified === true ? 'VERIFIED' : 'NOT VERIFIED'}
					</Typography>
				</Grid>

				<Container component="main" maxWidth="xs" disableGutters>
					<CssBaseline />
					<div className={`${classes.paper} top-paper`}>
						<div style={{ marginTop: '16px' }}>
							<Typography align="center">
								<Button
									component={Link}
									to="/dashboard"
									variant="contained"
									color="primary"
									type="submit"
								>
									Go to dashboard
								</Button>
							</Typography>
						</div>
					</div>
				</Container>
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
	temp: state.temp,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Verified)
