import React, { useEffect, useRef } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockTwoTone'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { setAlert, setUser, setCheckout } from '../../actions'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import History from './History'
import PaymentHistory from './PaymentHistory'
import Tools from '../tools/Tools'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// uncomment to use Stripe Checkout
/*
import { loadStripe } from '@stripe/stripe-js'
var stripePromise = null
if (window.location.href.includes(process.env.REACT_APP_LIVE_DOMAIN_CHECK)) {
	stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_LIVE);
} else {
	stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_TEST);
}
*/

var stripePromise = null

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Payments(props) {
	const classes = useStyles()

	const [credits, setCredits] = React.useState('')

	var user_credits = props.user
		? props.user.credits
			? props.user.credits
			: 0
		: 0

	const loaded = useRef(false)

	const [userAccount, setUserAccount] = React.useState({
		per_sms: 0,
		per_mms: 0,
		per_combo: 0,
		credits: 0,
		choice: 0,
		payment_methods: [],
	})

	React.useEffect(() => {
		if (props.user) {
			setUserAccount(props.user)
		}
	}, [props.user])

	useEffect(() => {
		load()
		// eslint-disable-next-line
	}, [])

	React.useEffect(() => {
		setCredits(user_credits)
	}, [user_credits])

	var load = () => {
		if (props.loaded) {
			return false
		}

		axios
			.post(props.baseurl + 'remote/user/data', {
				token: props.user.token,
				account: true,
			})
			.then((result) => {
				props.setUser(result.data)
				loaded.current = true
			})
	}

	var removePaymentMethod = (id) => {
		axios
			.post(props.baseurl + 'remote/payments/removepaymentmethod', {
				token: props.user.token,
				_id: id,
			})
			.then((result) => {
				props.setUser(result.data)

				loaded.current = true
			})
	}

	var setDefaultPaymentMethod = (id) => {
		axios
			.post(props.baseurl + 'remote/payments/setdefaultpaymentmethod', {
				token: props.user.token,
				_id: id,
			})
			.then((result) => {
				props.setUser(result.data)

				loaded.current = true
				props.setAlert({
					open: true,
					severity: 'success',
					message: 'Default Payment Method Changed',
				})
			})
	}

	//var default_method = []
	var all_methods = []

	if (userAccount.payment_methods) {
		userAccount.payment_methods.forEach((v, i) => {
			if (v.card_token.toString() !== props.user.card_token) {
				var payment_line = (
					<Button
						variant="text"
						size="small"
						color="primary"
						onClick={() => {
							setDefaultPaymentMethod(v.card_token.toString())
						}}
					>
						Set As Default
					</Button>
				)
			} else {
				payment_line = (
					<Box>
						<Typography variant="caption">Default Payment Method</Typography>
					</Box>
				)
			}

			var new_method = (
				<Grid
					container
					spacing={2}
					direction="row"
					key={v._id.toString()}
					alignItems="center"
				>
					<Grid item xs={3}>
						{v.brand} {v.label}
					</Grid>

					<Grid item xs={6}>
						{payment_line}
					</Grid>

					<Grid container item xs={3} alignContent="flex-end">
						<Button
							variant="text"
							color="primary"
							onClick={() => {
								var confirmed = window.confirm('Are you sure?')
								if (confirmed) {
									removePaymentMethod(v._id.toString())
								}
							}}
						>
							Remove
						</Button>
					</Grid>
				</Grid>
			)

			if (v.card_token.toString() !== props.user.card_token) {
				all_methods.push(new_method)
			} else {
				all_methods.unshift(new_method)
			}
		})
	}

	var credit = props.user && props.user.credits ? props.user.credits : 0

	if (props.user && props.user.connected && !props.user.parent_account) {
		credit = props.user.account_data
			? props.user.account_data.balance
			: props.user.balance
			? props.user.balance
			: 0
	}

	if (!props.user.connect) {
		credit = props.user && props.user.credits ? props.user.credits : 0
	}

	var package_details = {}
	switch (props.user.package) {
		case 0:
			package_details.title = 'Free'
			break
		case 1:
			package_details.title = 'Basic'
			break
		case 2:
			package_details.title = 'Plus'
			break
		case 3:
			package_details.title = 'Pro'
			break
		default:
			package_details.title = 'Free'
			break
	}

	return (
		<Container component="main" maxWidth="xs" disableGutters>
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					<LockOutlinedIcon
						fontSize="large"
						color="primary"
						style={{ marginRight: '8px' }}
					/>

					<Typography component="h1" variant="h5">
						{process.env.REACT_APP_QUEUE === 'email'
							? 'Account Summary'
							: 'Account Balance'}
					</Typography>
				</Grid>
				{process.env.REACT_APP_QUEUE !== 'email' && process.env.REACT_APP_QUEUE !== 'push' ? (
					<Grid container className={'paper-content'}>
						<Grid item xs={8} sm={6}>
							<Typography component="h1" variant="h6">
								Available Balance:
							</Typography>
						</Grid>
						<Grid item xs={4} sm={6}>
							<Typography component="h1" variant="h6" color="primary">
								{credit ? Tools.formatter(credit) : Tools.formatter(0)}
							</Typography>
						</Grid>
					</Grid>
				) : null}
				{props.user.connect || process.env.REACT_APP_QUEUE === 'email' ? (
					<Grid container style={{ marginTop: '16px' }}>
						<Grid item xs={8} sm={6}>
							<Typography component="h1" variant="h6">
								Current Plan:
							</Typography>
						</Grid>
						<Grid item xs={4} sm={6}>
							<Typography component="h1" variant="h6" color="primary">
								{package_details.title}
							</Typography>
						</Grid>
					</Grid>
				) : null}
			</div>

			{(process.env.REACT_APP_QUEUE !== 'email' || (!props.user.connect && props.user.package < 2)) && 1 === 2  ? (
				<Box pt={2}>
					<Button
						variant="contained"
						fullWidth
						color="primary"
						component={Link}
						to="/choices"
						disabled={props.user.package > 1}
					>
						Upgrade Plan
					</Button>
				</Box>
			) : null}

{process.env.REACT_APP_QUEUE !== 'email' || (!props.user.connect && props.user.package < 3)  ? (
				<Box pt={2}>
					<Button
						variant="contained"
						fullWidth
						color="primary"
						component={Link}
						to="/subscribe"
						disabled={props.user.package > 3}
					>
						Change Subscription
					</Button>
				</Box>
			) : null}

			{process.env.REACT_APP_QUEUE !== 'email' && 1 === 2 ? (
				<Box pt={2}>
					<Button
						variant="contained"
						fullWidth
						color="primary"
						component={Link}
						to="/credits"
					>
						Add Credit
					</Button>
				</Box>
			) : null}
			{process.env.REACT_APP_QUEUE !== 'email' && 1 === 2 ? (
				<div>
					<Box pt={2}>
						<Grid container spacing={1}>
							<Grid item xs={6}>
								<TextField
									variant="standard"
									margin="dense"
									fullWidth
									label="Cost Per SMS"
									name="credit"
									autoComplete="credit"
									autoFocus
									value={userAccount.per_sms}
									disabled
								/>
							</Grid>

							<Grid item xs={6}>
								<TextField
									variant="standard"
									margin="dense"
									fullWidth
									label="Remaining SMS"
									name="credit"
									autoComplete="credit"
									autoFocus
									value={
										(credits / userAccount.per_sms).toFixed(0) > 0
											? (credits / userAccount.per_sms).toFixed(0)
											: 0
									}
									disabled
								/>
							</Grid>
						</Grid>

						<Grid container spacing={1}>
							<Grid item xs={6}>
								<TextField
									variant="standard"
									margin="dense"
									fullWidth
									label="Cost Per MMS"
									name="credit"
									autoComplete="credit"
									autoFocus
									value={userAccount.per_mms}
									disabled
								/>
							</Grid>

							<Grid item xs={6}>
								<TextField
									variant="standard"
									margin="dense"
									fullWidth
									label="Remaining MMS"
									name="credit"
									autoComplete="credit"
									autoFocus
									value={
										(credits / userAccount.per_mms).toFixed(0) > 0
											? (credits / userAccount.per_mms).toFixed(0)
											: 0
									}
									disabled
								/>
							</Grid>
						</Grid>

						<Grid container spacing={1}>
							<Grid item xs={6}>
								<TextField
									variant="standard"
									margin="dense"
									fullWidth
									label="Cost Per Combo"
									name="credit"
									autoComplete="credit"
									autoFocus
									value={userAccount.per_combo}
									disabled
								/>
							</Grid>

							<Grid item xs={6}>
								<TextField
									variant="standard"
									margin="dense"
									fullWidth
									label="Remaining Combo"
									name="credit"
									autoComplete="credit"
									autoFocus
									value={
										(credits / userAccount.per_combo).toFixed(0) > 0
											? (credits / userAccount.per_combo).toFixed(0)
											: 0
									}
									disabled
								/>
							</Grid>
						</Grid>
					</Box>
				</div>
			) : null}

			<Box pt={3}>
				<Grid container spacing={0}>
					<PaymentHistory
						methods={all_methods}
						addPayment={
							<React.Fragment>
							<Box pt={0}>
								<Button
									variant="contained"
									fullWidth
									color="primary"
									component={Link}
									to="/account/addpayment"
								>
									Add Payment Method
								</Button>
							</Box>
							
							{stripePromise ? 
							<Box pt={1}>
				<Button
					variant="contained"
					fullWidth
					color="primary"				
					onClick={async () => {
						

						var res = await axios
						.post(props.baseurl + 'remote/payments/checkout_session', {
							token: props.user.token,
						})
			
			
					
			
						//setClientSecret(res.data.client_secret)
					setCheckout(res.data.session_id)
			
					if (res.data.customer_id) {
						
						var u = Object.assign({}, props.user, {
							customer_token: res.data.customer_id,				
						})
			
						props.setUser(u)

						const stripe = await stripePromise;

						stripe.redirectToCheckout({
							// Make the id field from the Checkout Session creation API response
							// available to this file, so you can provide it as argument here
							// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
							sessionId: res.data.session_id
						  }).then(function (result) {
							// If `redirectToCheckout` fails due to a browser or network
							// error, display the localized error message to your customer
							// using `result.error.message`.
						  });
			
			
					}

		

					}}
				>
					Add Checkout Payment Method
				</Button>
			</Box>
							: null}
							
							</React.Fragment>
						}
					/>
				</Grid>
			</Box>

			<Box pt={3} mb={3}>
				<Grid container>
					<History />
				</Grid>
			</Box>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
	loaded: state.loaded,
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
	setUser: (user) => dispatch(setUser(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Payments)
