import React, { useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import Box from '@material-ui/core/Box'
import axios from 'axios'
import { setGroups } from '../../../actions/groups'

function Groups(props) {
	useEffect(() => {
		load()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		// eslint-disable-next-line
	}, [props.selected_groups])

	var load = () => {
		props.setGroups([])
		axios
			.post(props.baseurl + 'remote/groups/list', {
				token: localStorage.getItem('token'),
				filter: {
					pagination: {
						limit: 100,
						skip: 0,
					},
				},
			})
			.then((result) => {
				props.setGroups(result.data.rows)
			})
	}

	var form_groups = props.groups.map((v, i) => {
		return (
			<FormGroup aria-label="groups" row key={v._id.toString()}>
				<FormControlLabel
					control={
						<Checkbox
							disabled={props.disabled}
							checked={props.isChecked(v._id.toString())}
							onChange={() => {
								var new_groups = props.changeChecked(v._id.toString(), v.name)
								props.updateField(new_groups)
							}}
							value={v._id.toString()}
							color="primary"
						/>
					}
					label={v.name}
				/>
			</FormGroup>
		)
	})

	return form_groups.length ? (
		<Box pt={1}>
			<FormControl component="fieldset">
				<FormLabel component="legend">Groups</FormLabel>
				{form_groups}
			</FormControl>
		</Box>
	) : null
}

const mapStateToProps = (state) => ({
	user: state.auth,
	groups: state.groups,
	baseurl: state.baseurl
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	setGroups: (groups) => dispatch(setGroups(groups)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Groups)
