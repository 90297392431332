import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Container, IconButton, Input, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'
import axios from 'axios'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import Balance from './Balance'
import NotConnected from './NotConnected'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		marginTop: theme.spacing(2),
	},
	formControl: {
		marginTop: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))

function Connect(props) {
	const classes = useStyles()
	const [token] = React.useState(localStorage.getItem('token') || null)
	const [authToken, setauthToken] = React.useState(null)
	const [accountSid, setAccountSid] = React.useState(null)
	const [showToken, setShowToken] = React.useState(false)

	var load = () => {
		axios
			.post(props.baseurl + 'remote/user/data', {
				token: props.user.token,
			})
			.then((result) => {
				props.setUser(result.data)
			})
	}

	React.useEffect(load, [])

	const handleMouseDownToken = (event) => {
		event.preventDefault()
	}

	const handleClickShowToken = () => {
		setShowToken(!showToken)
	}

	if (authToken) {
		var use_token = authToken
	} else {
		if (authToken === null) {
			use_token = props.user.authToken ? props.user.authToken : ''
		} else {
			use_token = ''
		}
	}

	if (accountSid) {
		var use_sid = accountSid
	} else {
		if (accountSid === null) {
			use_sid = props.user.accountSid ? props.user.accountSid : ''
		} else {
			use_sid = ''
		}
	}
	var submitChange = (e) => {
		e.preventDefault()

		axios
			.post(props.baseurl + 'remote/user/update', {
				token: token,
				updates: {
					authToken: use_token,
					accountSid: use_sid,
					connecting: true
				},
			})
			.then((result) => {
				if (result.data.severity === 'success') {
					var user = Object.assign({}, props.user, {
						authToken: use_token,
						accountSid: use_sid,
						connected: result.data.account_data
							? result.data.account_data.connected
							: false,
						account_data: {
							balance: result.data.account_data
								? result.data.account_data.balance
								: 0,
							connected: true,
						},
					})



					props.setUser(user)
				}

				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})
			})
	}

	return (
		<Container component="main" maxWidth="xs" disableGutters>
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					<AccountBoxIcon
						fontSize="large"
						color="primary"
						style={{ marginRight: '8px' }}
					/>

					<Typography component="h1" variant="h5">
						Connect With Twilio API
					</Typography>
				</Grid>

				<React.Fragment>
					<Grid container item className={classes.container + ' paper-content'}>
						{props.user.connected ? (
	
								<Grid item xs={12}>
									<Balance />
								</Grid>

						) : props.user.accountSid || props.user.authToken ? <Grid item xs={12}>
						<NotConnected />
					</Grid> : null}

						<form className={classes.form} noValidate onSubmit={submitChange}>
							<div>
								<TextField
									variant="standard"
									margin="normal"
									fullWidth
									id="accountSid"
									label="AccountSid"
									name="accountSid"
									autoComplete="AccountSid"
									value={use_sid}
									onChange={(e) => setAccountSid(e.target.value)}
								/>

								<FormControl
									variant="standard"
									fullWidth
									style={{ marginTop: '8px' }}
								>
									<InputLabel htmlFor="authToken">authToken</InputLabel>
									<Input
										type={showToken ? 'text' : 'password'}
										fullWidth
										id="authToken"
										label="authToken"
										name="authToken"
										autoComplete="authToken"
										value={use_token}
										onChange={(e) => {
											setauthToken(e.target.value)
										}}
										endAdornment={
											<InputAdornment
												position="end"
												style={{ marginBottom: '8px' }}
											>
												<IconButton
													aria-label="toggle token visibility"
													onClick={handleClickShowToken}
													onMouseDown={handleMouseDownToken}
												>
													{showToken ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
							</div>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Save Credentials
							</Button>
						</form>
					</Grid>
				</React.Fragment>
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),

	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Connect)
