const auth = (state = null, action) => {
	switch (action.type) {
		case 'SET_USER':			
			if (action.user && action.user.token) {
				localStorage.setItem('token', action.user.token)
			}
			return action.user
		case 'SET_USER_SOCKET':
			return Object.assign({}, state, {
				socket: action.socket,
			})
		case 'SIGN_OUT':
			localStorage.removeItem('mode')
			localStorage.removeItem('token')
			localStorage.removeItem('parent_token')			
			return null
		default:
			return state
	}
}

export default auth
