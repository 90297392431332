import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid, Container } from '@material-ui/core'
import { SocialIcon } from 'react-social-icons'

const useStyles = withStyles((theme) => ({}), { withTheme: true })

function Home(props) {
	//const xmatches = props.theme.breakpoints.down('sm')

	//var isSm = useMediaQuery(xmatches)

	const xsmatches = props.theme.breakpoints.down('xs')

	var isXs = useMediaQuery(xsmatches)

	//const matches = props.theme.breakpoints.up('md')

	//	var isMd = useMediaQuery(matches)

	return (!props.user && !props.needs_brand) || props.view ? (
		<React.Fragment>
			<Container
				component="main"
				disableGutters
				maxWidth="xs"
				style={{ paddingTop: '16px' }}
			>
				<center>
					<Grid
						container
						style={{
							paddingLeft: isXs ? '16px' : '0px',
							paddingRight: isXs ? '16px' : '0px',
						}}
					>
						<Grid item xs={6}>
							<div style={{ height: '48px', textAlign: 'left' }}>
								<a
									style={{ height: '48px', textAlign: 'center' }}
									href="https://play.google.com/store/apps/details?id=com.greenlightalerts&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
								>
									<img
										alt="Get it on Google Play"
										src="/assets/play_badge.png"
										style={{ height: '48px' }}
									/>
								</a>
							</div>
						</Grid>
						<Grid item xs={6}>
							<div style={{ height: '48px', textAlign: 'right' }}>
								<a
									style={{ height: '48px', textAlign: 'center' }}
									href="https://apps.apple.com/us/app/greenlight-alerts/id1567499968?itsct=apps_box_badge&itscg=30200"
								>
									<img
										src="/assets/ios_badge.png"
										alt="Download on the App Store"
										style={{ borderRadius: '0px', height: '48px' }}
									/>
								</a>
							</div>
						</Grid>
					</Grid>
				</center>
			</Container>

			<div
				style={{
					textAlign: 'center',
					marginTop: '16px',
					maxWidth: '400px',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				<Grid container>
					<Grid item xs={3}>
						<SocialIcon
							url="https://twitter.com/greenlight0420"
							network="twitter"
							fgColor="#fff"
							style={{
								height: 32,
								width: 32,
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<SocialIcon
							url="https://instagram.com/greenlight0420"
							network="instagram"
							fgColor="#fff"
							style={{
								height: 32,
								width: 32,
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<SocialIcon
							url="https://snapchat.com/add/greenlight0420"
							network="snapchat"
							fgColor="#fff"
							style={{
								height: 32,
								width: 32,
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<SocialIcon
							url="https://facebook.com/greenlight0420"
							network="facebook"
							fgColor="#fff"
							style={{
								height: 32,
								width: 32,
							}}
						/>
					</Grid>
				</Grid>
			</div>

			<div
				style={{
					bottom: 0,
					textAlign: 'center',
					width: '100%',
				}}
			>
				<Typography
					className="copyright"
					variant="body2"
					style={{
						textAlign: 'center',
						paddingTop: '16px',
						paddingBottom: '8px',
					}}
				>
					© 2023 {process.env.REACT_APP_NAME}
				</Typography>
			</div>
		</React.Fragment>
	) : null
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(Home))
