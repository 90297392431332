import React, { useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import { setContact } from '../../../actions/contacts'
import axios from 'axios'
import Groups from './Groups'
import Status from './Status'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Director from '../../Director'
import EditIcon from '@material-ui/icons/EditOutlined'
import AddIcon from '@material-ui/icons/AddOutlined'

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Edit(props) {
	const classes = useStyles()

	const [contact, setContact] = React.useState(props.contact)
	const loaded = useRef(false)

	useEffect(() => {
		if (props.match.params.contactID) {
		load()
		} else {
			//load()
			props.setContact({status: 'active'})
			loaded.current = true
		}
		// eslint-disable-next-line
	}, [])

	
	React.useEffect(() => {
		setContact(props.contact)
	}, [props.contact])


	var load = () => {
		axios
			.post(props.baseurl + 'remote/contacts/load', {
				token: props.user.token,
				_id: props.match.params.contactID,
			})
			.then(result => {
				props.setContact(result.data)
				loaded.current = true
			})
	}

	var updateGroups = (value) => {
		updateContact('groups',value)
	}

	var updateContact = (field, value) => {
		var updates = {}
		updates[field] = value

		setContact(Object.assign({}, contact, updates))

		props.setContact(Object.assign({}, contact, updates))
	}

	var changeChecked = (_id, name) => {
		var found = false
		var foundIndex = 0

		if (contact.groups) {
			contact.groups.forEach((v, i) => {
				if (v._id.toString() === _id) {
					found = true
					foundIndex = i
				}
			})
		}

		if (found) {
			contact.groups.splice(foundIndex, 1)
			return contact.groups
		} else if (contact.groups) {
			var new_groups = Object.assign([], contact.groups)

			new_groups.push({
				_id: _id,
				name: name,
			})

			return new_groups
		} else {
			new_groups = []

			new_groups.push({
				_id: _id,
				name: name,
			})

			return new_groups
		}
	}

	var isChecked = _id => {
		var found = false

		if (contact.groups) {
			contact.groups.forEach((v, i) => {
				if (v._id.toString() === _id) {
					found = true
				}
			})
		}

		if (found) {
			return true
		} else {
			return false
		}
	}

	var submitSave = e => {
		e.preventDefault()

		axios
			.post(props.baseurl + 'remote/contacts/save', {
				token: props.user.token,
				_id: contact._id,
				phone_number: contact.phone_number,
				status: contact.status,
				date_added: contact.date_added,
				email: contact.email,
				name: contact.name,
				groups: contact.groups ? contact.groups : [],
			})
			.then(result => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})


				if (!props.match.params.contactID && result.data._id) {
					loaded.current = false
					Director('/contacts/edit/' + result.data._id, true)
				}

			})
			.catch(err => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: 'Error',
				})
			})
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>

				<Grid
  container
  justifyContent="center"
  direction="row"
  alignItems="center"
  style={{ marginLeft: "auto", marginRight: "auto" }}
>
{contact && contact._id ? <EditIcon fontSize="large" color="primary" style={{ marginRight: "8px" }} /> : <AddIcon fontSize="large" color="primary" style={{ marginRight: "8px" }} /> }

  <Typography component="h1" variant="h5">
  {props.title ? props.title : 'Edit Contact'}
  </Typography>
</Grid>

				{contact ? (
					<form className={classes.form} noValidate onSubmit={submitSave}>
						<TextField
							variant="standard"
							margin="normal"
							required
							fullWidth
							id="phone_number"
							label="Phone Number"
							name="phone_number"
							autoComplete="phone"
							autoFocus
							value={contact.phone_number ? contact.phone_number : ''}
							onChange={e => {
								updateContact('phone_number', e.target.value)
							}}
						/>
						<TextField
							variant="standard"
							margin="normal"
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							
							value={contact.email ? contact.email : ''}
							onChange={e => {
								updateContact('email', e.target.value)
							}}
						/>
						<TextField
							variant="standard"
							margin="normal"
							fullWidth
							id="name"
							label="Name"
							name="name"
							autoComplete="name"
							
							value={contact.name ? contact.name : ''}
							onChange={e => {
								updateContact('name', e.target.value)
							}}
						/>

						{<Groups updateField={updateGroups} isChecked={isChecked} changeChecked={changeChecked} />}
						<Box pt={2}>
							<Status updateContact={updateContact} />
						</Box>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							Save Contact
						</Button>
					</form>
				) : null}
			</div>
		</Container>
	)
}

const mapStateToProps = state => ({
	user: state.auth,
	contact: state.contact,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setContact: contact => dispatch(setContact(contact)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
