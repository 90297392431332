import React from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => {
	return {}
}

const options = ['Reload', 'Export']

function SplitButton(props) {
	const [open, setOpen] = React.useState(false)
	const anchorRef = React.useRef(null)
	const [selectedIndex, setSelectedIndex] = React.useState(0)

	function handleClick() {
		if (options[selectedIndex] === 'Export') {
			props.export()
		}

		if (options[selectedIndex] === 'Reload') {
			props.reload()
		}
	}

	function handleMenuItemClick(event, index) {
		setSelectedIndex(index)
		setOpen(false)
	}

	function handleToggle() {
		setOpen(prevOpen => !prevOpen)
	}

	function handleClose(event) {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}

		setOpen(false)
	}

	return (
		<React.Fragment>
			<ButtonGroup
				variant="standard"
				color="primary"
				ref={anchorRef}
				aria-label="split button"
			>
				<Button onClick={handleClick}>{options[selectedIndex]}</Button>
				<Button
					color="primary"
					variant="standard"
					size="small"
					aria-owns={open ? 'menu-list-grow' : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper open={open} anchorEl={anchorRef.current} transition style={{zIndex:2}} placement="bottom-end">
				{({ TransitionProps, placement }) => {
					return (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'left top' : 'left bottom',
						}}
					>
						<Paper id="menu-list-grow">
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList>
									{options.map((option, index) => (
										<MenuItem
											key={option}
											selected={index === selectedIndex}
											onClick={event => handleMenuItemClick(event, index)}
										>
											{option}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}}
			</Popper>
		</React.Fragment>
	)
}

export default withStyles(styles)(SplitButton)
