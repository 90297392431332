import React, { useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { setUser, setAlert, setTemp } from '../../../actions'
import { setMessage } from '../../../actions/messages'
import axios from 'axios'
import Groups from './Groups'
import Status from './Status'
import Picker from '../../../ui/form/Picker'
import Box from '@material-ui/core/Box'
import { Grid, Switch, FormControlLabel } from '@material-ui/core'
import Director from '../../Director'
import FormControl from '@material-ui/core/FormControl'
//import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import PreviewIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/EditOutlined'
import AddIcon from '@material-ui/icons/AddOutlined'
import LinkIcon from '@material-ui/icons/LinkOutlined'
import Visibility from '@material-ui/icons/Visibility'
import Moment from 'moment-timezone'
import Current from '../updates/Current'
import Limited from '../Limited'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'


const useStyles = makeStyles(
	(theme) => ({
		paper: {
			marginTop: theme.spacing(0),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.primary.main,
		},
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		submit: {
			marginTop: theme.spacing(2),
		},
		switch: {
			marginLeft: '8px',
		},
	}),
	{ withTheme: true }
)

function Edit(props) {
	const classes = useStyles()

	const [message, setLocalMessage] = React.useState(props.message)
	const loaded = useRef(false)
	const [saved, setSaved] = React.useState(false)
	const [preview, setPreview] = React.useState(false)

	const [shorten, setShorten] = React.useState(false)

	const [short_link, setShort] = React.useState('')
	const [long_link, setLong] = React.useState('')

	const [limited, setLimited] = React.useState(false)
	const [notifyLimited, setNotifyLimited] = React.useState(false)

	const [option, setOption] = React.useState(0)

	const handleChange = (event, newValue) => {
		setOption(newValue)
	}

	useEffect(() => {
		if (props.match.params.messageID) {
			load()
		} else {
			props.setMessage({ status: 'draft' })
			loaded.current = true
		}
		// eslint-disable-next-line
	}, [props.match.params.messageID])

	React.useEffect(() => {
		setLocalMessage(props.message)
	}, [props.message])

	/*
	var complete_request = async () => {
		Director('/')
	}
	*/

	React.useEffect(() => {
		var time_now = Moment().valueOf()

		if (props.user.last_notify) {
			var notifyTooOften = time_now - props.user.last_notify < 86400000
		}

		if (props.user.package === 0) {
			notifyTooOften = true
		}

		if (notifyTooOften && props.user.package < 2) {
			setNotifyLimited(true)
		} else {
			setNotifyLimited(false)
		}

		if (props.user.last_publish) {
			var publishTooOften = time_now - props.user.last_publish < 86400000

			if (publishTooOften && props.user.package < 2) {
				setLimited(true)
			} else {
				setLimited(false)
			}
		}

		if (publishTooOften && notifyTooOften) {
			/*
			var temp = Object.assign({}, props.temp, {
				limited: {
					title: 'You cannot update that often',
					text:
						props.user.package === 0
							? 'Free accounts cannot update their listing.'
							: `Basic accounts can only update and their listing and send a notification once every 24 hours.`,
					callback: complete_request,
					no_cancel: true,
					limited_text: 'Go Back To Dashboard',
				},
			})
			*/
		}
	}, [props.user])

	var load = () => {
		axios
			.post(props.baseurl + 'remote/messages/load', {
				token: props.user.token,
				_id: props.match.params.messageID,
			})
			.then((result) => {
				if (result.data._id) {
					var opt = 0

					if (result.data.urlonly) {
						opt = 1
					}

					if (result.data.textonly) {
						opt = 2
					}

					setOption(opt)

					props.setMessage(result.data)
					loaded.current = true
					setSaved(true)
				} else {
					props.setMessage({})
					loaded.current = true
					setSaved(false)
				}
			})
	}

	var shortenLink = (link) => {
		if (!link || short_link) {
			if (short_link) {
				var textBox = document.getElementById('short')
				textBox.select()
				document.execCommand('copy')

				props.setAlert({
					open: true,
					severity: 'success',
					message: 'short link copied',
					vertical: 'top',
					horizontal: 'right',
				})
			}

			return false
		}

		axios
			.post(props.baseurl + 'remote/user/shorten', {
				token: props.user.token,
				url: long_link,
			})
			.then((result) => {
				setShort(result.data.link)

				var textBox = document.getElementById('short')
				textBox.select()
				document.execCommand('copy')

				props.setAlert({
					open: true,
					severity: 'success',
					message: 'short link copied',
					vertical: 'top',
					horizontal: 'right',
				})
			})
	}

	var updateGroups = (value) => {
		updateMessage('groups', value)
	}

	var updateMessage = (field, value) => {
		var updates = {}
		updates[field] = value

		if (field === 'notify' && value === false) {
			updates['notifyImage'] = false
		}

		setSaved(false)

		setLocalMessage(Object.assign({}, message, updates))
	}

	var changeChecked = (_id, name) => {
		var found = false
		var foundIndex = 0

		if (message.groups) {
			message.groups.forEach((v, i) => {
				if (v._id.toString() === _id) {
					found = true
					foundIndex = i
				}
			})
		}

		if (found) {
			message.groups.splice(foundIndex, 1)
			return message.groups
		} else if (message.groups) {
			var new_groups = Object.assign([], message.groups)

			new_groups.push({
				_id: _id,
				name: name,
			})

			return new_groups
		} else {
			new_groups = []

			new_groups.push({
				_id: _id,
				name: name,
			})

			return new_groups
		}
	}

	var isChecked = (_id) => {
		var found = false

		if (message.groups) {
			message.groups.forEach((v, i) => {
				if (v._id.toString() === _id) {
					found = true
				}
			})
		}

		if (found) {
			return true
		} else {
			return false
		}
	}

	var previewText = () => {
		if (!message.textbody) {
			return false
		}

		axios({
			method: 'POST',
			url: '/remote/api/previewtext',
			data: { token: props.user.token, text: message.textbody },
			headers: {
				Accept: 'application/json',
				//				'Content-Type': 'multipart/form-data',
			},
		})
			.then((res) => {
				if (res.data.files && res.data.files.length) {
					updateMessage('textimage', res.data.files[0])
				}
			})
			.catch(function (thrown) {
				console.log(thrown)
			})
	}

	var submitSave = (e) => {
		e.preventDefault()

		if (message) {
			if (message.body !== null && message.body !== undefined) {
				var use_body = message.body
			}

			if (
				(message.body === null || message.body === undefined) &&
				props.user.signature
			) {
				use_body = '\r\n\r\n' + props.user.signature
			}
		}

		var count_body = use_body ? use_body.toString() : 0

		var isChrome = window.chrome
		if (isChrome) {
			count_body = count_body ? count_body.replace(/(\r\n|\n|\r)/g, '  ') : ''
		}

		var counts = Math.ceil(count_body.length / props.user.message_limit)

		var body_count = count_body.length

		if (counts === 0) {
			counts = 1
		}

		if (
			message.status === 'scheduled' &&
			(!message.scheduled_time || message.scheduled_time <= Moment().valueOf())
		) {
			props.setAlert({
				open: true,
				severity: 'error',
				message:
					'Scheduled messages must be scheduled for a valid date and time',
			})
			return false
		}

		axios
			.post(props.baseurl + 'remote/messages/save', {
				token: props.user.token,
				_id: message._id,
				body: message.body,
				status: message.status,
				date_added: message.date_added,
				scheduled_time: message.scheduled_time,
				groups: message.groups ? message.groups : [],
				body_count: body_count,
				counts: counts,
				notify: message.notify,
				notifyImage: message.notifyImage,
				image: message.image,
				textonly: message.textonly,
				textbody: message.textbody,
				textimage: message.textimage,
				urlonly: message.urlonly,
				urlbody: message.urlbody,
				option: option,
			})
			.then((result) => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})

				setSaved(true)

				props.setMessage(message)

				if (!props.match.params.messageID && result.data._id) {
					loaded.current = false
					Director('/updates/edit/' + result.data._id, true)
				}
			})
			.catch((err) => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: 'Error',
				})
			})
	}

	var use_body = ''
	var use_textbody = ''

	if (message) {
		if (message.textbody !== null && message.textbody !== undefined) {
			use_textbody = message.textbody
		}

		if (message.body !== null && message.body !== undefined) {
			use_body = message.body
		}

		if (
			(message.body === null || message.body === undefined) &&
			props.user.signature
		) {
			use_body = '\r\n\r\n' + props.user.signature
		}
	}

	var count_body = use_body.toString()

	var isChrome = window.chrome
	if (isChrome) {
		count_body = count_body.replace(/(\r\n|\n|\r)/g, '  ')
	}

	if (message && message.body) {
		var counts = Math.ceil(count_body.length / props.user.message_limit)

		var body_count = count_body.length

		if (counts === 0) {
			counts = 1
		}
	} else {
		counts = 1
		body_count = count_body.length
	}

	if (
		message &&
		(message.status === 'sent' ||
			message.status === 'reply' ||
			message.status === 'auto' ||
			message.status === 'inbound')
	) {
		var disabled = true
	}

	var package_details = {}
	switch (props.user.package) {
		case 0:
			package_details.title = 'Free'
			break
		case 1:
			package_details.title = 'Basic'
			break
		case 2:
			package_details.title = 'Plus'
			break
		case 3:
			package_details.title = 'Pro'
			break
		default:
			package_details.title = 'Free'
			break
	}

	//start return

	return (
		<Container component="main" disableGutters maxWidth="sm">
			{limited ? <Limited /> : null}

			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					{message && message._id ? (
						<EditIcon
							fontSize="large"
							color="primary"
							style={{ marginRight: '8px' }}
						/>
					) : (
						<AddIcon
							fontSize="large"
							color="primary"
							style={{ marginRight: '8px' }}
						/>
					)}

					<Typography component="h1" variant="h5">
						{message
							? props.title
								? props.title
								: message && message._id
								? 'Edit Update'
								: 'New Update'
							: null}
					</Typography>
				</Grid>

				{message ? (
					<React.Fragment>
						<form className={classes.form} noValidate onSubmit={submitSave}>
							<FormControl component="fieldset" style={{ display: 'flex' }}>
								{message.reply ? (
									<strong>This message is a reply to {message.to}</strong>
								) : null}

								<Box pt={1}>
									<Status
										disabled={saved && message && disabled ? true : false}
										updateMessage={updateMessage}
										message={message}
									/>
								</Box>

								{message.status === 'scheduled' ? (
									<Box pt={1}>
										<Picker
											fullWidth
											date={
												message.scheduled_time ? message.scheduled_time : null
											}
											disabled={saved && message && disabled ? true : false}
											callback={(value) => {
												updateMessage('scheduled_time', value)
											}}
										/>
									</Box>
								) : null}

								<Paper square style={{ flexGrow: 1, marginTop: '16px' }}>
									<Tabs
										//variant="fullWidth"
										value={option}
										indicatorColor="primary"
										textColor="primary"
										onChange={handleChange}
										variant="fullWidth"
									>
										<Tab
											label="Upload"
											onClick={() => {
												var updated = Object.assign({}, message)

												updated.textonly = false
												updated.urlonly = false

												setLocalMessage(updated)
											}}
											disabled={props.user.package < 0}
										/>
										<Tab
											label="URL"
											onClick={() => {
												var updated = Object.assign({}, message)

												updated.textonly = false
												updated.urlonly = true

												setLocalMessage(updated)
											}}
											disabled={props.user.package < 0}
										/>
										<Tab
											label="Text"
											onClick={() => {
												var updated = Object.assign({}, message)

												updated.textonly = true
												updated.urlonly = false

												setLocalMessage(updated)
											}}
											disabled={props.user.package < 0}
										/>
									</Tabs>
								</Paper>

								{props.user.package < 0 ? null : props.user ? null : (
									<Box pt={1}>
										<Grid container>
											<Grid item xs={12} md={6}>
												<FormControlLabel
													style={{
														marginLeft: '0px',
														marginTop: '10px',
														alignContent: 'center',
														alignItems: 'center',
														justifyContent: 'center',
													}}
													control={
														<Switch
															color="primary"
															checked={message.textonly ? true : false}
															onChange={() => {
																updateMessage('textonly', !message.textonly)
															}}
															value="checked"
															inputProps={{ 'aria-label': 'Text only?' }}
															className={classes.switch}
															disabled={limited}
														/>
													}
													labelPlacement="end"
													label={'Text only?'}
												/>
											</Grid>
										</Grid>
									</Box>
								)}

								{message.urlonly ? (
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										style={{ marginTop: '16px' }}
									>
										<TextField
											fullWidth
											variant="outlined"
											size="small"
											value={message.urlbody}
											placeholder="Image URL"
											onChange={(e) => {
												updateMessage('urlbody', e.target.value)
											}}
										/>
									</Grid>
								) : null}

								{message.textonly ? (
									<React.Fragment>
										<TextField
											style={{ borderRadius: '0px', backgroundColor: props.theme === 'light' ? '#fff' : '#000' }}
											variant="outlined"
											margin="normal"
											fullWidth
											placeholder="Listing Text"
											id="textbody"
											//label="Listing text"
											name="textbody"
											disabled={saved && message && disabled ? true : false}
											//defaultValue={message.body ? message.body : default_body}
											value={use_textbody}
											onChange={(e) => {
												updateMessage('textbody', e.target.value)
											}}
											multiline={true}
											rows={8}
										/>

										<Button
											fullWidth
											variant="outlined"
											aria-label="Clear"
											onClick={() => {
												previewText()
											}}
											startIcon={<PreviewIcon />}
										>
											Preview Text
										</Button>
									</React.Fragment>
								) : null}

								<Box pt={1}>
									{!message.textonly && !message.urlonly ? (
										<div style={{ marginTop: '4px', marginBottom: '8px' }}>
											
											<Typography component="p" variant="body2">
												Must be in .PNG or .JPG format and no larger than 1 MB
												(1024 KB)
											</Typography>
										</div>
									) : null}

									{!message.image && !message.textonly && !message.urlonly ? (
										<React.Fragment>
											<TextField
												fullWidth
												inputProps={{
													multiple: false,
													style: {
														padding: '16px',
														paddingBottom: '21px',
														paddingLeft: 'auto',
														paddingRight: 'auto',
													},
												}}
												margin="none"
												type="file"
												disabled={limited}
												onChange={(e) => {
													var target = e.target

													if (target && target.files) {
														const files = Array.from(target.files)

														const formData = new FormData()

														formData.append('upload_type', 'current')

														files.forEach((file, i) => {
															formData.append('file', file)
														})

														formData.append(
															'token',
															localStorage.getItem('token')
														)

														axios({
															method: 'POST',
															url: '/remote/api/upload',
															data: formData,
															headers: {
																Accept: 'application/json',
																'Content-Type': 'multipart/form-data',
															},
														})
															.then((res) => {
																if (res.data.files && res.data.files.length) {
																	updateMessage('image', res.data.files[0])
																}
															})
															.catch(function (thrown) {
																console.log(thrown)
															})
													}

													//this changes the value of the data that was passed down to state from props so that the text field updates, without updating state text field value will always come from state.data that was sent from (but now isolated from) props, so changing the text will do nothing unless this changes the state that provides the value to the field. This by itself does not trigger a save, the blur handler must happen to trigger a save or a save must be triggered. This is not needed at all if the async solution is in use, as it will update and save. Use this instead of the this.props.handleChange line  if you want to update the state locally and reflect changes, but trigger the save manually.
													//this.handleChange('data', obj);
												}}
												value={''}
												variant="filled"
												color="primary"
												multiline={false}
											/>
										</React.Fragment>
									) : (saved && message && disabled) ||
									  message.urlonly ||
									  message.textonly ? null : message.image ? (
										<Button
											fullWidth
											variant="outlined"
											aria-label="Clear"
											onClick={() => {
												updateMessage('image', null)
												setPreview(false)
											}}
											startIcon={<ReplayIcon />}
										>
											Remove Image
										</Button>
									) : null}

									{limited ? (
										<div
											style={{
												fontSize: '12px',
												color: '#c0c0c0',
												marginTop: '8px',
											}}
										>
											{props.user.package === 0
												? 'Free accounts cannot update their listing.'
												: 'Cannot update listing yet. Basic accounts can only update their listing once every 24 hours.'}
										</div>
									) : null}

									{message.image && !message.urlonly && !message.textonly ? (
										<div style={{ marginBotom: '8px', marginTop: '8px' }}>
											<img
												key={message.image}
												alt="main"
												src={'/remote/api/image/' + message.image}
												style={{
													maxWidth: '100%',
												}}
											/>
										</div>
									) : null}

									{message.textonly && message.textimage ? (
										<div style={{ marginBotom: '8px', marginTop: '8px' }}>
											<img
												key={message.textimage}
												alt="main"
												src={'/remote/api/image/' + message.textimage}
												style={{
													maxWidth: '100%',
												}}
											/>
											<Button
												fullWidth
												variant="outlined"
												aria-label="Clear"
												onClick={() => {
													updateMessage('textimage', null)
													setPreview(false)
												}}
												startIcon={<ReplayIcon />}
											>
												Remove Image
											</Button>
										</div>
									) : null}

									{message.urlonly && message.urlbody ? (
										<div style={{ marginBotom: '8px', marginTop: '8px' }}>
											<img
												key={message.urlbody}
												alt="main"
												src={message.urlbody}
												style={{
													maxWidth: '100%',
												}}
											/>
										</div>
									) : null}
								</Box>

								{props.user.package < 0 ? null : (
									<Grid container>
										<Grid item xs={12} md={6}>
											<FormControlLabel
												style={{
													marginLeft: '0px',
													marginTop: '22px',
													alignContent: 'center',
													alignItems: 'center',
													justifyContent: 'center',
												}}
												control={
													<Switch
														color="primary"
														checked={
															message.notify
																? !notifyLimited
																	? true
																	: false
																: false
														}
														onChange={() => {
															updateMessage('notify', !message.notify)
														}}
														value="checked"
														inputProps={{ 'aria-label': 'Send Notification?' }}
														className={classes.switch}
														disabled={notifyLimited}
													/>
												}
												labelPlacement="end"
												label={'Send Notification?'}
											/>
										</Grid>

										<Grid item xs={12} md={6}>
											{message.notify &&
											props.user.package > 1 &&
											!notifyLimited ? (
												<FormControlLabel
													style={{
														marginLeft: '0px',
														marginTop: '10px',
														alignContent: 'center',
														alignItems: 'center',
														justifyContent: 'center',
													}}
													control={
														<Switch
															color="primary"
															checked={
																message.notifyImage
																	? notifyLimited
																		? false
																		: true
																	: false
															}
															onChange={() => {
																updateMessage(
																	'notifyImage',
																	!message.notifyImage
																)
															}}
															value="checked"
															inputProps={{
																'aria-label': 'Include image?',
															}}
															className={classes.switch}
															disabled={notifyLimited}
														/>
													}
													labelPlacement="end"
													label={'Include image?'}
												/>
											) : null}
										</Grid>
									</Grid>
								)}

								{notifyLimited ? (
									<div style={{ fontSize: '12px', color: '#c0c0c0' }}>
										{props.user.package === 0
											? 'Free accounts cannot send notifiications.'
											: 'Cannot send another notification yet. Basic accounts can only send 1 notification every 24 hours.'}
									</div>
								) : null}

								{message.notify && props.user.package > 1 && !notifyLimited ? (
									<React.Fragment>
										<TextField
											style={{ borderRadius: '0px', backgroundColor: props.theme === 'light' ? '#fff' : '#000' }}
											variant="outlined"
											margin="normal"
											fullWidth
											id="body"
											placeholder="Custom notification (optional)"
											name="body"
											disabled={
												saved && message && disabled
													? true
													: notifyLimited
													? true
													: false
											}
											//defaultValue={message.body ? message.body : default_body}
											value={use_body}
											onChange={(e) => {
												updateMessage('body', e.target.value)
											}}
											multiline={true}
											rows={8}
										/>

										<Grid container spacing={1}>
											<Grid item xs={6}>
												{body_count} character{body_count !== 1 ? 's' : null}
											</Grid>
											<Grid item xs={6} style={{ textAlign: 'right' }}>
												Counts as {counts} message{counts !== 1 ? 's' : null}
											</Grid>
										</Grid>

										<Grid item container spacing={0}>
											<Button
												fullWidth
												variant="outlined"
												aria-label="Clear"
												onClick={() => {
													if (shorten) {
														setLong('')
														setShort('')
													}

													setShorten(!shorten)
												}}
												startIcon={<LinkIcon />}
												style={{ marginTop: '8px', marginBottom: '8px' }}
											>
												{shorten
													? 'Hide Quick Link Shortener'
													: 'Show Quick Link Shortener'}
											</Button>
										</Grid>

										{shorten ? (
											<Grid container spacing={1} alignItems="center">
												<Grid item xs={12} sm={12} md={9}>
													<TextField
														fullWidth
														variant="outlined"
														size="small"
														value={long_link}
														placeholder="original URL"
														onChange={(e) => {
															setLong(e.target.value)
															setShort('')
														}}
													/>
												</Grid>
												<Grid item xs={12} sm={12} md={3}>
													<Button
														fullWidth
														variant="outlined"
														aria-label="Clear"
														onClick={() => {
															shortenLink(long_link)
														}}
													>
														shorten
													</Button>
												</Grid>
												<Grid item xs={12} sm={12} md={12}>
													{short_link ? (
														<TextField
															fullWidth
															variant="outlined"
															size="small"
															value={short_link}
															placeholder="shortened URL"
															id="short"
															onFocus={() => {}}
														/>
													) : null}
												</Grid>
											</Grid>
										) : null}
									</React.Fragment>
								) : null}

								<Box pt={1}>
									{
										<Groups
											disabled={saved && message && disabled ? true : false}
											updateField={updateGroups}
											isChecked={isChecked}
											changeChecked={changeChecked}
										/>
									}
								</Box>

								{message.image ? (
									<Button
										type="button"
										fullWidth
										variant="outlined"
										color="primary"
										className={classes.submit}
										startIcon={<Visibility />}
										onClick={() => {
											setPreview(!preview)
										}}
									>
										{preview ? 'Hide' : 'Show'} Preview
									</Button>
								) : null}

{message.image && preview ? (
							<div style={{ marginTop: '16px' }}>
								<Current
									image={'/remote/api/image/' + message.image}
								/>
							</div>
						) : null}

								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
									disabled={saved ? true : false}
								>
									Save Update
								</Button>

							</FormControl>
						</form>
					</React.Fragment>
				) : null}
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	message: state.message,
	baseurl: state.baseurl,
	temp: state.temp,
	theme: state.theme,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setMessage: (message) => dispatch(setMessage(message)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
