import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'

import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import { setMessage } from '../../../actions/messages'

import { Grid } from '@material-ui/core'

import {
	InfoOutlined,
	Cached,
	Settings,
	Edit as EditGear,
} from '@material-ui/icons'

//const BUCKET_NAME = 'resources.greenlightalerts.com'

function Edit(props) {
	const [headerBackground, setHeaderBackground] = React.useState(
		props.user.header_background
	)

	const [background, setBackground] = React.useState(
		props.image ? props.image : props.user.current_image
	)

	React.useEffect(() => {
		if (props.headerBackground) {
			setHeaderBackground(props.headerBackground)
		}
	}, [props.headerBackground])

	React.useEffect(() => {
		setHeaderBackground(props.user.header_background)
	}, [props.user])

	React.useEffect(() => {
		if (props.image) {
			setBackground(props.image)
		}
	}, [props.image])

	var colors = {
		brand: '#4cbb17',
		sub: '#c3ebb0',
		error_color: '#fff',
		error_background: '#a10000',
	}

	//var header_background = '#4cbb17'
	var header_border = '#ffffff'
	var header_text = '#ffffff'
	var sub_header = colors.sub
	var icon_color = colors.sub
	//var info_background = '#ffffff'
	//var info_border = '#ffffff'

	if (headerBackground) {
		//header_background = headerBackground

		if (headerBackground === '#ffffff') {
			header_border = '#4cbb17'
			header_text = '#000000'
			sub_header = '#5b5b5b'
			icon_color = '#4cbb17'
			//info_background = '#ffffff'
			//info_border = '#4cbb17'
		} else if (headerBackground === '#1d1d1d') {
			header_border = '#ffffff'
			header_text = '#ffffff'
			sub_header = '#9b9b9b'
			icon_color = '#4cbb17'
			//info_background = '#1d1d1d'
			//info_border = '#ffffff'
		} else if (headerBackground === '#4cbb17') {
			header_border = '#ffffff'
			header_text = '#ffffff'
			sub_header = colors.sub
			icon_color = colors.sub
			//info_background = '#ffffff'
			//info_border = '#4cbb17'
		}
	}

	if (props.image) {
		//var background_iamge = props.image
	} else {
		//var background_image = props.user.current_image
	}

	var display_logo = props.logoUpload ? props.logoUpload : props.user.logo

	return (
		<React.Fragment>
			<CssBaseline />
			{props.user.current_image ? (
				<Grid
					container
					justifyContent="center"
					alignContent="center"
					alignItems="center"
					style={{
						width: '328px',
						height: '676px',

						backgroundImage: 'url("/assets/frame.png")',
						backgroundSize: 'contain',
						backgroundRepeat: 'no-repeat',
						marginLeft: 'auto',
						marginRight: 'auto',
						position: 'relative',
					}}
				>
					<div
						style={{
							backgroundColor: headerBackground,
							height: '105px',
							position: 'absolute',
							top: '81px',
							textAlign: 'left',
							marginLeft: '0px',
							left: '22px',
							width: '284px',
							borderBottom: '1px solid ' + header_border,
						}}
					>
						<Grid container>
							<Grid
								item
								container
								xs={display_logo ? 8 : 12}
								style={{ padding: '8px', flexDirection: 'row' }}
							>
								<Grid
									item
									xs={12}
									style={{
										fontWeight: 'bold',
										fontSize: '12px',
										color: header_text,
									}}
									container
									alignItems="center"
									alignContent="center"
								>
									<span>
										{props.user.nickname.substr(0, 24)}
										{props.user.nickname.length > 24 ? '...' : null}
									</span>{' '}
									<InfoOutlined
										style={{
											fontSize: '10px',
											fontWeight: 'bold',
											marginLeft: 8,
											color: icon_color,
										}}
									/>
								</Grid>
								<Grid
									item
									container
									xs={12}
									style={{
										color: sub_header,
										alignItems: 'center',
										justifyContent: 'left',
										fontSize: '10px',
										marginTop: '2px',
										//height: '14px',
									}}
								>
									{props.user.address1} {props.user.address2}
								</Grid>
								<Grid
									item
									container
									xs={12}
									style={{
										color: sub_header,
										alignItems: 'center',
										justifyContent: 'left',
										fontSize: '10px',
										//height: '14px',
									}}
								>
									{props.user.city}
									{props.user.city && props.user.state ? ', ' : null}
									{props.user.state} {props.user.zipcode}
								</Grid>
								<Grid
									item
									container
									xs={12}
									style={{
										color: sub_header,
										alignItems: 'center',
										justifyContent: 'left',
										fontSize: '10px',
										//height: '14px',
									}}
								>
									{props.user.contactPhone}
								</Grid>
							</Grid>
							{display_logo && !props.removed ? (
								<Grid
									container
									item
									xs={4}
									style={{ height: '105px', textAlign: 'right' }}
									alignItems="center"
									alignContent="center"
									justifyContent="flex-end"
								>
									<img
										key={display_logo}
										alt="main"
										src={'/remote/api/image/' + display_logo}
										style={{
											maxWidth: '80px',

											maxHeight: '80px',
											marginRight: '8px',
										}}
									/>
								</Grid>
							) : (
								<Grid
									container
									item
									xs={4}
									style={{ height: '105px', textAlign: 'right' }}
									alignItems="center"
									alignContent="center"
									justifyContent="flex-end"
								></Grid>
							)}
						</Grid>
					</div>

					<div
						style={{
							width: '284px',
							height: '320px',
							position: 'absolute',
							top: '186px',
							left: '22px',
							backgroundColor: '#000',
							backgroundImage: 'url("' + background + '")',
							backgroundPosition: 'center',
							backgroundSize: props.cover_style
								? props.cover_style
								: props.user.cover_style
								? props.user.cover_style
								: 'contain',
							backgroundRepeat: 'no-repeat',
						}}
					></div>

					<div
						style={{
							backgroundColor: '#fff',
							height: '20px',
							position: 'absolute',
							top: '506px',
							textAlign: 'center',
							left: '22px',
							width: '284px',
							color: '#4cbb17',
							fontSize: '12px',
						}}
					>
						<Grid
							container
							alignContent="center"
							alignItems="center"
							justifyContent="center"
							direction="column"
							style={{ height: '20px' }}
						>
							swipe to browse
						</Grid>
					</div>

					<div
						style={{
							backgroundColor: '#4cbb17',
							height: '60px',
							position: 'absolute',
							top: '526px',
							textAlign: 'left',
							left: '22px',
							width: '284px',
						}}
					>
						<Grid
							container
							style={{ height: '60px' }}
							alignContent="center"
							alignItems="center"
							justifyContent="center"
						>
							<Grid
								item
								xs={3}
								alignContent="center"
								alignItems="center"
								justifyContent="center"
								direction="column"
								container
							>
								<Cached
									style={{
										fontSize: 24,

										color: '#fff',
									}}
								/>
								<div style={{ fontSize: '10px', color: '#fff' }}>My Shops</div>
							</Grid>
							<Grid
								item
								xs={6}
								alignContent="center"
								alignItems="center"
								justifyContent="center"
								direction="column"
								container
							>
								<EditGear
									style={{
										fontSize: 24,

										color: '#fff',
									}}
								/>
								<div style={{ fontSize: '10px', color: '#fff' }}>
									Add / Manage Shops
								</div>
							</Grid>
							<Grid
								item
								xs={3}
								alignContent="center"
								alignItems="center"
								justifyContent="center"
								direction="column"
								container
							>
								<Settings
									style={{
										fontSize: 24,

										color: '#fff',
									}}
								/>

								<div style={{ fontSize: '10px', color: '#fff' }}>Settings</div>
							</Grid>
						</Grid>
					</div>
				</Grid>
			) : null}
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	message: state.message,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setMessage: (message) => dispatch(setMessage(message)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
