import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { setUser, setAlert } from '../../../actions'
import { setMessage } from '../../../actions/messages'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	formControl: {
		marginTop: theme.spacing(1),
		minWidth: 120,
		width: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))

function Status(props) {
	const classes = useStyles()
	const [message, setMessage] = React.useState(props.message)

	React.useEffect(() => {
		setMessage(props.message)
	}, [props.message])

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth)
	}, [])

	const handleChange = (e) => {
		props.updateMessage('status', e.target.value)
	}

	const more_options = [
		<MenuItem value={'reply'} key='reply' disabled>
			Reply
		</MenuItem>,
		<MenuItem value={'auto'} disabled key='auto'>
			Auto Response
		</MenuItem>,
		<MenuItem value={'inbound'} disabled key='inbound'>
			Inbound
		</MenuItem>,
		<MenuItem value={'stop'} disabled key='stop'>
			Stop
		</MenuItem>,
	]

	return (
		<div>
			<FormControl variant="filled" className={classes.formControl}>
				<InputLabel ref={inputLabel}>Status</InputLabel>
				<Select
					fullWidth
					disabled={props.disabled}
					value={message.status ? message.status : ''}
					onChange={handleChange}
					labelWidth={labelWidth}
				>
					<MenuItem value={'draft'} key='draft'>Draft</MenuItem>
					<MenuItem value={'publish'} key='publish'>Publish</MenuItem>
					<MenuItem value={'queued'} key='queued'>Queued (send immediately)</MenuItem>
					<MenuItem value={'scheduled'} key='scheduled'>Scheduled</MenuItem>
					<MenuItem value={'deleted'} key='deleted'>Deleted</MenuItem>
					<MenuItem value={'paused'} key='paused'>Paused</MenuItem>
					<MenuItem value={'sent'} key='sent' disabled>
						Sent
					</MenuItem>

					{props.user.client_code !== 'green' ? more_options : null}
				</Select>
			</FormControl>
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,

	groups: state.groups,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setMessage: (message) => dispatch(setMessage(message)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Status)
