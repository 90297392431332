import { history } from '../index';

const Director = (location,replace) => {
		var push = true

		if (push === true) {
			
			if (replace) {
				history.replace(location)
			} else {
				history.push(location)
			}

		} else {
			history.replace(location)
		}		
    }
    
export default Director