import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Grid, Switch, FormControlLabel } from '@material-ui/core'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import axios from 'axios'
import Director from '../../Director'
import Groups from './Groups'
import Well from '../../ui/Well'
import {
	CheckCircleOutline as CheckIcon,
	HighlightOff as BadIcon,
	ImportContacts,
} from '@material-ui/icons'
import Columns from './Columns'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function SignUp(props) {
	const classes = useStyles()

	const [contacts, setContacts] = React.useState('')
	const [groups, setGroups] = React.useState([])
	const [columns, setColumns] = React.useState([
		'phone_number',
		'email',
		'name',
		'status',
		'date_added'
	])
	const [ignoreFirstRow, setIgnoreFirstRow] = React.useState(false)

	var changeGroups = (val) => {
		setGroups([...val])

		//setGroups(JSON.parse(JSON.stringify(val)))
	}

	var setColumn = (index,value) => {

		var cols = columns.slice()

		cols.forEach((c,i) => {


			if (c === value) {
				cols[i] = cols[index].toString()
			}

		})

		cols[index] = value

		setColumns(cols)
		
	}

	var changeChecked = (_id, name) => {
		var found = false
		var foundIndex = 0

		if (groups) {
			groups.forEach((v, i) => {
				if (v._id.toString() === _id) {
					found = true
					foundIndex = i
				}
			})
		}

		if (found) {
			groups.splice(foundIndex, 1)
			return groups
		} else if (groups) {
			var new_groups = Object.assign([], groups)

			new_groups.push({
				_id: _id,
				name: name,
			})

			return new_groups
		} else {
			new_groups = []

			new_groups.push({
				_id: _id,
				name: name,
			})

			return new_groups
		}
	}

	var isChecked = (_id) => {
		var found = false

		if (groups) {
			groups.forEach((v, i) => {
				if (v._id.toString() === _id) {
					found = true
				}
			})
		}

		if (found) {
			return true
		} else {
			return false
		}
	}

	var submitSignUp = (e) => {
		e.preventDefault()

		axios
			.post(props.baseurl + 'remote/contacts/import', {
				contacts: contacts,
				groups: groups,
				token: localStorage.getItem('token'),
				columns: columns,
				ignoreFirstRow: ignoreFirstRow
			})
			.then((result) => {
				if (result.data) {
					props.setAlert({
						open: true,
						severity: 'success',
						message: 'Contacts Successfully Imported',
					})

					Director('/contacts')
				} else {
					props.setAlert({
						open: true,
						severity: 'error',
						message: 'Error',
					})
				}
			})
			.catch((err) => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: 'Error',
				})
			})
	}

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					<ImportContacts
						fontSize="large"
						color="primary"
						style={{ marginRight: '8px' }}
					/>

					<Typography component="h1" variant="h5">
						Import Contacts
					</Typography>
				</Grid>

				<form className={classes.form} noValidate onSubmit={submitSignUp}>
					
					<TextField
						margin="normal"
						fullWidth
						label="Type or paste contacts here. Each contact must be on a separate line."
						onChange={(e) => {
							setContacts(e.target.value)
						}}
						value={contacts}
						color="primary"
						multiline={true}
						rows={10}
						variant="outlined"
					/>
					{contacts.length ? (
						<FormControlLabel
						style={{marginLeft: '0px'}}
							control={
								<Switch
									color="primary"
									checked={ignoreFirstRow}
									onChange={()=>{
									setIgnoreFirstRow(!ignoreFirstRow)	
									}}
									value="checked"
									inputProps={{ 'aria-label': 'ignore first row' }}
									className={classes.switch}
								/>
							}
							labelPlacement="start"
							label={'ignore first row'}
						/>
					) : null}
					{contacts.length ? (
						<Columns contacts={contacts} columns={columns} setColumns={setColumns} ignoreFirstRow={ignoreFirstRow} setColumn={setColumn} />
					) : null}
					{
						<Groups
							updateField={changeGroups}
							isChecked={isChecked}
							changeChecked={changeChecked}
						/>
					}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Import Contacts
					</Button>
					<div style={{ width: '100%', textAlign: 'left', marginTop: '8px' }}>
						<Well
							title={
								<Grid
									container
									justifyContent="center"
									direction="row"
									alignItems="center"
									style={{ marginLeft: 'auto', marginRight: 'auto' }}
								>
									<CheckIcon
										fontSize="large"
										color="primary"
										style={{ marginRight: '8px', color: '#70ab1f' }}
									/>

									<Typography variant="h5" component="p">
										Correct Formats
									</Typography>
								</Grid>
							}
							text={
								<Grid container spacing={2} style={{ marginTop: '8px' }}>
									<Grid item xs={12} sm={12}>
										<Typography variant="caption">
											5559991234
											<br />
											5557774321
											<br />
											5553334567
											<br />
											5554445432
											<br />
											5551111234
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Typography variant="h6" style={{ textAlign: 'center' }}>
											or
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Typography variant="caption">
											5559991234,email@gmail.com,Joe Smith,active
											<br />
											+15557774321
											<br />
											5553334567,contact@{process.env.REACT_APP_DOMAIN},,stop
											<br />
											5554445432
											<br />
											15551111234,,,stop
										</Typography>
									</Grid>
								</Grid>
							}
						/>
					</div>
					<div
						style={{ width: '100%', textAlign: 'left', marginTop: '8px' }}
					></div>
					<Well
						title={
							<Grid
								container
								justifyContent="center"
								direction="row"
								alignItems="center"
								style={{ marginLeft: 'auto', marginRight: 'auto' }}
							>
								<BadIcon
									fontSize="large"
									color="primary"
									style={{ marginRight: '8px', color: '#ff0000' }}
								/>

								<Typography variant="h5" component="p">
									Incorrect Formats
								</Typography>
							</Grid>
						}
						text={
							<Grid container spacing={2} style={{ marginTop: '8px' }}>
								<Grid item xs={12} sm={12}>
									<Typography variant="caption">
										5559991234, 5557774321, 5553334567,5554445432,5551111234
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12}>
									<Typography variant="h6" style={{ textAlign: 'center' }}>
										or
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12}>
									<Typography variant="caption">
										(555) 999-1234
										<br />
										555-777-4321
										<br />
										555-3334567
										<br />
										1-555-444-5432
										<br />
										5551111234,'email@gmail.com','','',''
									</Typography>
								</Grid>
							</Grid>
						}
					/>
				</form>
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
