import React, { useEffect, useRef } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Button, Grid, TextField } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import { setAlert, setUser, setTemp, setCheckout } from '../../actions'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
//import Options from './choices/Options'
//import Previews from './choices/Previews'
import Steps from './choices/Steps'
import PaymentMethods from './choices/PaymentMethods'
import ContinueIcon from '@material-ui/icons/ArrowForwardTwoTone'
import Plans from './Plans'
import Director from 'components/Director'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// uncomment to use Stripe Checkout
/*
import { loadStripe } from '@stripe/stripe-js'
var stripePromise = null
if (window.location.href.includes(process.env.REACT_APP_LIVE_DOMAIN_CHECK)) {
	stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_LIVE);
} else {
	stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_TEST);
}
*/
var stripePromise = null

const useStyles = withStyles(
	(theme) => ({
		paper: {
			marginTop: theme.spacing(8),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.primary.main,
		},
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		submit: {
			margin: theme.spacing(3, 0, 2),
		},
		panel: {
			width: '100%',
		},
		formControl: {
			marginTop: theme.spacing(1),
			minWidth: 120,
		},
	}),
	{ withTheme: true }
)

function Choices(props) {
	const classes = props.classes

	const matches = props.theme.breakpoints.down('sm')

	var isSmall = useMediaQuery(matches)

	var user_choice =
		props.user && props.user.package > 0 ? props.user.package + 1 : 2

	if (user_choice > 3) {
		user_choice = 2
	}

	const [choice, setChoice] = React.useState(user_choice)

	//const [promo, setPromo] = React.useState(null)

	const [code, setCode] = React.useState('')

	const [prorate, setProrate] = React.useState(0)
	const [prorateObj, setProrateObj] = React.useState({})

	const [prorating, setProrating] = React.useState(false)
	const [submitting, setSubmitting] = React.useState(false)

	//const [amount, setAmount] = React.useState(250)

	const [step, setStep] = React.useState(0)

	const [userAccount, setUserAccount] = React.useState({
		per_sms: 0,
		per_mms: 0,
		per_combo: 0,
		choice: '',
	})

	const loaded = useRef(false)

	useEffect(() => {
		if (props.user) {
			load()
		}
		// eslint-disable-next-line
	}, [])

	React.useEffect(() => {
		if (props.user) {
			setUserAccount(props.user)
		}
	}, [props.user])
	var use_choice = props.temp.tempChoice ? props.temp.tempChoice : choice
	React.useEffect(() => {
		if (props.user && props.user.package > 0 && props.user.subscription) {
			setProrating(true)

			axios
				.post(props.baseurl + 'remote/payments/prorate', {
					token: props.user.token,
					id: props.user.subscription,
					customer: props.user.customer_token,
					choice: use_choice,
				})
				.then((result) => {
					setProrate(result.data.total)
					setProrateObj(result.data)
					setProrating(false)
				})
		}
	}, [choice, props.user, props.baseurl, use_choice])

	var load = () => {
		axios
			.post(props.baseurl + 'remote/user/data', {
				token: props.user.token,
				account: true,
				_id: props.match.params.contactID,
			})
			.then((result) => {
				props.setUser(result.data)
				loaded.current = true
			})
	}

	var confirmSubscribe = () => {
		setSubmitting(true)

		axios
			.post(props.baseurl + 'remote/payments/subscribe', {
				token: props.user.token,
				customer_token: props.user.customer_token,
				card_token: props.temp.paymentMethod,
				choice: use_choice,
				code: props.temp.promo ? props.temp.promo.code : null,
				promotion_code: props.temp.promo
					? props.temp.promo.promotion_code
					: null,
			})
			.then((result) => {
				setSubmitting(false)
				if (result.data.charge.status === 'succeeded') {
					if (!result.data.account_data) {
						result.data.user.account_data = props.user.account_data
					}

					props.setUser(result.data.user)

					var temp = Object.assign({}, props.temp, {
						charge_card: result.data.charge,
						promo: null,
					})

					props.setTemp(temp)
					setStep(3)
				}
			})
			.catch((err) => {
				setSubmitting(false)
			})
	}

	var addPromo = () => {
		axios
			.post(props.baseurl + 'remote/payments/promo', { code: code })
			.then((res) => {
				if (
					res.data.promo &&
					(!prorateObj.promotion_code ||
						prorateObj.promotion_code !== res.data.promo.promotion_code)
				) {
					var temp = Object.assign({}, props.temp, {
						promo: {
							code: code,
							type: res.data.promo.type,
							amount: res.data.promo.amount,
							promotion_code: res.data.promo.promotion_code,
						},
					})

					props.setTemp(temp)

					setCode('')
				} else {
					setCode('')
					props.setAlert({
						open: true,
						severity: 'error',
						message: `Promo not valid`,
					})
				}
			})
	}

	var confirmUpgrade = () => {
		setSubmitting(true)
		axios
			.post(props.baseurl + 'remote/payments/upgrade', {
				token: props.user.token,
				customer_token: props.user.customer_token,
				card_token: props.temp.paymentMethod,
				choice: use_choice,
				id: props.user.subscription,
				code: props.temp.promo ? props.temp.promo.code : null,
				promotion_code: props.temp.promo
					? props.temp.promo.promotion_code
					: null,
			})
			.then((result) => {
				setSubmitting(false)
				if (result.data.charge.status === 'succeeded') {
					props.setUser(result.data.user)

					var temp = Object.assign({}, props.temp, {
						charge_card: result.data.charge,
						promo: null,
					})

					props.setTemp(temp)
					setStep(3)
				}
			})
			.catch((err) => {
				setSubmitting(false)
			})
	}

	var confirmPayment = () => {
		axios
			.post(props.baseurl + 'remote/payments/charge', {
				token: props.user.token,
				customer_token: props.user.customer_token,
				card_token: props.temp.paymentMethod,
				choice: use_choice,
			})
			.then((result) => {
				if (result.data.charge.status === 'succeeded') {
					if (!result.data.account_data) {
						result.data.user.account_data = props.user.account_data
					}

					props.setUser(result.data.user)

					var temp = Object.assign({}, props.temp, {
						charge_card: result.data.charge,
					})

					props.setTemp(temp)
					setStep(3)
				}
			})
	}

	var steps = []

	steps[0] = (
		<React.Fragment>
			<Container maxWidth="md" style={{ padding: isSmall ? 0 : 0 }}>
				{/*<Box pt={1} width="100%">
					<Options choice={choice} setChoice={setChoice} />
				</Box>/*}

				{/*}
			<Box pt={1}>
				<Previews userAccount={userAccount} choice={choice} />
			</Box>
	*/}

				{
					<React.Fragment>
						<Grid
							container
							style={{
								alignItems: 'center',
								alignContent: 'center',
								justifyContent: 'center',
								marginTop: '8px',
								flexDirection: 'row',
							}}
						>
							<Grid
								container
								alignItems="center"
								spacing={1}
								item
								xs={12}
								md={6}
							>
								<Grid item xs={6}>
									<TextField
										fullWidth
										size="small"
										variant="outlined"
										placeholder="Promo Code"
										value={code}
										onChange={(e) => {
											setCode(e.target.value)
										}}
										onKeyPress={(e) => {
											if (e.charCode === 13) {
												addPromo()
											}
										}}
									/>
								</Grid>

								<Grid item xs={6}>
									<Button
										fullWidth
										variant="outlined"
										color="primary"
										onClick={() => {
											addPromo()
										}}
									>
										{isSmall ? 'Apply' : 'Apply Promo'}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</React.Fragment>
				}

				{
					<Box pt={1}>
						<Button
							variant="contained"
							fullWidth
							color="primary"
							component={Link}
							to="/subscribe"
							onClick={() => {
								setStep(1)
							}}
							disabled={!choice || choice < 1 || prorating}
						>
							Continue To Payment
						</Button>
					</Box>
				}

				{stripePromise ? (
					<Box pt={1}>
						<Button
							variant="contained"
							fullWidth
							color="primary"
							onClick={async () => {
								var res = await axios.post(
									props.baseurl + 'remote/payments/checkout_payment',
									{
										token: props.user.token,
										choice: use_choice,
									}
								)

								//setClientSecret(res.data.client_secret)
								setCheckout(res.data.session_id)

								if (res.data.customer_id) {
									var u = Object.assign({}, props.user, {
										customer_token: res.data.customer_id,
									})

									props.setUser(u)

									const stripe = await stripePromise

									stripe
										.redirectToCheckout({
											// Make the id field from the Checkout Session creation API response
											// available to this file, so you can provide it as argument here
											// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
											sessionId: res.data.session_id,
										})
										.then(function (result) {
											// If `redirectToCheckout` fails due to a browser or network
											// error, display the localized error message to your customer
											// using `result.error.message`.
										})
								}
							}}
						>
							Continue To Checkout
						</Button>
					</Box>
				) : null}

				{/* save card via Stripe Checkout for future use then return to select that saved card 
			<Box pt={1}>
				<Button
					variant="contained"
					fullWidth
					color="primary"
					onClick={async () => {
						var res = await axios.post(
							props.baseurl + 'remote/payments/checkout_payment',
							{
								token: props.user.token,
								choice: choice,
							}
						)

						//setClientSecret(res.data.client_secret)
						setCheckout(res.data.session_id)

						if (res.data.customer_id) {
							var u = Object.assign({}, props.user, {
								customer_token: res.data.customer_id,
							})

							props.setUser(u)

							const stripe = await stripePromise

							stripe
								.redirectToCheckout({
									// Make the id field from the Checkout Session creation API response
									// available to this file, so you can provide it as argument here
									// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
									sessionId: res.data.session_id,
								})
								.then(function (result) {
									// If `redirectToCheckout` fails due to a browser or network
									// error, display the localized error message to your customer
									// using `result.error.message`.
								})
						}
					}}
				>
					Continue To Payment
				</Button>
			</Box>
				*/}
			</Container>
		</React.Fragment>
	)

	steps[1] = (
		<Box pt={1} width="100%">
			<PaymentMethods
				prorating={prorating}
				userAccount={userAccount}
				choice={use_choice}
				setStep={setStep}
				confirmPayment={confirmPayment}
			/>
		</Box>
	)

	if (use_choice === '') {
		var total = 0
	}

	if (use_choice === 0) {
		total = 0
	}

	if (use_choice === 1) {
		total = 250
	}

	if (use_choice === 2) {
		if (props.user && props.user.package === 1) {
			total = 250
		} else {
			total = 500
		}
	}

	if (use_choice === 3) {
		if (props.user && props.user.package === 1) {
			total = 750
		} else if (props.user && props.user.package === 2) {
			total = 500
		} else {
			total = 1000
		}
	}

	var package_details = {}
	switch (choice) {
		case 0:
			package_details.title = 'Free'
			break
		case 1:
			package_details.title = 'Basic'
			break
		case 2:
			package_details.title = 'Plus'
			break
		case 3:
			package_details.title = 'Pro'
			break
		default:
			package_details.title = 'Free'
			break
	}

	var pr_amount = null

	if (prorate && prorate !== null) {
		if (prorate > 0) {
			pr_amount = prorate.toFixed(2)
		} else {
			pr_amount = 0
		}
	} else {
		if (use_choice === 1) {
			pr_amount = 250
		}

		if (use_choice === 2) {
			pr_amount = 500
		}

		if (use_choice === 3) {
			pr_amount = 1000
		}
	}

	if (props.temp.promo) {
		var promo_amount = props.temp.promo.amount * pr_amount
	}

	steps[2] = (
		<React.Fragment>
			<Box pt={1} width="100%">
				<Grid container className={classes.formControl}>
					<Grid item xs={6}>
						Subscription:
					</Grid>
					<Grid item xs={6}>
						{package_details.title}
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={6}>
						Total:
					</Grid>
					<Grid
						item
						xs={6}
						style={{
							textDecoration: promo_amount ? 'line-through' : 'none',
						}}
					>
						$
						{prorate
							? prorate > 0
								? prorate.toFixed(2) + ' (prorated)'
								: 0 + ' (downgrade takes effect at end of billing cycle)'
							: total.toFixed(2)}
					</Grid>
				</Grid>
				{promo_amount ? (
					<Grid container>
						<Grid item xs={6}>
							With promo ({props.temp.promo.code.toUpperCase()}):
						</Grid>
						<Grid item xs={6}>
							${(promo_amount / 100).toFixed(2)}
						</Grid>
					</Grid>
				) : null}
			</Box>

			<Box pt={2}>
				<Button
					variant="contained"
					fullWidth
					disabled={prorating || submitting}
					color="primary"
					onClick={() => {
						if (prorate) {
							confirmUpgrade()
						} else {
							confirmSubscribe()
						}
						//confirmPayment()
					}}
				>
					{submitting
						? 'Submitting...'
						: prorate
						? prorate > 0
							? 'Pay Now & Upgrade'
							: 'Downgrade Subscription'
						: 'Pay Now & Subscribe'}
				</Button>
			</Box>
		</React.Fragment>
	)

	steps[3] = (
		<Box pt={1}>
			<Typography variant="h6" component="p">
				Payment Successful!
			</Typography>
			<Typography variant="subtitle1" component="p">
				Package: {package_details.title}
			</Typography>
			<Typography variant="subtitle1" component="p">
				Payment Details:
			</Typography>

			{props.temp.charge_card ? (
				<React.Fragment>
					{' '}
					<Typography variant="subtitle2" component="p">
						Card: {props.temp.charge_card.brand} {props.temp.charge_card.last4}
					</Typography>
					<Typography variant="subtitle2" component="p">
						Receipt ID: {props.temp.charge_card.id}
					</Typography>
				</React.Fragment>
			) : null}

			<Box pt={1}>
				<Button
					variant="contained"
					fullWidth
					color="primary"
					component={Link}
					to="/account"
				>
					Go To Account
				</Button>
			</Box>
		</Box>
	)

	return (
		userAccount && (
			<Container component="main">
				<CssBaseline />
				<div className={`${classes.paper} top-paper`}>
					<Grid
						container
						justifyContent="center"
						direction="row"
						alignItems="center"
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<LockOutlinedIcon
							fontSize="large"
							color="primary"
							style={{ marginRight: '8px' }}
						/>

						<Typography component="h1" variant="h5">
							Choose Subscription
						</Typography>
					</Grid>

					{props.onboard ? (
						<Box pt={2} width="100%">
							<div style={{ textAlign: 'center' }}>
								<Button
									variant="outlined"
									color="secondary"
									onClick={() => {
										Director('/')
									}}
									endIcon={<ContinueIcon />}
								>
									Skip & Continue to Dashboard
								</Button>
							</div>
						</Box>
					) : null}

					{step < 3 ? (
						<Box pt={1} width="100%">
							<Plans
								choice={props.temp.tempChoice ? props.temp.tempChoice : choice}
								setChoice={setChoice}
							/>
						</Box>
					) : null}

					{(prorate || (props.user && props.user.package > 0)) && step < 3 ? (
						<Box pt={1} width="100%">
							<Container maxWidth="md">
								<Grid container>
									<Grid item xs={12}>
										Due today:{' '}
										<span
											style={{
												textDecoration: promo_amount ? 'line-through' : 'none',
											}}
										>
											{prorate || prorate !== null ? (
												prorating ? (
													'Calculating prorated amount'
												) : (
													<React.Fragment>
														$
														{prorate
															? prorate > 0
																? prorate.toFixed(2) + ' (prorated)'
																: 0 +
																  ' (downgrade takes effect at end of billing cycle)'
															: total.toFixed(2)}
													</React.Fragment>
												)
											) : props.user.subscription &&
											  props.user.package > 0 &&
											  !prorate &&
											  prorate !== 0 ? (
												'Calculating prorated amount'
											) : choice === 3 ? (
												'$1,000'
											) : choice === 2 ? (
												'$500'
											) : (
												'$250'
											)}
										</span>
									</Grid>
									{promo_amount ? (
										<Grid item xs={12}>
											With promo ({props.temp.promo.code.toUpperCase()}): $
											{(promo_amount / 100).toFixed(2)}
										</Grid>
									) : null}
								</Grid>
							</Container>
						</Box>
					) : null}
					<Box pt={1} width="100%">
						<Container maxWidth="md">
							<Steps
								choice={choice}
								setChoice={setChoice}
								steps={steps}
								step={step}
							/>
						</Container>
					</Box>

					{step > 0 && step < 3 ? (
						<Box pt={1} width="100%">
							<Container maxWidth="md" style={{ textAlign: 'center' }}>
								<Button
									style={{ width: '50%' }}
									variant="contained"
									color="secondary"
									onClick={() => {
										setStep((step) => step - 1)
									}}
								>
									Back
								</Button>
							</Container>
						</Box>
					) : null}
				</div>
			</Container>
		)
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	temp: state.temp,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
	setUser: (user) => dispatch(setUser(user)),
	setTemp: (temp) => dispatch(setTemp(temp)),
	setCheckout: (checkout) => dispatch(setCheckout(checkout)),
})

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(Choices))
