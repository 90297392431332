import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Select, InputLabel, FormControl, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
//import RequestNumberActions from './RequestNumberActions'
import { connect } from 'react-redux'
import { setAlert, setUser, setTemp } from '../../../actions'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120,
		marginTop: '0px',
		marginBottom: '0px'
	},
}))

function RequestNumber(props) {
	const [open, setOpen] = React.useState(false)	
	const [amount, setAmount] = React.useState(1)

	const classes = useStyles()

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		setLabelWidth(inputLabel.offsetWidth)
	}, [])

	var requestnumber = () => {


		var complete_request = () => {
		axios
			.post(props.baseurl + 'remote/numbers/create', {
				token: props.user.token,								
				amount: Number(amount),
			})
			.then((result) => {
				if (result.data.status === true) {
					handleClose()
				} else {
					props.setAlert({
						open: true,
						severity: result.data.severity,
						message: result.data.message,
					})
				}
			})
		}

        var temp = Object.assign({}, props.temp, {
            confirm: {
                title: 'Confirm purchase',
                callback: complete_request
            }
        })

        props.setTemp(temp)


	}

	var handleChange = (e) => {
		setAmount(e.target.value)
	}

	const handleClose = () => {
		setOpen(false)
		props.clear()
	}

	React.useEffect(() => {
		setOpen(props.open)
	}, [props.open])

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				fullWidth				
			>
				<DialogTitle style={{ paddingBottom: '8px' }}>
					Request Numbers
				</DialogTitle>
				<DialogContent style={{ paddingTop: '0px' }}>
					<Grid container spacing={1} alignItems="center">


						<Grid item xs={12}>
							
						<FormControl variant="standard" className={classes.formControl} style={{marginTop: '16px'}} fullWidth>
				<InputLabel ref={inputLabel}>How many numbers?</InputLabel>
				<Select	
				style={{width: '100%'}}			
					value={amount}
					onChange={handleChange}
					labelWidth={labelWidth}
				>					
					<MenuItem value={1}>1</MenuItem>					
					<MenuItem value={2}>2</MenuItem>
					<MenuItem value={3}>3</MenuItem>
					<MenuItem value={4}>4</MenuItem>
					<MenuItem value={5}>5</MenuItem>
					<MenuItem value={6}>6</MenuItem>
					<MenuItem value={7}>7</MenuItem>
					<MenuItem value={8}>8</MenuItem>
					<MenuItem value={9}>9</MenuItem>
					<MenuItem value={10}>10</MenuItem>
				</Select>
			</FormControl>

						</Grid>
					</Grid>

					<Typography variant="h6" style={{ marginTop: '16px' }}>
						
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={requestnumber} color="primary">
						Purchase Numbers
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
    baseurl: state.baseurl,
    temp: state.temp
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
    setUser: (user) => dispatch(setUser(user)),
    setTemp: (temp) => dispatch(setTemp(temp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestNumber)
