import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	formControl: {
		marginTop: theme.spacing(2),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))

export default function TransferActions(props) {

    const classes = useStyles()

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth)
	}, [])


	return (

                <FormControl variant="standard" className={classes.formControl}>
				<InputLabel ref={inputLabel}>Action</InputLabel>
                <Select	
                fullWidth				
					value={props.action}
					onChange={props.handleChange}
					labelWidth={labelWidth}>
					<MenuItem value={'add'}>Add</MenuItem>
					<MenuItem value={'remove'}>Remove</MenuItem>
				</Select>
			</FormControl>

	)
}
