import React from 'react'
//import Switch from '@material-ui/core/Switch'
import { connect } from 'react-redux'
import { setTheme } from '../../actions'
import axios from 'axios'
import Night from '@material-ui/icons/Brightness2TwoTone'
import Day from '@material-ui/icons/WbSunnyTwoTone'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'


function Theme(props) {
	const toggle = () => {
		var theme = ''

		if (props.theme === 'custom') {
			props.setTheme('dark')
			theme = 'dark'
		} else if (props.theme === 'light') {
			props.setTheme('dark')
			theme = 'dark'
		} else {
			props.setTheme('light')
			theme = 'light'
		}

		if (theme === 'light') {
			if (document.getElementById('forced_dark')) {
				document.getElementById('forced_dark').remove()
				alert(1)
			}
		}

		if (localStorage.getItem('token')) {
			axios
			.post(props.baseurl + 'remote/user/mode', {
				token: localStorage.getItem('token'),
				mode: theme,
			})
			.then(result => {})
		}
	}

	return (
		<ListItem
			key="night"
			button
			onClick={() => {
				toggle()
			}}
		>
			<ListItemIcon>
				{props.theme === 'dark' ? (
					<Day color="primary" className="menu" />
				) : (
					<Night color="primary" className="menu" />
				)}
			</ListItemIcon>
			<ListItemText
				primary={props.theme === 'dark' ? 'Bright Mode' : 'Night Mode'}
			/>
		</ListItem>
	)
}

const mapStateToProps = (state) => {
	return {
		theme: state.theme,
		baseurl: state.baseurl,
	}
}

const mapDispatchToProps = (dispatch) => ({
	setTheme: (theme) => {
		dispatch(setTheme(theme))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Theme)
