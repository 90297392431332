import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'

import { connect } from 'react-redux'
import { setShop, setAlert } from '../../actions'
import { setMessage } from '../../actions/messages'

import { Grid, Box } from '@material-ui/core'

import { InfoOutlined } from '@material-ui/icons'

import axios from 'axios'

import Current from './Current'

import Typography from '@material-ui/core/Typography'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { withStyles } from '@material-ui/core/styles'

//const BUCKET_NAME = 'resources.greenlightalerts.com'

const useStyles = withStyles((theme) => ({}), { withTheme: true })

function Shop(props) {
	const matches = props.theme.breakpoints.down('sm')

	var isSmall = useMediaQuery(matches)

	const [shop, setShop] = React.useState(false)

	const [headerBackground, setHeaderBackground] = React.useState(
		shop.header_background
	)

	React.useEffect(() => {
		if (props.headerBackground) {
			setHeaderBackground(props.headerBackground)
		}
	}, [props.headerBackground])

	React.useEffect(() => {
		setHeaderBackground(shop.header_background)

		if (shop.current_image) {
			//setBackground(shop.current_image)
		}
	}, [shop])

	React.useEffect(() => {
		if (props.image) {
			//setBackground(props.image)
		}
	}, [props.image])

	React.useEffect(() => {
		if (!shop) {
			axios
				.post(props.baseurl + 'remote/stores/search', {
					code: props.match.params.shopcode,
				})
				.then((result) => {
					setShop(result.data.store)
				})
		}
	}, [props.match.params.shopcode, props.baseurl, shop])

	var colors = {
		brand: '#4cbb17',
		sub: '#c3ebb0',
		error_color: '#fff',
		error_background: '#a10000',
	}

	//var header_background = '#4cbb17'
	var header_border = '#ffffff'
	var header_text = '#ffffff'
	var sub_header = colors.sub
	var icon_color = colors.sub
	//var info_background = '#ffffff'
	//var info_border = '#ffffff'

	if (headerBackground) {
		//header_background = headerBackground

		if (headerBackground === '#ffffff') {
			header_border = '#4cbb17'
			header_text = '#000000'
			sub_header = '#5b5b5b'
			icon_color = '#4cbb17'
			//info_background = '#ffffff'
			//info_border = '#4cbb17'
		} else if (headerBackground === '#1d1d1d') {
			header_border = '#ffffff'
			header_text = '#ffffff'
			sub_header = '#9b9b9b'
			icon_color = '#4cbb17'
			//info_background = '#1d1d1d'
			//info_border = '#ffffff'
		} else if (headerBackground === '#4cbb17') {
			header_border = '#ffffff'
			header_text = '#ffffff'
			sub_header = colors.sub
			icon_color = colors.sub
			//info_background = '#ffffff'
			//info_border = '#4cbb17'
		}
	}

	if (props.image) {
		//var background_iamge = props.image
	} else {
		//var background_image = shop.current_image
	}

	if (!shop) {
		return null
	}

	return (
		<React.Fragment>
			<CssBaseline />
			{shop.current_image ? (
				<div
					style={{
						backgroundColor: headerBackground,
						height: '105px',
						position: 'relative',
						top: '0px',
						textAlign: 'left',
						marginLeft: '0px',
						left: '0px',
						width: '100%',
						borderBottom: '1px solid ' + header_border,
					}}
				>
					<Grid container>
						<Grid
							item
							container
							xs={shop.logo ? 8 : 12}
							style={{ padding: '16px', flexDirection: 'row' }}
						>
							<Grid
								item
								xs={12}
								style={{
									fontWeight: 'bold',
									alignItems: 'center',
									color: header_text,
									marginBottom: '4px',
								}}
								container
								alignItems="center"
								alignContent="center"
							>
								<span>
									{/*
										{shop.title.substr(0, 24)}
										{shop.title.length > 24 ? '...' : null}
										*/}
									{shop.title}
								</span>{' '}
								<InfoOutlined
									style={{
										fontSize: '16px',
										fontWeight: 'bold',
										marginLeft: 8,
										color: icon_color,
									}}
								/>
							</Grid>
							<Grid
								item
								container
								xs={12}
								style={{
									color: sub_header,
									alignItems: 'center',
									justifyContent: 'left',

									marginTop: '0px',
									//height: '14px',
								}}
							>
								{shop.address1} {shop.address2}
							</Grid>
							<Grid
								item
								container
								xs={12}
								style={{
									color: sub_header,
									alignItems: 'center',
									justifyContent: 'left',

									//height: '14px',
								}}
							>
								{shop.city}
								{shop.city && shop.state ? ', ' : null}
								{shop.state} {shop.zipcode}
							</Grid>
							<Grid
								item
								container
								xs={12}
								style={{
									color: sub_header,
									alignItems: 'center',
									justifyContent: 'left',

									//height: '14px',
								}}
							>
								{shop.contactPhone}
							</Grid>
						</Grid>
						{shop.logo ? (
							<Grid
								container
								item
								xs={4}
								style={{ height: '105px', textAlign: 'right' }}
								alignItems="center"
								alignContent="center"
								justifyContent="flex-end"
							>
								<img
									key={shop.logo}
									alt="main"
									src={shop.logo}
									style={{
										maxWidth: '80px',

										maxHeight: '80px',
										marginRight: '8px',
									}}
								/>
							</Grid>
						) : null}
					</Grid>
				</div>
			) : null}

			<Grid container direction={isSmall ? 'column-reverse' : 'row'}>
				<Grid
					direction="row"
					item
					container
					xs={12}
					md={6}
					style={{
						padding: '8px',
						justifyContent: isSmall ? 'center' : 'flex-end',
						paddingTop: isSmall ? '0px' : '8px',
						alignItems: 'center',
						alignContent: 'center',
					}}
					justifyContent="flex-end"
				>
					<Box pt={isSmall ? 0 : 1} pb={isSmall ? 0 : 1}>
						<Current {...props} loaded_shop={shop} />
					</Box>
				</Grid>
				<Grid
					direction="row"
					item
					container
					xs={12}
					md={6}
					style={{
						padding: '8px',
						paddingTop: isSmall ? '0px' : '8px',
						justifyContent: isSmall ? 'center' : 'flex-start',
						alignItems: 'center',
						alignContent: 'center',
					}}
				>
					<Box pt={isSmall ? 3 : 1} pb={1}>
						<div
							style={{ textAlign: 'center', cursor: 'pointer' }}
							onClick={() => {
								window.location.href =
									'https://greenlightalerts.com/shopcode/' + shop.shopcode
							}}
						>
							<img
								src={shop.qr}
								style={{ width: isSmall ? '300px' : '300px' }}
								alt={'qr code for ' + shop.shopcode}
							/>
						</div>
						<div style={{ textAlign: 'center' }}>
							<Typography
								component="p"
								variant="caption"
								style={{ textAlign: 'center' }}
								color="secondary"
							>
								scan or tap QR code
							</Typography>
						</div>
					</Box>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({
	shop: state.shop,
	message: state.message,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setShop: (shop) => dispatch(setShop(shop)),
	setMessage: (message) => dispatch(setMessage(message)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(Shop))
