import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import {useStripe, useElements} from '@stripe/react-stripe-js'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import Director from '../../Director'
import { setUser, setIntent, setAlert } from '../../../actions'

function _CardForm(props) {

	const stripe = useStripe();
	const elements = useElements();

	const [errorMessage, setErrorMessage] = React.useState(null)
	const [paymentStatus, setPaymentStatus] = React.useState(null)
	//const [clientSecret, setClientSecret] = React.useState(null)

	const [saving, setSaving] = React.useState(false)

	var setIntent = props.setIntent



	var handleChange = ({ error }) => {
		if (error) {
			setErrorMessage( error.message )
		} else {
			setErrorMessage( null )
		}
	}

	var handleResult = (result,customer_id) => {



		axios
			.post(props.baseurl + 'remote/payments/savecard', {
				stripeToken: result,
				token: localStorage.getItem('token'),
				//email: 'customer@'+process.env.REACT_APP_DOMAIN,
				customer: customer_id,
			})
			.then((charged) => {
				if (charged.data.status === 'succeeded') {
					setPaymentStatus('succeeded')
				}

				if (props.callBack) {
					props['callBack'](charged.data)
				}

				if (props.redirect) {
					var user = Object.assign({}, props.user, {
						customer_token: charged.data.customer,
						payment_methods: charged.data.payment_methods,
					})

					props.setUser(user)

					setSaving(false)

					Director(props.redirect)
				}
			})
			.catch(err=>{
				setSaving(false)
			})
	}

	var handleSubmit = async (evt) => {
		evt.preventDefault()
		
		if (saving) {
			return false
		}

		setSaving(true)

		const cardElement = elements.getElement(CardElement);

		var res = await axios
			.post(props.baseurl + 'remote/payments/intent', {
				token: props.user.token,
			})


		

			//setClientSecret(res.data.client_secret)
		setIntent(res.data.client_secret)

		if (res.data.customer_id) {
			
			var u = Object.assign({}, props.user, {
				customer_token: res.data.customer_id,				
			})

			props.setUser(u)


		}


		if (stripe) {
			//stripe.createToken(cardElement).then(handleResult)

			stripe.confirmCardSetup(
				res.data.client_secret,
				{
				  payment_method: {
					card: cardElement,					
				  }
				}
			  ).then(function(result) {
				if (result.error) {
				  // Display error.message in your UI.

				  props.setAlert({
					open: true,
					severity: 'error',
					message: 'Invalid Payment Method',
				})

				setSaving(false)

				} else {
				  // The SetupIntent was successful!
handleResult(result,res.data.customer_id)

				}
			  });

		


		} else {
			console.log("Stripe.js hasn't loaded yet.")
		}
	}


		return paymentStatus === 'succeeded' ? (
			<div>Payment complete</div>
		) : (
			<div
				className="CheckoutForm"
				style={{ width: '100%', marginTop: '16px' }}
			>
				<form>
					<label>
						Payment information
						<CardElement
						style={{backgroundColor: '#fff'}}
							onChange={handleChange}
							options={{
								style: {
									base: {
										transitionTimingFunction: 'none !important',
										transition: 'none !important',
										fontSize: '16px',
										color: '#424770',
										fontFamily: 'Open Sans, sans-serif',
										letterSpacing: '0.025em',
										'::placeholder': {
											color: '#aab7c4',
										},
									},
									invalid: {
										color: '#c23d4b',
									},
									empty: {
										backgroundColor: '#fff'
									},
									'::placeholder': {
										backgroundColor: '#fff'
									  },
								},
							}}
						/>
					</label>
					<div className="error" role="alert">
						{errorMessage}
					</div>
					<Button
						color="primary"
						variant="contained"
						fullWidth
						onClick={handleSubmit}
						disabled={saving}
					>
						{saving ? 'Saving...' : 'Save Card'}
					</Button>
				</form>
			</div>
		)
	}



const CardForm = _CardForm

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	setIntent: (intent) => dispatch(setIntent(intent)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardForm)
