import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import NotVerifiedUser from '@material-ui/icons/Block'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'

const useStyles = makeStyles({
	root: {
		maxWidth: '100%',
	},
})

function MediaCard(props) {
	const classes = useStyles()

	return (
		<Card className={classes.root}>
			<CardActionArea>
				<CardContent>
					<Grid
						container
						justifyContent="center"
						direction="row"
						alignItems="center"
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<NotVerifiedUser fontSize="large" color="primary" style={{marginRight: '8px', color: '#f12e45'}} />

						<Typography variant="h5" component="p">
							NOT Connected
						</Typography>
					</Grid>

					<Typography variant="body2" color="textSecondary" component="p" style={{marginTop: '8px'}}>
						Your Twilio account is not connected to your dashboard. Check your credentials.
					</Typography>


				</CardContent>
			</CardActionArea>
		</Card>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MediaCard)
