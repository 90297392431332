// src/ui/theme/index.js

import { createTheme } from '@material-ui/core/styles';

const palette = {
  primary: { main: '#212121' },
  secondary: { main: '#95bf79' }
};

const overrides = {
  MuiDrawer: {
    root: {
      borderRight: '0px !important'
    }
  }
}

const themeName = 'Mine Shaft Puce Ross Seals';

export default createTheme({ palette: palette, overrides: overrides, themeName });
