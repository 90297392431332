const alert = (state = {}, action) => {
	switch (action.type) {
        case 'SET_ALERT':			
            var new_alert = {
                open: action.alert.open,
                severity: action.alert.severity ? action.alert.severity : state.severity,
                message: action.alert.message ? action.alert.message : state.message,
                vertical: action.alert.vertical ? action.alert.vertical : null,
                horizontal: action.alert.horizontal ? action.alert.horizontal : null,
            }
			return new_alert
		default:
			return state
	}
}

export default alert
