import React from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import { Grid, Container } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUser, setAlert, setTheme, setLoaded } from '../../actions'
import axios from 'axios'
import Director from '../Director'
import { Link as Links } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

//import { ThemeProvider } from '@material-ui/core/styles'

const useStyles = withStyles(
	(theme) => ({
		paper: {
			marginTop: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.primary.main,
		},
		form: {
			width: '100%', // Fix IE 11 issue.
		},
		submit: {
			height: '40px',
		},
	}),
	{ withTheme: true }
)

function SignIn(props) {
	const classes = props.classes

	const matches = props.theme.breakpoints.down('sm')
	var isSmall = useMediaQuery(matches)

	const [email, setEmail] = React.useState('')
	const [password, setPassword] = React.useState('')

	var submitSignIn = (e) => {
		props.setLoaded(false)

		e.preventDefault()
		axios
			.post(props.baseurl + 'remote/user/signin', {
				email: email,
				password: password,
			})
			.then(async (result) => {
				if (result.data) {
					if (result.data.mode) {
						props.setTheme(result.data.mode)
					}

					props.changeUser(() => {
						if (result.data.package > 0) {
							Director('/dashboard')
						} else {
							Director('/subscribe/onboard')
						}

						window.scrollTo(0, 0)
					}, result.data)
				} else {
					props.setAlert({
						open: true,
						severity: 'error',
						message: 'Invalid credentials',
					})
				}
			})
			.catch((err) => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: `That action can't be performed right now`,
				})
			})
	}

	return (
		<Container
			component="main"
			disableGutters
			maxWidth="xl"
			style={{
				padding: '8px',
				backgroundColor: '#000000',
			}}
		>
			<div
				style={{ maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }}
			>
				<form className={classes.form} noValidate onSubmit={submitSignIn}>
					<div style={{ position: 'relative', width: '100%' }}>
						<Grid container>
							<Grid
								container
								style={{
									alignItems: 'center',
									alignContent: 'center',
									justifyContent: 'flex-end',
									margin: 0,
								}}
								spacing={1}
								item
								xs={12}
								md={12}
							>
								<Grid item xs={12} md={4}>
									<TextField
										variant="outlined"
										margin="dense"
										required
										fullWidth
										id="email"
										placeholder="Email or Phone"
										name="email"
										autoComplete="email"
										value={email}
										onChange={(e) => {
											setEmail(e.target.value)
										}}
										style={{
											margin: 0,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<TextField
										variant="outlined"
										margin="dense"
										required
										fullWidth
										name="password"
										placeholder="Password"
										type="password"
										id="password"
										autoComplete="current-password"
										value={password}
										onChange={(e) => {
											setPassword(e.target.value)
										}}
										style={{
											margin: 0,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className={classes.submit}
									>
										Sign In
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</div>

					<div
						style={{ position: 'relative', width: '100%', marginTop: '4px' }}
					>
						<Grid container>
							<Grid
								container
								style={{
									alignItems: 'center',
									alignContent: 'center',
									justifyContent: 'flex-end',
									margin: 0,
								}}
								spacing={1}
								item
								xs={12}
								md={12}
							>
								<Grid item xs={12} md={4}>
									<Links component={Link} color="primary" to={'/forgot'}>
										Forgot password?
									</Links>
								</Grid>

								<Grid
									item
									xs={12}
									md={8}
									style={{
										textAlign: isSmall ? 'left' : 'right',
									}}
								>
									<Links component={Link} color="primary" to={'/signup'}>
										Don't have an account? Sign Up
									</Links>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</form>
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
	loaded: state.loaded,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setLoaded: (loaded) => dispatch(setLoaded(loaded)),
	setAlert: (alert) => dispatch(setAlert(alert)),
	setTheme: (theme) => dispatch(setTheme(theme)),
	changeUser: (callback, user) => {
		dispatch((dispatch) => {
			callback(dispatch(setUser(user)).user, true)
		})
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(SignIn))
