import React from 'react'
import YouTube from 'react-youtube'

class Video extends React.Component {
	render() {
		const opts = {
			width: this.props.isxSmall ? 320 : this.props.isSmall ? 560 : 720,
			height: this.props.isxSmall ? 180 : this.props.isSmall ? 340 : 405,

			playerVars: {
				// https://developers.google.com/youtube/player_parameters
				autoplay: 0,
				showinfo: 0,
				controls: 0,
			},
		}

		return <YouTube videoId="rmhe-dWBRhY" opts={opts} onReady={this._onReady} />
	}

	_onReady(event) {
		// access to player in all event handlers via event.target
		event.target.pauseVideo()
	}
}

export default Video
