import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { setUser } from '../../../actions'
import axios from 'axios'
import { Button, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'

const styles = theme => {
	return {
		paper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		buttons: {
			width: '50%',
		},
	}
}

class Home extends React.Component {
	state = {
		message: false,
		store: {},
	}

	componentDidMount() {
		//this.setState({store: store.getState()})
	}

	setUser = () => {
		axios.post(this.props.baseurl + 'remote/api/load').then(result => {
			this.props.setUser(result.data.user)
		})
	}

	render() {
		return (
			<React.Fragment>
				<CssBaseline />

				<Grid container>
					<Grid item xs={12} sm={12} md={6}>
						<Box p={1}>
							<Button
								variant="contained"
								component={Link}
								to="/reports/messages"
								fullWidth
							>
								Messages Report
							</Button>
						</Box>
					</Grid>

				</Grid>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	user: state.auth,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Home))
