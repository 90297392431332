import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	from: {
		fontWeight: 'bold',
		padding: '2px',
	},
	ago: {
		fontSize: '12px',
		padding: '2px',
	},
	body: {
		padding: '2px',
	},
}))

export default function SimpleExpansionPanel(props) {
	const classes = useStyles()

	var chat_time = props.date_diff(props.message.date_added, props.retrieve_date)

	return (
		<div className={props.message.from === 'app' ? 'from-you' : 'to-you'}>
			<div className={classes.from}>
				{props.message.from === 'app' ? 'you' : 'them'}
			</div>
			<div className={classes.body}>{props.message.body}</div>
			<div className={classes.ago}>sent {chat_time} ago</div>
		</div>
	)
}
