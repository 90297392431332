import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import TransferActions from './TransferActions'
import { connect } from 'react-redux'
import { setAlert, setUser } from '../../actions'
import Typography from '@material-ui/core/Typography'

function Transfer(props) {
	const [open, setOpen] = React.useState(false)
	const [action, setAction] = React.useState('add')
	const [amount, setAmount] = React.useState('')

	const handleChange = () => {
		if (action === 'add') {
			setAction('remove')
		} else {
			setAction('add')
		}
	}

	var transfer = () => {
		axios
			.post(props.baseurl + 'remote/master/transfer', {
				token: props.user.token,
				_id: props.transfer._id,
				action: action,
				amount: Number(amount),
			})
			.then((result) => {
				if (result.data.status === true) {
					handleClose()
				} else {
					props.setAlert({
						open: true,
						severity: result.data.severity,
						message: result.data.message,
					})
				}
			})
	}

	var handleAmount = (e) => {
		setAmount(e.target.value)
	}

	const handleClose = () => {
		setOpen(false)
		props.clear()
	}

	React.useEffect(() => {
		setOpen(props.open)
	}, [props.open])

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle style={{ paddingBottom: '8px' }}>
					Transfer Credit
				</DialogTitle>
				<DialogContent style={{ paddingTop: '0px' }}>
					<Grid container spacing={1} alignItems="center">
						<Grid item xs={6}>
							<TransferActions action={action} handleChange={handleChange} />
						</Grid>

						<Grid item xs={6}>
							<TextField
								style={{ marginTop: '16px' }}
								autoFocus
								label="Amount"
								type="amount"
								fullWidth
								onChange={handleAmount}
								value={amount}
								variant="standard"
							/>
						</Grid>
					</Grid>

					<Typography variant="h6" style={{ marginTop: '16px' }}>
						{action === 'add' ? 'To: ' : 'From: '}{' '}
						{props.transfer.email
							? props.transfer.email
							: props.transfer.phone_number
							? props.transfer.phone_number
							: 'this account'}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={transfer} color="primary">
						Transfer
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
	setUser: (user) => dispatch(setUser(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Transfer)
