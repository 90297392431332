const loaded = (state = false, action) => {
	switch (action.type) {
		case 'SET_LOADED':			
		return action.loaded
		default:
			return state
	}
}

export default loaded
