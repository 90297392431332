import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { connect } from 'react-redux'
import { setAlert } from '../../actions'


const useStyles = withStyles(
	(theme) => ({
		root: {
			width: '100%',
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			flexBasis: '100%',
			flexShrink: 0,
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
		},
		faq: {
			border: '1px solid rgba(0, 0, 0, .125)',
			marginBottom: '0px',
			boxShadow: 'none',
			'&:not(:first-child)': {
				borderTop: 0,
			},
			'&:before': {
				display: 'none',
			},
			'&.Mui-expanded': {
				'&:not(:first-child)': {
					borderTop: '0px solid rgba(0, 0, 0, .125)',
				},
			},
		},
	}),
	{ withTheme: true }
)

const faqs = []
faqs.push(
	{
		title: `What is `+process.env.REACT_APP_NAME+`?`,
		body: (
			<React.Fragment>
				{process.env.REACT_APP_NAME} is an app available on both iOS and Android that allows users to follow dispensaries and receive updates via push notifications instead of text messages.
			</React.Fragment>
		),
	},
	{
		title: `Are there other benefits to using `+process.env.REACT_APP_NAME+`?`,
		body: (
			<React.Fragment>
				Saving money is not the only reason to use {process.env.REACT_APP_NAME}. The other benefits
				of using all of the different features {process.env.REACT_APP_NAME} has to offer will become
				obvious right away. {process.env.REACT_APP_NAME} provides simple and easy to use tools for
				things such as:
				<br />
				<br />
				<Typography variant="body1">
					• Importing any number of new or existing contacts into your dashboard
					as often as you need to.
				</Typography>
				<br />
				<Typography variant="body1">
					• Managing details for contacts such as phone number, email address,
					name, and status.
				</Typography>
				<br />
				<Typography variant="body1">
					• Creating and managing groups to organize contacts, allowing for
					group specific messages such as targeted marketing campaigns and
					promotional offers.
				</Typography>
				<br />
				<Typography variant="body1">
					• Creating keywords with options to do things like send auto-responses
					or add the sender to a specific group when they text in a particular
					keyword.
				</Typography>
				<br />
				<Typography variant="body1">
					• Automatically recognizing and handling requests from contacts to
					stop receiving messages.
				</Typography>
				<br />
				<Typography variant="body1">
					• Reading and replying to incoming messages from contacts via a text
					message inbox.
				</Typography>
				<br />
				<Typography variant="body1">
					• Composing messages to be sent immediately, scheduled for a specific
					time or saved as a draft to edit later.
				</Typography>
				<br />
				<Typography variant="body1">
					• Uploading images to send as picture messages.
				</Typography>
				<br />
				<Typography variant="body1">
					• Calculating the length and cost of each message.
				</Typography>
				<br />
				<Typography variant="body1">
					• Connecting your Twilio account to enable sending messages, importing
					phone numbers, and deducting sent messages from your Twilio account
					balance.
				</Typography>
				<br />
				<Typography variant="body1">
					• Searching for new local or toll-free phone numbers and immediately
					purchasing them for your account to use.
				</Typography>
				<br />
				<Typography variant="body1">
					• Creating and managing sub-accounts.
				</Typography>
				<br />
				<Typography variant="body1">
					• Automatically delivering messages as quickly as possible to all of
					the contacts in the targeted groups and pausing delivery if your
					account balance becomes too low to continue sending.
				</Typography>
			</React.Fragment>
		),
	}
)

function FAQ(props) {
	const [expanded, setExpanded] = React.useState({})

	const handleChange = (faq) => (event, isExpanded) => {
		var new_exp = {}

		if (expanded[faq]) {
			new_exp[faq] = false
		} else {
			new_exp[faq] = true
		}

		var final_exp = {}

		//comment out to allow multiple accordions to be open simultaneously
		final_exp = Object.assign({}, new_exp)

		final_exp = new_exp

		setExpanded(final_exp)
	}

	return (
		<div className={props.classes.root}>
			{faqs.map((f, i) => {
				return (
					<Accordion
						expanded={expanded['faq' + i] ? expanded['faq' + i] : false}
						onChange={handleChange('faq' + i)}
						key={'faq' + i}
						TransitionProps={{
							timeout: 0,
						}}
						className={props.classes.faq + ' faq'}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={'faq' + i + 'bh-content'}
							id={'faq' + i + 'bh-header'}
						>
							<Typography className={props.classes.heading + ' bold'}>
								{f.title}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography variant={'body1'} component="div">
								{f.body}
							</Typography>
						</AccordionDetails>
					</Accordion>
				)
			})}
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(FAQ))
