import React, { useRef } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import { setMessage } from '../../../actions/messages'
import axios from 'axios'
import Director from '../../Director'
import FormControl from '@material-ui/core/FormControl'

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function Reply(props) {
	const classes = useStyles()

    const [message, setMessage] = React.useState({})    
	const loaded = useRef(false)
    const [saved, setSaved] = React.useState(false)
	
	var updateMessage = (field, value) => {
		var updates = {}
		updates[field] = value

		setSaved(false)

		setMessage(Object.assign({}, message, updates))
	}
	
	var submitSave = e => {
		e.preventDefault()

		axios
			.post(props.baseurl + 'remote/messages/save', {
				token: props.user.token,
                body: message.body,
                to: props.match.params.phone_number,
                status: 'reply',
                reply: true
			})
			.then(result => {
				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})

				setSaved(true)

				if (!props.match.params.messageID && result.data._id) {
					loaded.current = false					
					Director('/messages/edit/' + result.data._id, true)
				}

			})
			.catch(err => {
				props.setAlert({
					open: true,
					severity: 'error',
					message: 'Error',
				})
			})
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Typography component="h1" variant="h5">
    {message ? props.title ? props.title : message && message._id ? 'Reply to' : 'Reply to' : null} {message ? <Button color="primary" component={Link} to={'/messages/convo/'+props.match.params.phone_number}>{props.match.params.phone_number}</Button> : null}
				</Typography>
                
				 
					<form className={classes.form} noValidate onSubmit={submitSave}>
						<FormControl component="fieldset" style={{ display: 'flex' }}>

							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="body"
								label="Reply"
								name="body"
								autoFocus
								disabled={saved && message && message.status === 'sent' ? true : false}
								value={message.body}
								onChange={e => {
									updateMessage('body', e.target.value)
								}}
								multiline={true}
								rows={5}
							/>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								disabled={saved ? true : false}
							>
								Send Message
							</Button>
						</FormControl>
					</form>

			</div>
		</Container>
	)
}

const mapStateToProps = state => ({
	user: state.auth,
	message: state.message,
	baseurl: state.baseurl
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setMessage: message => dispatch(setMessage(message)),
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Reply)