import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import List from './messages/List'
import Edit from './messages/Edit'
import Report from './messages/Report'
import Reply from './messages/Reply'
import To from './messages/To'
import Chat from './messages/Chat'
import Convo from './messages/Convo'

const styles = (theme) => {
	return {
		root: {
			padding: theme.spacing(3, 2),
		},
	}
}

function Messages(props) {
	var path = props.match.path
	var parent_props = props

	return (
		<Switch>
			<Route
				path={`${path}/`}
				exact
				render={(props) => {					
					return <List {...props} status={parent_props.status ? parent_props.status : ''} />
				}}
			/>
			<Route
				path={`${path}/add`}
				render={(props) => <Edit title="Add Message" {...props} />}
			/>
			<Route path={`${path}/edit/:messageID?`} component={Edit} />} />			
			<Route path={`${path}/chat/:phone_number?`} component={Chat} />} />
			<Route path={`${path}/convo/:uuid?`} component={Convo} />} />
			<Route path={`${path}/to/:phone_number?`} component={To} />} />
			<Route path={`${path}/reply/:messageID?`} component={Reply} />} />
			<Route path={`${path}/history/:phone_number?`} render={(props) => {					
					return <List {...props} status={''} />
				}} />} />
			<Route path={`${path}/report/:messageID?`} component={Report} />} />
		</Switch>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Messages))
