import React, { useState } from 'react'
import MomentUtils from '@date-io/moment'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Moment from 'moment-timezone'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'

function Picker(props) {
	const [selectedDate, handleDateChange] = useState(null)

	var field_date = props.date

	Moment.tz.setDefault(
		props.user.timezone ? props.user.timezone : 'America/Los_Angeles'
	)

	React.useEffect(() => {
		handleDateChange(field_date)
	}, [field_date])

	var changeDate = (value) => {
		var val = Moment(value).seconds(0).milliseconds(0)
		console.log(val.valueOf())
		props.callback(val.valueOf())
		handleDateChange(val.valueOf())
	}

	switch (props.user.timezone ? props.user.timezone : 'America/Los_Angeles') {
		case 'America/Los_Angeles':
			var tz = 'Pacific'
			break
		case 'America/New_York':
			tz = 'Eastern'
			break
		case 'America/Denver':
			tz = 'Mountain'
			break
		case 'America/Chicago':
			tz = 'Central'
			break
		case 'Pacific/Honolulu':
			tz = 'Hawaii'
			break
		case 'America/Anchorage':
			tz = 'Alaska'
			break

		default:
			tz = 'Pacific'
		// code block
	}

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<DateTimePicker
				fullWidth
				disabled={props.disabled ? true : false}
				inputVariant="filled"
				value={selectedDate}
				onChange={changeDate}
				margin="normal"
				label="Choose date/time"
				format="MMM Do YYYY, h:mm a"
			/>
			<React.Fragment>
				<Typography variant="caption" component="p">({tz} timezone)</Typography>
			</React.Fragment>
		</MuiPickersUtilsProvider>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Picker)
