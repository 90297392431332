import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Pagination from '@material-ui/lab/Pagination'
import { setUser, setAlert, setTemp } from '../../actions'
import { connect } from 'react-redux'

const useStyles = withStyles(
	theme => ({
		root: {
			'& > * + *': {
				marginTop: theme.spacing(2),
			},
			display: 'block',
		},
		pagination: {
			width: '100%',
		},
	}),
	{
		withTheme: true,
	}
)

function Pages(props) {
	const [pagination, setPagination] = React.useState(props.pagination)

	const classes = props.classes
	const handleChange = (event, value) => {


		
        var new_pagination = Object.assign({}, pagination)
        new_pagination.page = value
        new_pagination.skip = pagination.max * (value - 1)
        new_pagination.limit = pagination.max * value

        setPagination(new_pagination)
        props.callback(new_pagination)

	}

	var content = (
		<div
			className={classes.pagination}
			style={{
				padding: props.isSmall ? props.theme.spacing(1) : '0px',
			}}
		>
			<Pagination
				style={{
					width: 'fit-content',
					marginLeft: props.isSmall ? 'auto' : 'auto',
					marginRight: props.isSmall ? 'auto' : props.wide ? 'auto' : '0px',
				}}
				count={Math.ceil(props.total / pagination.max)}
				page={pagination.page}
				onChange={handleChange}
				showFirstButton={false}
				showLastButton={false}
				shape="rounded"
				variant="text"
				size={props.size}
				siblingCount={1}
				boundaryCount={1}
				hidePrevButton={pagination.page > 1 ? false : false}
				hideNextButton={pagination.page === Math.ceil(props.total / pagination.max) || props.total === 0 ? false : false}
			/>
		</div>
    )
    
	return !content ? (
		<Paper
			className={classes.root}
			style={{
				width: props.wide ? '100%' : '100%',
			}}
		>
			{content}
		</Paper>
	) : (
		content
	)
}

const mapStateToProps = state => ({
	user: state.auth,
	temp: state.temp,
})

const mapDispatchToProps = dispatch => ({
	setUser: user => dispatch(setUser(user)),
	setAlert: alert => dispatch(setAlert(alert)),
	dispatch: dispatch,
	changeTemp: (callback, temp) => {
		dispatch(dispatch => {
			callback(dispatch(setTemp(temp)).temp,true)
		})
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(useStyles(Pages))
