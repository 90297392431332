import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setAlert } from '../actions'

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}))

function Alerts(props) {
	const classes = useStyles()

	const [open, setOpen] = React.useState(props.alert.open)

	React.useEffect(() => {
		setOpen(props.alert.open)
	}, [props.alert.open])

	const handleClose = (event, reason) => {
		
		if (reason === 'clickaway') {
			return
		}
		
		props.setAlert({
			open: false,			
		})
	}
	
	return (
		open ? <div className={classes.root}>
		<Snackbar
			anchorOrigin={{horizontal: props.alert.horizontal ? props.alert.horizontal : 'center', vertical: props.alert.vertical ? props.alert.vertical : 'bottom'}}
			open={props.alert.open}
			autoHideDuration={2000}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={props.alert.severity}>
				{props.alert.message}
			</Alert>
		</Snackbar>
	</div> : null
	)
}

const mapStateToProps = state => ({
	alert: state.alert,
})

const mapDispatchToProps = dispatch => ({
	setAlert: alert => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)
