import React /*useState*/ from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setAlert } from '../../actions'
import HelpIcon from '@material-ui/icons/HelpTwoTone'
import { Grid, Container } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import FAQ from './FAQ'
import FAQStandalone from './FAQStandalone'
import FAQEmail from './FAQEmail'

const useStyles = withStyles(
	(theme) => ({
		paper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginTop: theme.spacing(2),
		},
		container: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2)
		},
	}),
	{ withTheme: true }
)

function Help(props) {
	return (
		<Container component="main" disableGutters maxWidth="md">
			<CssBaseline />
			<div className={`${props.classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}

				>
					<HelpIcon
						fontSize="large"
						color="primary"
						style={{ marginRight: '8px' }}
					/>

					<Typography component="h1" variant="h5">
						Help & Support
					</Typography>
				</Grid>

				<Grid container item className={props.classes.container+' paper-content'}>
					<Grid container>
						<Grid item xs={12}>
						{process.env.REACT_APP_CONNECT === 'true' ? <FAQ /> : process.env.REACT_APP_QUEUE === 'email' ? <FAQEmail /> : <FAQStandalone />}
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(Help))
