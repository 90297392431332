import React, { useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../../actions'
import Box from '@material-ui/core/Box'
//import Button from '@material-ui/core/Button'

function Numbers(props) {
	const [numbers, setNumbers] = React.useState(props.numbers)


	useEffect(() => {
		setNumbers(props.numbers)
	}, [props.numbers])

	var changeChecked = (phone_number) => {
		var found = false
		var foundIndex = 0

		if (props.selected) {
			props.selected.forEach((v, i) => {
				if (v.phone_number.toString() === phone_number) {
					found = true
					foundIndex = i
				}
			})
		}

		if (found) {
			props.selected.splice(foundIndex, 1)
			return props.selected
		} else if (props.selected) {
			var new_selected = Object.assign([], props.selected)

			new_selected.push({
				phone_number: phone_number,
			})

			return new_selected
		} else {
			new_selected = []

			new_selected.push({
				phone_number: phone_number,
			})

			return new_selected
		}
	}

	var isChecked = (phone_number) => {
		var found = false

		if (props.selected) {
			props.selected.forEach((v, i) => {
				if (v.phone_number === phone_number) {
					found = true
				}
			})
		}

		if (found) {
			return true
		} else {
			return false
		}
	}

	var form_numbers = numbers
		? numbers.map((v, i) => {
				return (
					<FormGroup aria-label="numbers" row key={v.phone_number}>
						<FormControlLabel
							control={
								<Checkbox
									checked={isChecked(v.phone_number)}
									onChange={() => {
										var new_numbers = changeChecked(v.phone_number)                                        
                                        props.changeSelected([...new_numbers])
									}}
									value={v.phone_number}
									color="primary"
								/>
							}
							label={v.phone_number}
						/>
					</FormGroup>
				)
		  })
		: []

	return (
		<Box pt={2}>
			<FormControl component="fieldset">
				<FormLabel component="legend">Available Numbers</FormLabel>
				<div style={{paddingTop: '8px'}}>
                {form_numbers}
                </div>
			</FormControl>


                {/*
                <div style={{width:'100%',marginTop: '8px'}}>
					<Button
                    disabled={!selected.length}
                        variant="contained"
                        fullWidth
                        color="primary"
						onClick={() => {
							if (window.confirm('Are you sure?')) {
                                props.requestnumbers([...selected])
                            }
						}}
					>
						Purchase Selected Numbers
					</Button>
				</div>
                */}
	
		</Box>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Numbers)
