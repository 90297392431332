import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import AccountBoxIcon from '@material-ui/icons/Settings'
import ReplayIcon from '@material-ui/icons/Replay'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { withStyles } from '@material-ui/core/styles'
import {
	Container,
	Grid,
	Box,
	Switch,
	FormControlLabel,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { setUser, setAlert } from '../../actions'
import axios from 'axios'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Moment from 'moment-timezone'
//import countries from './Countries'
import { InfoOutlined } from '@material-ui/icons'
import Current from './updates/Current'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = withStyles(
	(theme) => ({
		paper: {
			marginTop: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.primary.main,
		},
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		submit: {
			marginTop: theme.spacing(3),
		},
		formControl: {
			marginTop: theme.spacing(1),
			minWidth: 120,
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
		},
		previewSubmit: {
			marginTop: theme.spacing(1),
		},
		switch: {
			marginLeft: '8px',
		},
	}),
	{ withTheme: true }
)

function Preferences(props) {
	//var base_url = props.user && props.user.base_url && props.user.base_url !== '/' ? props.user.base_url : '/'

	var locked = props.user.package < 1

	const matches = props.theme.breakpoints.down('sm')

	var isSmall = useMediaQuery(matches)

	const classes = props.classes
	const [token] = React.useState(localStorage.getItem('token') || null)
	const [signature, setSignature] = React.useState(null)
	const [nickname, setNickname] = React.useState(null)
	const [area_code, setAreacode] = React.useState(null)
	const [timezone, setTimezone] = React.useState('')
	const [country, setCountry] = React.useState('')
	const [countryInfo, setCountryInfo] = React.useState({})
	const [logo, setLogo] = React.useState('')
	//const [removedLogo, setRemovedLogo] = React.useState(null)
	const [logoUpload, setLogoUpload] = React.useState(null)

	const [address1, setAddress1] = React.useState(null)
	const [address2, setAddress2] = React.useState(null)
	const [city, setCity] = React.useState(null)
	const [state, setState] = React.useState(null)
	const [zipcode, setZipcode] = React.useState(null)
	const [contactPhone, setContactPhone] = React.useState(null)
	const [textPhone, setTextPhone] = React.useState(null)
	const [contactEmail, setContactEmail] = React.useState(null)

	const [facebook, setFacebook] = React.useState('')
	const [instagram, setInstagram] = React.useState('')
	const [snapchat, setSnapchat] = React.useState('')
	const [twitter, setTwitter] = React.useState('')
	const [tiktok, setTiktok] = React.useState('')
	const [website, setWebsite] = React.useState('')
	const [weedmaps, setWeedmaps] = React.useState('')
	const [headerBackground, setHeaderBackground] = React.useState('')

	const [testcode, setTestcode] = React.useState('')
	const [shopcode, setShopCode] = React.useState('')
	const [shop, setShop] = React.useState('')
	const [clientcode, setClientcode] = React.useState('')

	const [link1, setLink1] = React.useState({ title: '', url: '' })
	const [link2, setLink2] = React.useState({ title: '', url: '' })
	const [link3, setLink3] = React.useState({ title: '', url: '' })

	const [video, setVideo] = React.useState('')
	//const [store, setStore] = React.useState('')

	const [preview, setPreview] = React.useState(false)

	const [coverStyle, setCoverStyle] = React.useState('contain')
	const [chatEnabled, setChatEnabled] = React.useState(false)
	const [askEmail, setAskEmail] = React.useState(false)
	const [askPhone, setAskPhone] = React.useState(false)

	const [hours, setHours] = React.useState('')
	const [description, setDescription] = React.useState('')

	const [expandAddress, setExpandAddress] = React.useState(false)
	const [expandContact, setExpandContact] = React.useState(false)
	const [expandSocial, setExpandSocial] = React.useState(false)
	const [expandLinks, setExpandLinks] = React.useState(false)
	const [expandInfo, setExpandInfo] = React.useState(false)
	const [expandQR, setExpandQR] = React.useState(false)

	var client_code = props.user
		? props.user.client_code
			? props.user.client_code
			: ''
		: ''

	var test_code = props.user
		? props.user.test_code
			? props.user.test_code
			: ''
		: ''

	var shop_code = props.user
		? props.user.shopcode
			? props.user.shopcode
			: ''
		: ''

	React.useEffect(() => {
		setClientcode(client_code)
	}, [client_code])

	React.useEffect(() => {
		setTestcode(test_code)
	}, [test_code])

	React.useEffect(() => {
		setShopCode(shop_code)
	}, [shop_code])

	React.useEffect(() => {
		setTimezone(props.user.timezone)
	}, [props.user.timezone])

	React.useEffect(() => {
		setCountryInfo(props.user.country_info)
	}, [props.user.country_info])

	React.useEffect(() => {
		setCountry(props.user.country)
	}, [props.user.country])

	React.useEffect(() => {
		setLogo(props.user.logo)
	}, [props.user.logo])

	React.useEffect(() => {
		setLogoUpload(props.user.current_logo_upload)
	}, [props.user.current_logo_upload])

	React.useEffect(() => {
		setFacebook(props.user.facebook || '')
		setInstagram(props.user.instagram || '')
		setSnapchat(props.user.snapchat || '')
		setTwitter(props.user.twitter || '')
		setTiktok(props.user.tiktok || '')
		setLink1(props.user.link_1 || { title: '', url: '' })
		setLink2(props.user.link_2 || { title: '', url: '' })
		setLink3(props.user.link_3 || { title: '', url: '' })
		setWebsite(props.user.website || '')
		setWeedmaps(props.user.weedmaps || '')
		setHeaderBackground(props.user.header_background || '')

		setVideo(props.user.current_video || '')

		setShop(props.user.shop || '')

		setCoverStyle(props.user.cover_style || 'contain')
		setChatEnabled(props.user.chat_enabled || false)
		setAskPhone(props.user.askPhone || false)
		setAskEmail(props.user.askEmail || false)

		setHours(props.user.hours || '')
		setDescription(props.user.description || '')
	}, [props.user])

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		setLabelWidth(inputLabel.current ? inputLabel.current.offsetWidth : 0)
	}, [])

	if (signature) {
		var use_sig = signature
	} else {
		if (signature === null) {
			use_sig = props.user.signature ? props.user.signature : ''
		} else {
			use_sig = ''
		}
	}

	if (nickname) {
		var use_nick = nickname
	} else {
		if (nickname === null) {
			use_nick = props.user.nickname ? props.user.nickname : ''
		} else {
			use_nick = ''
		}
	}

	if (address1) {
		var use_address1 = address1
	} else {
		if (address1 === null) {
			use_address1 = props.user.address1 ? props.user.address1 : ''
		} else {
			use_address1 = ''
		}
	}

	if (address2) {
		var use_address2 = address2
	} else {
		if (address2 === null) {
			use_address2 = props.user.address2 ? props.user.address2 : ''
		} else {
			use_address2 = ''
		}
	}

	if (city) {
		var use_city = city
	} else {
		if (city === null) {
			use_city = props.user.city ? props.user.city : ''
		} else {
			use_city = ''
		}
	}

	if (state) {
		var use_state = state
	} else {
		if (state === null) {
			use_state = props.user.state ? props.user.state : ''
		} else {
			use_state = ''
		}
	}

	if (zipcode) {
		var use_zipcode = zipcode
	} else {
		if (zipcode === null) {
			use_zipcode = props.user.zipcode ? props.user.zipcode : ''
		} else {
			use_zipcode = ''
		}
	}

	if (contactEmail) {
		var use_contactEmail = contactEmail
	} else {
		if (contactEmail === null) {
			use_contactEmail = props.user.contactEmail ? props.user.contactEmail : ''
		} else {
			use_contactEmail = ''
		}
	}

	if (contactPhone) {
		var use_contactPhone = contactPhone
	} else {
		if (contactPhone === null) {
			use_contactPhone = props.user.contactPhone ? props.user.contactPhone : ''
		} else {
			use_contactPhone = ''
		}
	}

	if (textPhone) {
		var use_textPhone = textPhone
	} else {
		if (textPhone === null) {
			use_textPhone = props.user.textPhone ? props.user.textPhone : ''
		} else {
			use_textPhone = ''
		}
	}

	if (area_code) {
		var use_area = area_code
	} else {
		if (area_code === null) {
			use_area = props.user.area_code ? props.user.area_code : ''
		} else {
			use_area = ''
		}
	}

	if (logo) {
		var use_logo = logo
	} else {
		if (logo === null) {
			use_logo = props.user.logo ? props.user.logo : ''
		} else {
			use_logo = ''
		}
	}

	var submitChange = (e) => {
		e.preventDefault()

		var updates = {
			signature: use_sig,
			timezone: timezone,
			country: country,
			nickname: use_nick,

			address1: use_address1,
			address2: use_address2,
			city: use_city,
			state: use_state,
			zipcode: use_zipcode,
			contactEmail: use_contactEmail,
			contactPhone: use_contactPhone,
			textPhone: use_textPhone,
			area_code: use_area,
			logo: use_logo,
			country_info: countryInfo,
			facebook,
			instagram,
			snapchat,
			twitter,
			tiktok,
			cover_style: coverStyle,
			chat_enabled: chatEnabled,
			askEmail: askEmail,
			askPhone: askPhone,
			link_1: link1,
			link_2: link2,
			link_3: link3,
			current_video: video,
			media_type: video ? 'video' : null,
			shop: shop,
			header_background: headerBackground,
			website,
			weedmaps,
			client_code: clientcode,
			hours: hours,
			description: description,
			current_logo_upload: logoUpload,
		}

		axios
			.post(props.baseurl + 'remote/user/update', {
				token: token,
				updates: updates,
			})
			.then((result) => {
				if (result.data.severity === 'success') {
					var user = Object.assign({}, props.user, updates)

					props.setUser(user)
				}

				props.setAlert({
					open: true,
					severity: result.data.severity,
					message: result.data.message,
				})
			})
	}

	var timezones = [
		'Africa/Abidjan',
		'Africa/Accra',
		'Africa/Algiers',
		'Africa/Bissau',
		'Africa/Cairo',
		'Africa/Casablanca',
		'Africa/Ceuta',
		'Africa/El_Aaiun',
		'Africa/Johannesburg',
		'Africa/Juba',
		'Africa/Khartoum',
		'Africa/Lagos',
		'Africa/Maputo',
		'Africa/Monrovia',
		'Africa/Nairobi',
		'Africa/Ndjamena',
		'Africa/Sao_Tome',
		'Africa/Tripoli',
		'Africa/Tunis',
		'Africa/Windhoek',
		'America/Adak',
		'America/Anchorage',
		'America/Araguaina',
		'America/Argentina/Buenos_Aires',
		'America/Argentina/Catamarca',
		'America/Argentina/Cordoba',
		'America/Argentina/Jujuy',
		'America/Argentina/La_Rioja',
		'America/Argentina/Mendoza',
		'America/Argentina/Rio_Gallegos',
		'America/Argentina/Salta',
		'America/Argentina/San_Juan',
		'America/Argentina/San_Luis',
		'America/Argentina/Tucuman',
		'America/Argentina/Ushuaia',
		'America/Asuncion',
		'America/Atikokan',
		'America/Bahia',
		'America/Bahia_Banderas',
		'America/Barbados',
		'America/Belem',
		'America/Belize',
		'America/Blanc-Sablon',
		'America/Boa_Vista',
		'America/Bogota',
		'America/Boise',
		'America/Cambridge_Bay',
		'America/Campo_Grande',
		'America/Cancun',
		'America/Caracas',
		'America/Cayenne',
		'America/Chicago',
		'America/Chihuahua',
		'America/Costa_Rica',
		'America/Creston',
		'America/Cuiaba',
		'America/Curacao',
		'America/Danmarkshavn',
		'America/Dawson',
		'America/Dawson_Creek',
		'America/Denver',
		'America/Detroit',
		'America/Edmonton',
		'America/Eirunepe',
		'America/El_Salvador',
		'America/Fort_Nelson',
		'America/Fortaleza',
		'America/Glace_Bay',
		'America/Goose_Bay',
		'America/Grand_Turk',
		'America/Guatemala',
		'America/Guayaquil',
		'America/Guyana',
		'America/Halifax',
		'America/Havana',
		'America/Hermosillo',
		'America/Indiana/Indianapolis',
		'America/Indiana/Knox',
		'America/Indiana/Marengo',
		'America/Indiana/Petersburg',
		'America/Indiana/Tell_City',
		'America/Indiana/Vevay',
		'America/Indiana/Vincennes',
		'America/Indiana/Winamac',
		'America/Inuvik',
		'America/Iqaluit',
		'America/Jamaica',
		'America/Juneau',
		'America/Kentucky/Louisville',
		'America/Kentucky/Monticello',
		'America/La_Paz',
		'America/Lima',
		'America/Los_Angeles',
		'America/Maceio',
		'America/Managua',
		'America/Manaus',
		'America/Martinique',
		'America/Matamoros',
		'America/Mazatlan',
		'America/Menominee',
		'America/Merida',
		'America/Metlakatla',
		'America/Mexico_City',
		'America/Miquelon',
		'America/Moncton',
		'America/Monterrey',
		'America/Montevideo',
		'America/Nassau',
		'America/New_York',
		'America/Nipigon',
		'America/Nome',
		'America/Noronha',
		'America/North_Dakota/Beulah',
		'America/North_Dakota/Center',
		'America/North_Dakota/New_Salem',
		'America/Nuuk',
		'America/Ojinaga',
		'America/Panama',
		'America/Pangnirtung',
		'America/Paramaribo',
		'America/Phoenix',
		'America/Port-au-Prince',
		'America/Port_of_Spain',
		'America/Porto_Velho',
		'America/Puerto_Rico',
		'America/Punta_Arenas',
		'America/Rainy_River',
		'America/Rankin_Inlet',
		'America/Recife',
		'America/Regina',
		'America/Resolute',
		'America/Rio_Branco',
		'America/Santarem',
		'America/Santiago',
		'America/Santo_Domingo',
		'America/Sao_Paulo',
		'America/Scoresbysund',
		'America/Sitka',
		'America/St_Johns',
		'America/Swift_Current',
		'America/Tegucigalpa',
		'America/Thule',
		'America/Thunder_Bay',
		'America/Tijuana',
		'America/Toronto',
		'America/Vancouver',
		'America/Whitehorse',
		'America/Winnipeg',
		'America/Yakutat',
		'America/Yellowknife',
		'Antarctica/Casey',
		'Antarctica/Davis',
		'Antarctica/DumontDUrville',
		'Antarctica/Macquarie',
		'Antarctica/Mawson',
		'Antarctica/Palmer',
		'Antarctica/Rothera',
		'Antarctica/Syowa',
		'Antarctica/Troll',
		'Antarctica/Vostok',
		'Asia/Almaty',
		'Asia/Amman',
		'Asia/Anadyr',
		'Asia/Aqtau',
		'Asia/Aqtobe',
		'Asia/Ashgabat',
		'Asia/Atyrau',
		'Asia/Baghdad',
		'Asia/Baku',
		'Asia/Bangkok',
		'Asia/Barnaul',
		'Asia/Beirut',
		'Asia/Bishkek',
		'Asia/Brunei',
		'Asia/Chita',
		'Asia/Choibalsan',
		'Asia/Colombo',
		'Asia/Damascus',
		'Asia/Dhaka',
		'Asia/Dili',
		'Asia/Dubai',
		'Asia/Dushanbe',
		'Asia/Famagusta',
		'Asia/Gaza',
		'Asia/Hebron',
		'Asia/Ho_Chi_Minh',
		'Asia/Hong_Kong',
		'Asia/Hovd',
		'Asia/Irkutsk',
		'Asia/Jakarta',
		'Asia/Jayapura',
		'Asia/Jerusalem',
		'Asia/Kabul',
		'Asia/Kamchatka',
		'Asia/Karachi',
		'Asia/Kathmandu',
		'Asia/Khandyga',
		'Asia/Kolkata',
		'Asia/Krasnoyarsk',
		'Asia/Kuala_Lumpur',
		'Asia/Kuching',
		'Asia/Macau',
		'Asia/Magadan',
		'Asia/Makassar',
		'Asia/Manila',
		'Asia/Nicosia',
		'Asia/Novokuznetsk',
		'Asia/Novosibirsk',
		'Asia/Omsk',
		'Asia/Oral',
		'Asia/Pontianak',
		'Asia/Pyongyang',
		'Asia/Qatar',
		'Asia/Qostanay',
		'Asia/Qyzylorda',
		'Asia/Riyadh',
		'Asia/Sakhalin',
		'Asia/Samarkand',
		'Asia/Seoul',
		'Asia/Shanghai',
		'Asia/Singapore',
		'Asia/Srednekolymsk',
		'Asia/Taipei',
		'Asia/Tashkent',
		'Asia/Tbilisi',
		'Asia/Tehran',
		'Asia/Thimphu',
		'Asia/Tokyo',
		'Asia/Tomsk',
		'Asia/Ulaanbaatar',
		'Asia/Urumqi',
		'Asia/Ust-Nera',
		'Asia/Vladivostok',
		'Asia/Yakutsk',
		'Asia/Yangon',
		'Asia/Yekaterinburg',
		'Asia/Yerevan',
		'Atlantic/Azores',
		'Atlantic/Bermuda',
		'Atlantic/Canary',
		'Atlantic/Cape_Verde',
		'Atlantic/Faroe',
		'Atlantic/Madeira',
		'Atlantic/Reykjavik',
		'Atlantic/South_Georgia',
		'Atlantic/Stanley',
		'Australia/Adelaide',
		'Australia/Brisbane',
		'Australia/Broken_Hill',
		'Australia/Currie',
		'Australia/Darwin',
		'Australia/Eucla',
		'Australia/Hobart',
		'Australia/Lindeman',
		'Australia/Lord_Howe',
		'Australia/Melbourne',
		'Australia/Perth',
		'Australia/Sydney',
		'CET',
		'CST6CDT',
		'EET',
		'EST',
		'EST5EDT',
		'Etc/GMT',
		'Etc/GMT+1',
		'Etc/GMT+10',
		'Etc/GMT+11',
		'Etc/GMT+12',
		'Etc/GMT+2',
		'Etc/GMT+3',
		'Etc/GMT+4',
		'Etc/GMT+5',
		'Etc/GMT+6',
		'Etc/GMT+7',
		'Etc/GMT+8',
		'Etc/GMT+9',
		'Etc/GMT-1',
		'Etc/GMT-10',
		'Etc/GMT-11',
		'Etc/GMT-12',
		'Etc/GMT-13',
		'Etc/GMT-14',
		'Etc/GMT-2',
		'Etc/GMT-3',
		'Etc/GMT-4',
		'Etc/GMT-5',
		'Etc/GMT-6',
		'Etc/GMT-7',
		'Etc/GMT-8',
		'Etc/GMT-9',
		'Etc/UTC',
		'Europe/Amsterdam',
		'Europe/Andorra',
		'Europe/Astrakhan',
		'Europe/Athens',
		'Europe/Belgrade',
		'Europe/Berlin',
		'Europe/Brussels',
		'Europe/Bucharest',
		'Europe/Budapest',
		'Europe/Chisinau',
		'Europe/Copenhagen',
		'Europe/Dublin',
		'Europe/Gibraltar',
		'Europe/Helsinki',
		'Europe/Istanbul',
		'Europe/Kaliningrad',
		'Europe/Kiev',
		'Europe/Kirov',
		'Europe/Lisbon',
		'Europe/London',
		'Europe/Luxembourg',
		'Europe/Madrid',
		'Europe/Malta',
		'Europe/Minsk',
		'Europe/Monaco',
		'Europe/Moscow',
		'Europe/Oslo',
		'Europe/Paris',
		'Europe/Prague',
		'Europe/Riga',
		'Europe/Rome',
		'Europe/Samara',
		'Europe/Saratov',
		'Europe/Simferopol',
		'Europe/Sofia',
		'Europe/Stockholm',
		'Europe/Tallinn',
		'Europe/Tirane',
		'Europe/Ulyanovsk',
		'Europe/Uzhgorod',
		'Europe/Vienna',
		'Europe/Vilnius',
		'Europe/Volgograd',
		'Europe/Warsaw',
		'Europe/Zaporozhye',
		'Europe/Zurich',
		'HST',
		'Indian/Chagos',
		'Indian/Christmas',
		'Indian/Cocos',
		'Indian/Kerguelen',
		'Indian/Mahe',
		'Indian/Maldives',
		'Indian/Mauritius',
		'Indian/Reunion',
		'MET',
		'MST',
		'MST7MDT',
		'PST8PDT',
		'Pacific/Apia',
		'Pacific/Auckland',
		'Pacific/Bougainville',
		'Pacific/Chatham',
		'Pacific/Chuuk',
		'Pacific/Easter',
		'Pacific/Efate',
		'Pacific/Enderbury',
		'Pacific/Fakaofo',
		'Pacific/Fiji',
		'Pacific/Funafuti',
		'Pacific/Galapagos',
		'Pacific/Gambier',
		'Pacific/Guadalcanal',
		'Pacific/Guam',
		'Pacific/Honolulu',
		'Pacific/Kiritimati',
		'Pacific/Kosrae',
		'Pacific/Kwajalein',
		'Pacific/Majuro',
		'Pacific/Marquesas',
		'Pacific/Nauru',
		'Pacific/Niue',
		'Pacific/Norfolk',
		'Pacific/Noumea',
		'Pacific/Pago_Pago',
		'Pacific/Palau',
		'Pacific/Pitcairn',
		'Pacific/Pohnpei',
		'Pacific/Port_Moresby',
		'Pacific/Rarotonga',
		'Pacific/Tahiti',
		'Pacific/Tarawa',
		'Pacific/Tongatapu',
		'Pacific/Wake',
		'Pacific/Wallis',
		'WET',
	]

	var timezone_list = timezones.map((c, i) => {
		return (
			<MenuItem key={i} value={c}>
				{c}
			</MenuItem>
		)
	})

	var countries_list = [
		{
			name: 'Canada',
			dialCode: '+1',
			isoCode: 'CA',
		},
		{
			name: 'United States',
			dialCode: '+1',
			isoCode: 'US',
		},
	]

	var country_list = countries_list.map((c, i) => {
		return (
			<MenuItem
				onClick={() => {
					setCountryInfo({
						dialCode: c.dialCode,
						isoCode: c.isoCode,
						name: c.name,
					})
				}}
				key={i}
				value={c.isoCode}
			>
				{c.dialCode} {c.name}
			</MenuItem>
		)
	})

	var colors = {
		brand: '#4cbb17',
		sub: '#c3ebb0',
		error_color: '#fff',
		error_background: '#a10000',
	}

	//var header_background = "#4cbb17";
	//var header_border = "#ffffff";
	var header_text = '#ffffff'
	var sub_header = colors.sub
	var icon_color = colors.sub
	//var info_background = "#ffffff";
	//var info_border = "#ffffff";

	if (headerBackground) {
		//header_background = headerBackground;

		if (headerBackground === '#ffffff') {
			//header_border = "#4cbb17";
			header_text = '#1d1d1d'
			sub_header = '#5b5b5b'
			icon_color = '#4cbb17'
			//info_background = "#ffffff";
			//info_border = "#4cbb17";
		} else if (headerBackground === '#1d1d1d') {
			//header_border = "#ffffff";
			header_text = '#ffffff'
			sub_header = '#9b9b9b'
			icon_color = '#4cbb17'
			//info_background = "#1d1d1d";
			//info_border = "#ffffff";
		} else if (headerBackground === '#4cbb17') {
			//header_border = "#ffffff";
			header_text = '#ffffff'
			sub_header = colors.sub
			icon_color = colors.sub
			//info_background = "#ffffff";
			//info_border = "#4cbb17";
		}
	}

	var display_logo = logoUpload ? logoUpload : logo ? logo : null

	return (
		<Container component="main" maxWidth="xs" disableGutters>
			<CssBaseline />
			<div className={`${classes.paper} top-paper`}>
				<Grid
					container
					justifyContent="center"
					direction="row"
					alignItems="center"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					<AccountBoxIcon
						fontSize="large"
						color="primary"
						style={{ marginRight: '8px' }}
					/>

					<Typography component="h1" variant="h5">
						Edit Profile
					</Typography>
				</Grid>

				<form className={classes.form} noValidate onSubmit={submitChange}>
					<div>
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							id="nickname"
							placeholder="Shop Name"
							name="nickname"
							autoComplete="nickname"
							value={use_nick}
							onChange={(e) => setNickname(e.target.value)}
						/>

						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="clientcode"
							placeholder="Custom Greenlight Code"
							name="clientcode"
							autoComplete="clientcode"
							disabled={locked}
							value={clientcode}
							onChange={(e) => {
								setClientcode(
									e.target.value
										.toLowerCase()
										.trim()
										.replace(/[^a-zA-Z0-9]+/g, '')
								)
							}}
						/>

						<div style={{ color: '#5d5d5d', fontSize: 10, marginBottom: 8 }}>
							(only lowercase letters and numbers are allowed, no spaces or
							special characters)
							{locked ? (
								<React.Fragment>
									<br />
									Free accounts cannot customize their Greenlight code.
								</React.Fragment>
							) : (
								''
							)}
						</div>

						{props.user.client_code && testcode ? (
							<div>Test code: {props.user.client_code + '-' + testcode}</div>
						) : null}

						{props.user.shopcode ? (
							<div>numeric Greenlight code: {shopcode}</div>
						) : null}

						<div style={{ textAlign: 'center' }}>
							<Button
								style={{
									marginTop: '16px',
									marginBottom: '0px',
									marginLeft: 'auto',
									marginRight: 'auto',
								}}
								variant="outlined"
								color="primary"
								startIcon={expandQR ? <VisibilityOff /> : <Visibility />}
								onClick={() => {
									setExpandQR(!expandQR)
								}}
							>
								{expandQR ? 'Hide' : 'Show'} QR Code
							</Button>
						</div>

						{expandQR ? (
							<div style={{ marginBottom: '16px' }}>
								<div style={{ textAlign: 'center' }}>
									<Typography
										component="p"
										variant="caption"
										style={{ textAlign: 'center' }}
										color="secondary"
									>
										scannable QR code:
									</Typography>
								</div>

								<div style={{ textAlign: 'center' }}>
									<img
										src={props.user.qr}
										style={{ width: isSmall ? '100%' : '50%' }}
										alt={'qr code for ' + props.user.shopcode}
									/>
								</div>
							</div>
						) : null}

						<Accordion
							expanded={expandInfo}
							onChange={() => {
								setExpandInfo(!expandInfo)
							}}
							key="info"
							TransitionProps={{
								timeout: 0,
							}}
							className={props.classes.expand + ' expand'}
							style={{ marginTop: '16px' }}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography className={props.classes.heading + ' bold'}>
									Info & Hours
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									style={{ alignItems: 'center', flexDirection: 'row' }}
								>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="description"
										placeholder="Description / Info"
										name="description"
										autoComplete="description"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										multiline={true}
										rows={5}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="hours"
										placeholder="Hours"
										name="hours"
										autoComplete="hours"
										value={hours}
										onChange={(e) => setHours(e.target.value)}
										multiline={true}
										rows={5}
									/>
								</Grid>
							</AccordionDetails>
						</Accordion>

						<Accordion
							expanded={expandAddress}
							onChange={() => {
								setExpandAddress(!expandAddress)
							}}
							key="address"
							TransitionProps={{
								timeout: 0,
							}}
							className={props.classes.expand + ' expand'}
							style={{ marginTop: '16px' }}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography className={props.classes.heading + ' bold'}>
									Address Details
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									style={{ alignItems: 'center', flexDirection: 'row' }}
								>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="address1"
										placeholder="Address 1"
										name="address1"
										autoComplete="address1"
										value={use_address1}
										onChange={(e) => setAddress1(e.target.value)}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="address2"
										placeholder="Address 2"
										name="address2"
										autoComplete="address2"
										value={use_address2}
										onChange={(e) => setAddress2(e.target.value)}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="city"
										placeholder="City"
										name="city"
										autoComplete="city"
										value={use_city}
										onChange={(e) => setCity(e.target.value)}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="state"
										placeholder="State"
										name="state"
										autoComplete="state"
										value={use_state}
										onChange={(e) => setState(e.target.value)}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="zipcode"
										placeholder="Zipcode"
										name="zipcode"
										autoComplete="zipcode"
										value={use_zipcode}
										onChange={(e) => setZipcode(e.target.value)}
									/>
								</Grid>
							</AccordionDetails>
						</Accordion>

						{props.user.client_code !== 'green' ? (
							<Accordion
								expanded={expandContact}
								onChange={() => {
									setExpandContact(!expandContact)
								}}
								key="contact"
								TransitionProps={{
									timeout: 0,
								}}
								className={props.classes.expand + ' expand'}
								style={{ marginTop: '16px' }}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography className={props.classes.heading + ' bold'}>
										Contact Details
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										style={{ alignItems: 'center', flexDirection: 'row' }}
									>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="contactEmail"
											placeholder="Contact Email"
											name="contactEmail"
											autoComplete="contactEmail"
											value={use_contactEmail}
											onChange={(e) => setContactEmail(e.target.value)}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="contactPhone"
											placeholder="Contact Phone"
											name="contactPhone"
											autoComplete="contactPhone"
											value={use_contactPhone}
											onChange={(e) => setContactPhone(e.target.value)}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="textPhone"
											placeholder="Text Phone"
											name="textPhone"
											autoComplete="textPhone"
											value={use_textPhone}
											onChange={(e) => setTextPhone(e.target.value)}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											placeholder="Area Code"
											name="area_code"
											value={use_area}
											onChange={(e) => setAreacode(e.target.value)}
										/>

										<FormControl
											variant="filled"
											className={classes.formControl}
											style={{ marginTop: '16px' }}
											fullWidth
										>
											<InputLabel ref={inputLabel}>Time Zone</InputLabel>
											<Select
												value={timezone ? timezone : 'America/Los_Angeles'}
												onChange={(e) => {
													setTimezone(e.target.value)
												}}
												labelWidth={labelWidth}
											>
												{timezone_list}
											</Select>
											<span style={{ marginTop: '8px' }}>
												Local time is:{' '}
												{Moment()
													.tz(timezone ? timezone : 'America/Los_Angeles')
													.format('MM/DD/YYYY hh:mm:ss A')}
											</span>
										</FormControl>

										<FormControl
											variant="filled"
											className={classes.formControl}
											style={{ marginTop: '16px' }}
											fullWidth
										>
											<InputLabel ref={inputLabel}>Country</InputLabel>
											<Select
												value={country ? country : 'US'}
												onChange={(e) => {
													setCountry(e.target.value)
												}}
												labelWidth={labelWidth}
											>
												{country_list}
											</Select>
										</FormControl>
									</Grid>
								</AccordionDetails>
							</Accordion>
						) : null}

						{props.user.client_code !== 'green' ? (
							<Accordion
								expanded={expandLinks}
								onChange={() => {
									setExpandLinks(!expandLinks)
								}}
								key="links"
								TransitionProps={{
									timeout: 0,
								}}
								className={props.classes.expand + ' expand'}
								style={{ marginTop: '16px' }}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography className={props.classes.heading + ' bold'}>
										Website & Links
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										style={{ alignItems: 'center', flexDirection: 'row' }}
									>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="website"
											placeholder="Website"
											name="website"
											autoComplete="website"
											value={website}
											onChange={(e) => setWebsite(e.target.value)}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="weedmaps"
											placeholder="Weedmaps"
											name="weedmaps"
											autoComplete="weedmaps"
											value={weedmaps}
											onChange={(e) => setWeedmaps(e.target.value)}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="link1"
											placeholder="Link 1 Title"
											name="link1"
											autoComplete="link1"
											value={link1.title}
											onChange={(e) => {
												var link_1 = Object.assign({}, link1)
												link_1.title = e.target.value
												setLink1(link_1)
											}}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="link1"
											placeholder="Link 1 URL"
											name="link1"
											autoComplete="link1"
											value={link1.url}
											onChange={(e) => {
												var link_1 = Object.assign({}, link1)
												link_1.url = e.target.value
												setLink1(link_1)
											}}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="link2"
											placeholder="Link 2 Title"
											name="link2"
											autoComplete="link2"
											value={link2.title}
											onChange={(e) => {
												var link_2 = Object.assign({}, link2)
												link_2.title = e.target.value
												setLink2(link_2)
											}}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="link2"
											placeholder="Link 2 URL"
											name="link2"
											autoComplete="link2"
											value={link2.url}
											onChange={(e) => {
												var link_2 = Object.assign({}, link2)
												link_2.url = e.target.value
												setLink2(link_2)
											}}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="link3"
											placeholder="Link 3 Title"
											name="link3"
											autoComplete="link3"
											value={link3.title}
											onChange={(e) => {
												var link_3 = Object.assign({}, link3)
												link_3.title = e.target.value
												setLink3(link_3)
											}}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="link3"
											placeholder="Link 3 URL"
											name="link3"
											autoComplete="link3"
											value={link3.url}
											onChange={(e) => {
												var link_3 = Object.assign({}, link3)
												link_3.url = e.target.value
												setLink3(link_3)
											}}
										/>
									</Grid>
								</AccordionDetails>
							</Accordion>
						) : null}

						{props.user.client_code !== 'green' ? (
							<Accordion
								expanded={expandSocial}
								onChange={() => {
									setExpandSocial(!expandSocial)
								}}
								key="social"
								TransitionProps={{
									timeout: 0,
								}}
								className={props.classes.expand + ' expand'}
								style={{ marginTop: '16px' }}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography className={props.classes.heading + ' bold'}>
										Social Media Accounts
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										style={{ alignItems: 'center', flexDirection: 'row' }}
									>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="facebook"
											placeholder="Facebook"
											name="facebook"
											autoComplete="facebook"
											value={facebook}
											onChange={(e) => setFacebook(e.target.value)}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="instagram"
											placeholder="Instagram"
											name="instagram"
											autoComplete="instagram"
											value={instagram}
											onChange={(e) => setInstagram(e.target.value)}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="snapchat"
											placeholder="Snapchat"
											name="snapchat"
											autoComplete="snapchat"
											value={snapchat}
											onChange={(e) => setSnapchat(e.target.value)}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="twitter"
											placeholder="Twitter"
											name="twitter"
											autoComplete="twitter"
											value={twitter}
											onChange={(e) => setTwitter(e.target.value)}
										/>

										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="tiktok"
											placeholder="TikTok"
											name="tiktok"
											autoComplete="tiktok"
											value={tiktok}
											onChange={(e) => setTiktok(e.target.value)}
										/>
									</Grid>
								</AccordionDetails>
							</Accordion>
						) : null}

						<TextField
							style={{ display: 'none' }}
							variant="outlined"
							margin="normal"
							fullWidth
							id="signature"
							placeholder="Signature"
							name="signature"
							autoComplete="signature"
							value={use_sig}
							onChange={(e) => setSignature(e.target.value)}
							multiline={true}
							rows={5}
						/>

						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							id="video"
							placeholder="Video URL"
							name="video"
							autoComplete="video"
							value={video}
							disabled={props.user.package < 3}
							onChange={(e) => setVideo(e.target.value)}
						/>
						{props.user.package < 3 ? (
							<React.Fragment>
								<br />
								<div
									style={{ color: '#5d5d5d', fontSize: 10, marginBottom: 8 }}
								>
									(Only Pro accounts can add a video.)
								</div>
							</React.Fragment>
						) : (
							''
						)}

						{props.user.client_code !== 'green' ? (
							<React.Fragment>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id="shop"
									placeholder="Store URL"
									name="shop"
									autoComplete="shop"
									value={shop}
									disabled={props.user.package < 3}
									onChange={(e) => setShop(e.target.value)}
								/>
								{props.user.package < 3 ? (
									<React.Fragment>
										<br />
										<div
											style={{
												color: '#5d5d5d',
												fontSize: 10,
												marginBottom: 8,
											}}
										>
											(Only Pro accounts can add a store.)
										</div>
									</React.Fragment>
								) : (
									''
								)}
							</React.Fragment>
						) : null}
					</div>

					{props.user.client_code !== 'green' ? (
						<Grid container style={{ marginTop: '24px' }}>
							<Grid item xs={12} sm={12}>
								<FormControlLabel
									style={{
										marginLeft: '0px',

										alignContent: 'center',
										alignItems: 'center',
										justifyContent: 'center',
									}}
									control={
										<Switch
											color="primary"
											disabled={props.user.package < 3}
											checked={askEmail === true ? true : false}
											onChange={(e) => {
												if (askEmail === true) {
													setAskEmail(false)
												} else {
													setAskEmail(true)
												}
											}}
											value="checked"
											inputProps={{ 'aria-label': 'Ask for email?' }}
											className={classes.switch}
										/>
									}
									labelPlacement="end"
									label={'Ask for email?'}
								/>
								{props.user.package < 3 ? (
									<React.Fragment>
										<br />
										<div
											style={{
												color: '#5d5d5d',
												fontSize: 10,
												marginBottom: 8,
											}}
										>
											(Only Pro accounts can enable email requests.)
										</div>
									</React.Fragment>
								) : (
									''
								)}
							</Grid>

							<Grid item xs={12} sm={12}>
								<FormControlLabel
									style={{
										marginLeft: '0px',
										marginTop: '8px',
										alignContent: 'center',
										alignItems: 'center',
										justifyContent: 'center',
									}}
									control={
										<Switch
											color="primary"
											disabled={props.user.package < 3}
											checked={askPhone === true ? true : false}
											onChange={(e) => {
												if (askPhone === true) {
													setAskPhone(false)
												} else {
													setAskPhone(true)
												}
											}}
											value="checked"
											inputProps={{ 'aria-label': 'Ask for phone?' }}
											className={classes.switch}
										/>
									}
									labelPlacement="end"
									label={'Ask for phone?'}
								/>
								{props.user.package < 3 ? (
									<React.Fragment>
										<br />
										<div
											style={{
												color: '#5d5d5d',
												fontSize: 10,
												marginBottom: 8,
											}}
										>
											(Only Pro accounts can enable phone requests.)
										</div>
									</React.Fragment>
								) : (
									''
								)}
							</Grid>
						</Grid>
					) : null}

					<div style={{ marginTop: '24px', display: 'none' }}>
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							id="header_background"
							placeholder="Header background color"
							name="header_background"
							autoComplete="header_background"
							value={headerBackground}
							disabled={true}
							onChange={(e) => setHeaderBackground(e.target.value)}
						/>
					</div>

					<Grid
						container
						style={{
							marginTop: '24px',
							flexDirection: 'row',
							alignContent: 'flex-start',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
						}}
						spacing={1}
					>
						<Grid item xs={4}>
							<Button
								fullWidth
								style={{
									backgroundColor: '#4cbb17',
									color: '#ffffff',
									//	width: '33%',
									height: '48px',
								}}
								variant="contained"
								onClick={() => {
									setHeaderBackground('#4cbb17')
								}}
							>
								Green
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button
								fullWidth
								style={{
									backgroundColor: '#1d1d1d',
									color: '#ffffff',
									//width: '33%',
									height: '48px',
								}}
								variant="contained"
								onClick={() => {
									setHeaderBackground('#1d1d1d')
								}}
							>
								Dark
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button
								fullWidth
								style={{
									backgroundColor: '#ffffff',
									color: '#4cbb17',
									//	width: '33%',
									height: '48px',
								}}
								variant="contained"
								onClick={() => {
									setHeaderBackground('#ffffff')
								}}
							>
								Light
							</Button>
						</Grid>
					</Grid>

					{props.user.current_image ? (
						<Button
							type="button"
							fullWidth
							variant="outlined"
							color="primary"
							className={classes.previewSubmit}
							startIcon={<Visibility />}
							onClick={() => {
								setPreview(!preview)
							}}
						>
							{preview ? 'Show' : 'Show'} {preview ? 'Header Only' : 'Full'}{' '}
							Preview
						</Button>
					) : null}

					{preview ? null : (
						<div
							style={{
								marginTop: '8px',
								marginBottom: '8px',
								backgroundColor: headerBackground,
								height: '104px',
								border:
									headerBackground === '#ffffff' ? '0px solid #ececec' : 'none',
							}}
						>
							<Grid container>
								<Grid
									item
									container
									xs={logo ? 8 : 12}
									style={{ padding: '8px' }}
								>
									<Grid
										item
										xs={12}
										style={{
											fontWeight: 'bold',
											fontSize: '16px',
											color: header_text,
										}}
										container
										alignItems="center"
										alignContent="center"
									>
										<span>{use_nick}</span>{' '}
										<InfoOutlined
											style={{
												fontSize: 14,
												fontWeight: 'bold',
												marginLeft: 8,
												color: icon_color,
											}}
										/>
									</Grid>
									<Grid item xs={12} style={{ color: sub_header }}>
										{use_address1} {use_address2}
									</Grid>
									<Grid item xs={12} style={{ color: sub_header }}>
										{use_city}
										{use_city && use_state ? ', ' : null}
										{use_state} {use_zipcode}
									</Grid>
									<Grid item xs={12} style={{ color: sub_header }}>
										{use_contactPhone}
									</Grid>
								</Grid>
								{display_logo ? (
									<Grid
										container
										item
										xs={4}
										style={{ height: '104px', textAlign: 'right' }}
										alignItems="center"
										alignContent="center"
										justifyContent="flex-end"
									>
										<img
											key={display_logo}
											alt="main"
											src={'/remote/api/image/' + display_logo}
											style={{
												maxWidth: '100%',
												maxHeight: '80px',
												marginRight: '8px',
											}}
										/>
									</Grid>
								) : null}
							</Grid>
						</div>
					)}

					<Grid
						style={{
							fontSize: '12px',
							color: '#c0c0c0',
							textAlign: 'center',
							marginBottom: '0px',
						}}
					>
						{props.user.current_image && preview ? (
							<div style={{ marginTop: '16px' }}>
								<Current
									headerBackground={headerBackground}
									cover_style={coverStyle}
									logoUpload={logoUpload}
									removed={!logo}
								/>
							</div>
						) : null}
					</Grid>

					<div>
						<FormControlLabel
							style={{
								marginLeft: '0px',
								marginTop: '10px',
								alignContent: 'center',
								alignItems: 'center',
								justifyContent: 'center',
								marginBottom: '0px',
							}}
							control={
								<Switch
									color="primary"
									checked={coverStyle === 'cover' ? true : false}
									onChange={(e) => {
										if (coverStyle === 'cover') {
											setCoverStyle('contain')
										} else {
											setCoverStyle('cover')
										}
									}}
									value="checked"
									inputProps={{
										'aria-label': 'Image covers entire window?',
									}}
									className={classes.switch}
								/>
							}
							labelPlacement="end"
							label={'Image covers entire window?'}
						/>
					</div>

					<Box pt={1}>
						<div style={{ marginTop: '4px', marginBottom: '8px' }}>
							<Typography
								component="p"
								variant="body2"
								style={{ textAlign: 'center' }}
							>
								<strong>Customize your logo</strong>
							</Typography>
							<Typography
								component="p"
								variant="caption"
								style={{ marginTop: '4px' }}
								color="secondary"
							>
								Must be in .PNG or .JPG format
							</Typography>
							<Typography component="p" variant="caption" color="secondary">
								File size must be under 250KB
							</Typography>
							<Typography component="p" variant="caption" color="secondary">
								Height and width cannot exceed 128x128
							</Typography>
						</div>

						{!logo ? (
							<TextField
								fullWidth
								inputProps={{
									multiple: false,
									style: {
										padding: '16px',
										paddingBottom: '21px',
										paddingLeft: 'auto',
										paddingRight: 'auto',
									},
								}}
								margin="none"
								type="file"
								onChange={(e) => {
									var target = e.target

									if (target && target.files) {
										const files = Array.from(target.files)

										const formData = new FormData()

										formData.append('upload_type', 'logo')

										files.forEach((file, i) => {
											formData.append('file', file)
										})

										formData.append('token', localStorage.getItem('token'))

										axios({
											method: 'POST',
											url: '/remote/api/upload',
											data: formData,
											headers: {
												Accept: 'application/json',
												'Content-Type': 'multipart/form-data',
											},
										})
											.then((res) => {
												if (res.data.files && res.data.files.length) {
													setLogo(res.data.final_file.full_name)
													setLogoUpload(res.data.current_logo_upload)
												}
											})
											.catch(function (thrown) {
												console.log(thrown)
											})
									}

									//this changes the value of the data that was passed down to state from props so that the text field updates, without updating state text field value will always come from state.data that was sent from (but now isolated from) props, so changing the text will do nothing unless this changes the state that provides the value to the field. This by itself does not trigger a save, the blur handler must happen to trigger a save or a save must be triggered. This is not needed at all if the async solution is in use, as it will update and save. Use this instead of the this.props.handleChange line  if you want to update the state locally and reflect changes, but trigger the save manually.
									//this.handleChange('data', obj);
								}}
								value={''}
								variant="filled"
								color="primary"
								multiline={false}
							/>
						) : logo ? null : null}

						{display_logo ? (
							<Grid
								container
								style={{
									marginBottom: '8px',
									//									marginTop: '16px',
									textAlign: 'center',
									backgroundColor: headerBackground,
									alignItems: 'center',
									alignContent: 'center',
									justifyContent: 'center',
									paddingTop: '4px',
									paddingBottom: '4px',
								}}
							>
								{' '}
								<img
									key={display_logo}
									alt="main"
									src={'/remote/api/image/' + display_logo}
									style={{
										maxWidth: '100%',
									}}
								/>{' '}
							</Grid>
						) : null}
					</Box>

					{logo ? (
						<Box>
							<Button
								fullWidth
								variant="outlined"
								aria-placeholder="Clear"
								onClick={() => {
									setLogo('')
									setLogoUpload(null)
								}}
								startIcon={<ReplayIcon />}
							>
								Remove Image
							</Button>
						</Box>
					) : null}

					{props.user.client_code !== 'green' ? (
						<div>
							<FormControlLabel
								style={{
									marginLeft: '0px',
									marginTop: '24px',
									alignContent: 'center',
									alignItems: 'center',
									justifyContent: 'center',
								}}
								control={
									<Switch
										color="primary"
										disabled={props.user.package < 3}
										checked={chatEnabled === true ? true : false}
										onChange={(e) => {
											if (chatEnabled === true) {
												setChatEnabled(false)
											} else {
												setChatEnabled(true)
											}
										}}
										value="checked"
										inputProps={{ 'aria-label': 'Chat enabled?' }}
										className={classes.switch}
									/>
								}
								labelPlacement="end"
								label={'Chat enabled?'}
							/>

							{props.user.package < 3 ? (
								<React.Fragment>
									<br />
									<div
										style={{ color: '#5d5d5d', fontSize: 10, marginBottom: 8 }}
									>
										(Only Pro accounts can enable chat.)
									</div>
								</React.Fragment>
							) : (
								''
							)}
						</div>
					) : null}

					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Save Preferences
					</Button>
				</form>
			</div>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(useStyles(Preferences))
