import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { setUser, setAlert } from '../../../actions'
import { setContact } from '../../../actions/contacts'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	formControl: {
		marginTop: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))

function Status(props) {
	const classes = useStyles()

	const inputLabel = React.useRef(null)
	const [labelWidth, setLabelWidth] = React.useState(0)
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth)
	}, [])

	const handleChange = (e) => {
		props.setChoice(e.target.value)
	}

	return (
		<FormControl variant="standard" className={classes.formControl} fullWidth>
			<InputLabel ref={inputLabel}>
				{props.choice > 0 ? 'Selected' : 'Select'} Subscription
			</InputLabel>
			<Select
				value={props.choice}
				onChange={handleChange}
				labelWidth={labelWidth}
			>
				{props.user.package > 3 ? null : (
					<MenuItem value={1}>$250 - Basic</MenuItem>
				)}
				{props.user.package > 3 ? null : (
					<MenuItem value={2}>
						{props.user.package === 1 ? '$500' : '$500'} - Plus
					</MenuItem>
				)}
				{props.user.package > 3 ? null : (
					<MenuItem value={3}>
						{props.user.package === 1
							? '$1000'
							: props.user.package === 2
							? '$1000'
							: '$1000'}{' '}
						- Pro
					</MenuItem>
				)}
			</Select>
		</FormControl>
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	contact: state.contact,
	groups: state.groups,
})

const mapDispatchToProps = (dispatch) => ({
	setUser: (user) => dispatch(setUser(user)),
	setContact: (contact) => dispatch(setContact(contact)),
	setAlert: (alert) => dispatch(setAlert(alert)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Status)
