import React, { useEffect, useRef } from 'react'
import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import {
	setAlert,
	setUser,
	setTemp,
	setIntent,
	setCheckout,
} from '../../actions'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import Options from './credits/Options'
import Previews from './credits/Previews'
import Steps from './credits/Steps'
import PaymentMethods from './credits/PaymentMethods'
//import Director from '../Director'


//import { loadStripe } from '@stripe/stripe-js'
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe('pk_test_51HkD2jKT3upKfJYVGtEFYyqzC3bMJ8DTS4uVXYY35DjUACUrFzgxw488yrQy9VSYPNjbnsRMUI9WvTrht0eN2i5d00g4nNIeyC')

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	panel: {
		width: '100%',
	},
}))

function Credits(props) {
	const classes = useStyles()

	const [credits, setCredits] = React.useState(100)

	const [step, setStep] = React.useState(0)

	const [userAccount, setUserAccount] = React.useState({
		per_sms: 0,
		per_mms: 0,
		per_combo: 0,
		credits: 0,
	})

	//const [clientSecret, setClientSecret] = React.useState(null)

	const loaded = useRef(false)

	useEffect(() => {
		load()
		// eslint-disable-next-line
	}, [])

	React.useEffect(() => {
		if (props.user) {
			setUserAccount(props.user)
		}
	}, [props.user])

	var load = () => {
		axios
			.post(props.baseurl + 'remote/user/data', {
				token: props.user.token,
				account: true,
				_id: props.match.params.contactID,
			})
			.then((result) => {
				props.setUser(result.data)
				loaded.current = true
			})
	}

	/*
	var setIntent = props.setIntent

	var updateSecret = React.useCallback((result) => {
		setClientSecret(result.data.client_secret)
		setIntent(result.data.client_secret)
	},[setIntent])

	var getIntent = React.useCallback(() => {
		axios
			.post(props.baseurl + 'remote/payments/secret', {
				token: props.user.token,
				amount: credits,
			})
			.then((result) => {
				updateSecret(result)
			})
	}, [credits, props.baseurl, props.user.token, updateSecret])

	
	React.useEffect(() => {
		if (step === 2) {
			getIntent()
		}
	}, [getIntent, step])
*/

	var confirmPayment = () => {
		console.log(props.user.customer_token)
		console.log(props.temp.paymentMethod)

		axios
			.post(props.baseurl + 'remote/payments/charge', {
				token: props.user.token,
				customer_token: props.user.customer_token,
				card_token: props.temp.paymentMethod,
				credits: credits,
			})
			.then((result) => {
				if (result.data.charge.status === 'error') {
					props.setAlert({
						open: true,
						severity: result.data.severity,
						message: result.data.message,
					})

					return false
				}

				if (result.data.charge.status === 'succeeded') {
					props.setUser(result.data.user)

					var temp = Object.assign({}, props.temp, {
						charge_card: result.data.charge,
					})

					props.setTemp(temp)
					setStep(3)
				}
			})
	}

	var steps = []

	steps[0] = (
		<React.Fragment>
			<Box pt={1} width="100%">
				<Options credits={credits} setCredits={setCredits} />
			</Box>

			<Box pt={1}>
				<Previews userAccount={userAccount} credits={credits} />
			</Box>

			<Box pt={1}>
				<Button
					variant="contained"
					fullWidth
					color="primary"
					component={Link}
					to="/credits"
					onClick={() => {
						setStep(1)
					}}
				>
					Continue To Payment
				</Button>
			</Box>
			{/* save card via Stripe Checkout for future use then return to select that save card
			<Box pt={1}>
				<Button
					variant="contained"
					fullWidth
					color="primary"				
					onClick={async () => {
						

						var res = await axios
						.post(props.baseurl + 'remote/payments/checkout_session', {
							token: props.user.token,
						})
			
			
					
			
						//setClientSecret(res.data.client_secret)
					setCheckout(res.data.session_id)
			
					if (res.data.customer_id) {
						
						var u = Object.assign({}, props.user, {
							customer_token: res.data.customer_id,				
						})
			
						props.setUser(u)

						const stripe = await stripePromise;

						stripe.redirectToCheckout({
							// Make the id field from the Checkout Session creation API response
							// available to this file, so you can provide it as argument here
							// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
							sessionId: res.data.session_id
						  }).then(function (result) {
							// If `redirectToCheckout` fails due to a browser or network
							// error, display the localized error message to your customer
							// using `result.error.message`.
						  });
			
			
					}

		

					}}
				>
					Continue To Checkout
				</Button>
			</Box>
				*/}
			{/* make payment directly with Stripe Checkout and then redirect back to payments / balance page, save card
			<Box pt={1}>
				<Button
					variant="contained"
					fullWidth
					color="primary"
					onClick={async () => {
						var res = await axios.post(
							props.baseurl + 'remote/payments/checkout_payment',
							{
								token: props.user.token,
								amount: credits,
							}
						)

						//setClientSecret(res.data.client_secret)
						setCheckout(res.data.session_id)

						if (res.data.customer_id) {
							var u = Object.assign({}, props.user, {
								customer_token: res.data.customer_id,
							})

							props.setUser(u)

							const stripe = await stripePromise

							stripe
								.redirectToCheckout({
									// Make the id field from the Checkout Session creation API response
									// available to this file, so you can provide it as argument here
									// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
									sessionId: res.data.session_id,
								})
								.then(function (result) {
									// If `redirectToCheckout` fails due to a browser or network
									// error, display the localized error message to your customer
									// using `result.error.message`.
								})
						}
					}}
				>
					Continue To Payment
				</Button>
			</Box>
			*/}
		</React.Fragment>
	)

	steps[1] = (
		<PaymentMethods
			userAccount={userAccount}
			credits={credits}
			setStep={setStep}
			confirmPayment={confirmPayment}
		/>
	)

	steps[2] = (
		<React.Fragment>
			<Box pt={1} width="100%">
				<Grid container spacing={1}>
					<Grid item xs={6}>
						Total:
					</Grid>
					<Grid item xs={6}>
						${credits.toFixed(2)}
					</Grid>
				</Grid>
			</Box>

			<Box pt={1}>
				<Button
					variant="contained"
					fullWidth
					color="primary"
					onClick={() => {
						confirmPayment()
					}}
				>
					Complete Purchase
				</Button>
			</Box>
		</React.Fragment>
	)

	steps[3] = (
		<React.Fragment>
			<Typography variant="h6">PAYMENT SUCCESSFUL!</Typography>
			<Typography variant="subtitle1">
				Credit Added: ${credits.toFixed(2)}
			</Typography>
			<Typography variant="subtitle1">
				Total Available Balance: ${props.user.credits.toFixed(2)}
			</Typography>
			<Typography variant="subtitle1">PAYMENT DETAILS</Typography>

			{props.temp.charge_card ? (
				<React.Fragment>
					{' '}
					<Typography variant="subtitle2">
						Card: {props.temp.charge_card.brand} {props.temp.charge_card.last4}
					</Typography>
					<Typography variant="subtitle2">
						Receipt ID: {props.temp.charge_card.id}
					</Typography>
				</React.Fragment>
			) : null}

			<Box pt={1}>
				<Button
					variant="contained"
					fullWidth
					color="primary"
					component={Link}
					to="/account"
				>
					Go To Account
				</Button>
			</Box>
		</React.Fragment>
	)

	return (
		userAccount && (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={`${classes.paper} top-paper`}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Add Credit
					</Typography>

					<Box pt={1} width="100%">
						<Steps
							credits={credits}
							setCredits={setCredits}
							steps={steps}
							step={step}
						/>
					</Box>

					{step > 0 && step < 3 ? (
						<Box pt={1}>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => {
									setStep((step) => step - 1)
								}}
							>
								Back
							</Button>
						</Box>
					) : null}
				</div>
			</Container>
		)
	)
}

const mapStateToProps = (state) => ({
	user: state.auth,
	temp: state.temp,
	baseurl: state.baseurl,
})

const mapDispatchToProps = (dispatch) => ({
	setAlert: (alert) => dispatch(setAlert(alert)),
	setUser: (user) => dispatch(setUser(user)),
	setTemp: (temp) => dispatch(setTemp(temp)),
	setIntent: (intent) => dispatch(setIntent(intent)),
	setCheckout: (checkout) => dispatch(setCheckout(checkout)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Credits)
