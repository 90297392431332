const theme = (state = localStorage.getItem('mode') ? localStorage.getItem('mode') : 'dark', action) => {
	switch (action.type) {
		case 'SET_THEME':
			localStorage.setItem('mode', action.theme)
			return action.theme
		default:
			return state
	}
}

export default theme
